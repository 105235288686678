import { SCHEMA, TestRankingSchemaType } from '@leenda/editor/lib/brand'
import { getParent } from 'utils'

import { simplifyStyleValue } from 'services/Branding/constants/fields'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testRanking.style, {
  item: {
    META: { label: t('elements.testRanking.tags.item') },
    color: { label: t('input.label.iconColor') },
    backgroundColor: {},
  },
  item_hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: (data, { name }) => {
        const { parent } = getParent<TestRankingSchemaType>(name, data, 2)
        const borderStyle = simplifyStyleValue(parent?.border.borderStyle)
        return {
          hidden: borderStyle === 'none',
          transparent: true,
          resettable: true,
        }
      },
    },
  },
  border: {
    META: { hidden: true },
    borderRadius: {
      params: (data, { name }) => {
        const { parent } = getParent<{ borderStyle?: 'none' }>(name, data)
        const borderStyle = simplifyStyleValue(parent?.borderStyle)
        return {
          hidden: borderStyle === 'none',
        }
      },
    },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.testRanking.schema.boxShadow') },
  },
  offset: {
    META: { label: t('elements.testRanking.tags.spacing') },
    marginTop: { label: t('input.label.answerIndents') },
    padding: { label: t('input.label.innerAnswerIndents') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testRanking.font, FONT_SCHEMA_FIELDS)

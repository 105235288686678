import { gql } from 'gql/__generated__'

export const brandsSelectItemsByCompany = gql(`
  query BrandSelectItemsByCompany(
    $companyId: String!
    $search: BrandSearch
    $excludedIds: [String]
  ) {
    data: brandsByCompany(companyId: $companyId, search: $search, excludedIds: $excludedIds) {
      brands {
        ...BrandSelectItem
      }
    }
  }
`)

export const brandTableItemsByCompany = gql(`
  query BrandTableItemsByCompany(
    $companyId: String!
    $search: BrandSearch
    $excludedIds: [String]
  ) {
    data: brandsByCompany(companyId: $companyId, search: $search, excludedIds: $excludedIds) {
      brands {
        ...BrandTableItem
      }
      total
      limit
      offset
    }
  }
`)

export const branGetById = gql(`
  query BrandGetById($id: String!) {
    data: brandGetById(id: $id) {
      ...BrandEditable
    }
  }
`)

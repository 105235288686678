"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const constants_1 = require("../../constants");
exports.TYPE = 'audio';
exports.DEFAULT_VALUE = {
    playbackRate: 1,
    file: constants_1.imageValue,
    controls: true,
    autoPlay: false,
    repeat: false,
    coverImage: null,
    duration: null,
    overlay: 'rgba(255, 255, 255, 0.1)',
    volume: 50,
    percent: 0,
    captions: null,
    enableCCByDefault: false,
};

import { gql } from 'gql/__generated__'

export const createProject = gql(`
  mutation ProjectsCreate($companyId: String!, $data: ProjectCreateInput!) {
    data: projectsCreate(companyId: $companyId, data: $data) {
      data {
        ...ProjectListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const updateProject = gql(`
  mutation ProjectsUpdateById($projectId: String!, $data: ProjectUpdateInput!) {
    data: projectsUpdateById(projectId: $projectId, data: $data) {
      data {
        ...ProjectSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const projectsUpdateLocale = gql(`
  mutation ProjectsUpdateLocale($projectId: ID!, $data: ProjectUpdateLocale!) {
    data: projectsUpdateLocaleById(projectId: $projectId, data: $data) {
      ...ProjectSchema
    }
  }
`)

export const projectUpdateOwner = gql(`
  mutation ProjectUpdateOwner($projectId: String!, $ownerId: String!) {
    data: projectUpdateOwner(projectId: $projectId, ownerId: $ownerId) {
      ...ProjectSchema
    }
  }
`)

export const projectsUpdateOwners = gql(`
  mutation ProjectsUpdateOwners($data: [UpdateOwnersData!]!) {
    data: projectsUpdateOwners(data: $data) {
      ...ProjectSchema
    }
  }
`)

export const projectEmployeePreferences = gql(`
  mutation ProjectEmployeePreferences($projectId: ID!, $settings: JSON!) {
    data: projectEmployeePreferencesUpdate(projectId: $projectId, settings: $settings) 
  }
`)

import { useMemo } from 'react'

import Form from 'components/controls/Form'
import FormControls from 'components/controls/FormControls'
import FormField from 'components/controls/FormField'
import { IFolderFormValues } from 'components/forms/Folder'
import Modal, { MCWithParams, ModalBody, ModalFooter } from 'components/uiKit/Modal'
import {
  FileMetaGroupUpdateByIdMutation,
  FileMetaGroupCreateMutation,
} from 'gql/__generated__/graphql'
import { useFileMetaGroupUpdateById } from 'gql/files/apollo'
import { t } from 'services/Translation'

import { FILES_AND_GROUPS_FIELDS, InputErrorValues } from './config'

interface IFolderFinderEditProps {
  editableCategory: string
  companyId: string
  color?: string | null
  name: string
}

const FolderFinderEdit: MCWithParams<IFolderFinderEditProps> = ({ onClose, params }) => {
  const { companyId, editableCategory: id, color, name } = params
  const [updateFileAndGroupBuId] = useFileMetaGroupUpdateById()

  const initialValues = useMemo(() => ({ name, color }), [color, name])

  const onFinish = async (values: IFolderFormValues) => {
    if (!id) {
      return
    }
    const result = await updateFileAndGroupBuId({ variables: { data: values, companyId, id } })
    return onSuccessClose(result.data)
  }

  const onSuccessClose = (
    data?: FileMetaGroupUpdateByIdMutation | FileMetaGroupCreateMutation | null,
  ) => {
    if (!data?.data.errors) {
      onClose && onClose()
    }
    return data
  }

  return (
    <Form
      initialValues={initialValues}
      inputErrorValues={InputErrorValues}
      layout='vertical'
      name='fileGroup'
      onReset={onClose}
      onSubmit={onFinish}
    >
      <Modal maxWidth={560} name='edit' title={t('modal.editFolder.title')}>
        <ModalBody sizeAutoCapable>
          <FormField config={FILES_AND_GROUPS_FIELDS.name} />
          <FormField config={FILES_AND_GROUPS_FIELDS.color} />
        </ModalBody>
        <ModalFooter>
          <FormControls justify='right' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default FolderFinderEdit

import React, { useMemo } from 'react'

import { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { Typography } from 'components/uiKit/Typography'
import { t } from 'services/Translation'

import ColorCircle from '../ColorCircle'
import { IColorOption } from '../ColorPicker.types'
import s from './ColorRoot.module.scss'

interface IColorRootProps {
  value?: string | null
  color: string
  icon?: KeyIconsType
  size?: KitSize
  name: string
  labeled?: boolean
  option?: IColorOption
  onBlur?: () => void
  onFocus?: () => void
  forceLabel?: string
}

const ColorRoot: React.FC<IColorRootProps> = ({
  value,
  color,
  icon,
  size,
  name,
  labeled,
  option,
  onBlur,
  onFocus,
  forceLabel,
}) => {
  const label = useMemo(() => {
    if (!labeled || value === null) {
      return null
    }
    if (forceLabel) return forceLabel
    if (value === 'transparent') {
      return t('uiKit.button.transparent')
    }
    return option?.rootLabel || color.slice(0, 7)
  }, [forceLabel, color, labeled, option?.rootLabel, value])

  return (
    <div className={s.root}>
      {label && (
        <span className={s.label}>
          <Typography rows={1} size={KitSize.XS} wordBreak='break-all'>
            {label}
          </Typography>
        </span>
      )}
      <ColorCircle
        color={color}
        empty={value === null}
        icon={icon}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        size={size}
        transparent={value === 'transparent'}
      />
    </div>
  )
}

export default ColorRoot

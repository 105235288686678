import { AnimationTypeCardEnum, FlipMethodCardEnum } from '@leenda/editor/lib/brand'
import { useUpdateEffect } from 'ahooks'
import cn from 'classnames'
import { useCallback, useMemo, useState } from 'react'

import { completeElement } from 'services/Store/Project/actions'
import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { testProps } from 'utils/test/qaData'

import s from './CardElement.module.scss'
import { CardElementType } from './CardElement.types'
import CardSide from './CardSide'
import { CardSidesEnum } from './enum'
import ElementRootStyled from './styled/ElementRootStyled'
import { getBookAnimationConfig, getCardsCongig } from './utils'

const CardElement: CardElementType = ({
  element,
  styles,
  mode,
  block,
  state,
  waiting,
  setState,
  onChange,
  font,
}) => {
  const [hoveredCard, setHoveredCard] = useState(false)
  const dispatch = useProjectDispatch()
  const isInteractive = useProjectContext(getIsInteractiveMode)
  const isBack = Boolean(state?.isBack)
  const isFill = mode.previewMode === PreviewMode.editor && mode.editorMode === AppMode.fill
  const isHoverType = styles.animation.type === FlipMethodCardEnum.hover
  const isBook = styles.animation.cardFlip === AnimationTypeCardEnum.book
  const cardsConfig = useMemo(() => getCardsCongig(isBack), [isBack])
  const bookConfig = useMemo(() => getBookAnimationConfig(isBack), [isBack])

  const onFlipCard = useCallback(() => {
    setState?.({ isBack: !isBack, pauseAnimation: false })
    if (isInteractive && !isBack) {
      dispatch(
        completeElement({
          elementId: element.id,
          blockId: block?.uuid || '',
          incomplete: false,
        }),
      )
    }
  }, [block, dispatch, element.id, isBack, isInteractive, setState])

  const onClick = useCallback(() => {
    if (!isHoverType && !isFill) {
      onFlipCard()
    }
  }, [isHoverType, onFlipCard, isFill])

  const onMouseEnterCard = useCallback(() => {
    if (isHoverType && !hoveredCard && !isFill) {
      setHoveredCard(true)
      onFlipCard()
    }
  }, [hoveredCard, isHoverType, onFlipCard, isFill])

  const onMouseEnterIcon = useCallback(() => {
    if (isHoverType && !hoveredCard) {
      setHoveredCard(true)
      onFlipCard()
    }
  }, [hoveredCard, isHoverType, onFlipCard])

  const onMouseLeaveCard = useCallback(() => hoveredCard && setHoveredCard(false), [hoveredCard])

  useUpdateEffect(() => {
    setState?.({ isBack, pauseAnimation: true })
  }, [styles.animation.cardFlip])

  if (mode.previewMode === PreviewMode.pdf) {
    return (
      <>
        {cardsConfig.map(({ side }) => (
          <div className={s.root} key={side} style={{ ...styles.card, ...styles.border }}>
            <div className={cn(s.card, s[side])}>
              <CardSide
                font={font}
                id={element.id}
                mode={mode}
                side={side}
                styles={styles}
                value={element.value}
                active
              />
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ElementRootStyled
      $noHover={isFill}
      $styles={styles}
      onMouseEnter={onMouseEnterCard}
      onMouseLeave={onMouseLeaveCard}
    >
      <div
        className={cn(s.content, s[styles.animation.cardFlip], {
          [s.pauseAnimation]: state?.pauseAnimation,
          [s.active]: isBack,
        })}
        onClick={onClick}
        {...testProps({
          el: 'cardEl',
          side: isBack ? CardSidesEnum.BACK : CardSidesEnum.FRONT,
          label: isBack ? element.value.back.label : element.value.front.label,
        })}
      >
        {!isBook &&
          cardsConfig.map(({ side, active }) => (
            <CardSide
              active={active}
              font={font}
              id={element.id}
              key={side}
              mode={mode}
              onChange={onChange}
              onClick={onFlipCard}
              onMouseEnter={onMouseEnterIcon}
              side={side}
              styles={styles}
              value={element.value}
              waiting={waiting}
            />
          ))}
        {isBook && (
          <div
            className={cn(s.book, { [s.active]: isBack })}
            style={{ boxShadow: styles.effects.boxShadow, borderRadius: styles.card.borderRadius }}
          >
            {bookConfig.map(({ className, side, active }, index) => (
              <div
                className={cn(s[className], {
                  [s.transparentFront]: styles.sideA.backgroundColor === 'transparent',
                  [s.transparentBack]: styles.sideB.backgroundColor === 'transparent',
                })}
                key={index}
              >
                <CardSide
                  active={active}
                  font={font}
                  id={element.id}
                  mode={mode}
                  onChange={onChange}
                  onClick={onFlipCard}
                  onMouseEnter={onMouseEnterIcon}
                  side={side}
                  styles={styles}
                  value={element.value}
                  waiting={waiting}
                  offShadow
                />
              </div>
            ))}
          </div>
        )}
        <div className={s.fill} style={{ borderRadius: styles.card.borderRadius }} />
      </div>
    </ElementRootStyled>
  )
}

export default CardElement

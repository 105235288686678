import { CardFontSchemaType, CardSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, CardElementValue, ScalingCardEnum } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import { useState } from 'react'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './CardElement'

const DEMO_VALUE: CardElementValue = {
  front: {
    label: textToRtValue(t('elements.card.tags.sideA')),
    image: null,
    scaling: ScalingCardEnum.fill,
    overlay: '#FFFFFF00',
  },
  back: {
    label: textToRtValue(t('elements.card.tags.sideB')),
    image: null,
    scaling: ScalingCardEnum.fill,
    overlay: '#FFFFFF00',
  },
}

const CardDemo: React.FC<IElementDemoProps<CardSchemaType, CardFontSchemaType>> = ({
  styles,
  fonts,
}) => {
  const [state, setState] = useState({ isBack: false, pauseAnimation: false })

  return (
    <DemoWrapper center>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.card.type}-demo`,
          type: CONFIG_ELEMENTS.card.type,
          value: DEMO_VALUE,
          style: {},
          font: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        setState={setState}
        state={state}
        styles={styles}
      />
    </DemoWrapper>
  )
}

export default CardDemo

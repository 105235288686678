import { VideoSchemaType } from '@leenda/editor/lib/brand'

import { ElementStyleCss } from 'services/Branding/types'

export const PLAYBACK_RATE_OPTIONS = [
  { value: 0.5, label: '0.5 x' },
  { value: 1, label: '1.0 x' },
  { value: 1.5, label: '1.5 x' },
  { value: 2, label: '2.0 x' },
]

export const DEFAULT_STYLES: ElementStyleCss<VideoSchemaType> = {
  player: {
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  border: {
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: 'rgba(228, 228, 228, 1)',
    borderWidth: '1px',
  },
  icons: {
    color: 'rgba(27, 27, 27, 1)',
  },
  activeCC: {
    color: 'rgba(1, 176, 117, 1)',
  },
  slider: {
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },
  sliderFilled: {
    backgroundColor: 'rgba(1, 176, 117, 1)',
  },
  play: {
    backgroundColor: 'rgba(1, 176, 117, 1)',
  },
  hover: {
    backgroundColor: 'rgba(1, 176, 117, 1)',
  },
  effects: { boxShadow: 'none' },
  root: {},
  shell: {},
}

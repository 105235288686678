import Button from 'components/uiKit/Button'
import Modal, { MCWithoutParams, ModalBody, ModalFooter, ModalHeader } from 'components/uiKit/Modal'
import { t } from 'services/Translation'

const FormExitModal: MCWithoutParams<boolean | null> = ({ onClose }) => {
  const handleExit = () => onClose(false)
  const handleExitAndSave = () => onClose(true)
  return (
    <Modal maxWidth={560} name='confirmation' title={t('form.exit.modal.title')}>
      <ModalHeader
        description={t('form.exit.modal.description')}
        title={t('form.exit.modal.title')}
      />
      <ModalBody sizeAutoCapable>
        <div />
      </ModalBody>
      <ModalFooter>
        <Button name='exit' onClick={handleExit} styleType='ghost'>
          {t('uiKit.button.exit')}
        </Button>
        <Button name='exitAndSave' onClick={handleExitAndSave}>
          {t('uiKit.button.saveAndExit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormExitModal

import {
  PositionTabsElementType,
  SCHEMA,
  SizeTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import { getParent } from 'utils'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { simplifyStyleValue } from 'services/Branding/constants/fields'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

import indentContent from './assets/indent_content.svg'
import indentTabs from './assets/indent_tabs.svg'

const ALIGN_TEXT_OPTIONS = [
  { value: 'start', label: <Icon name='alignmentAlignLeft' size={KitSize.S} /> },
  { value: 'center', label: <Icon name='alignmentAlignMid' size={KitSize.S} /> },
  { value: 'end', label: <Icon name='alignmentAlignRight' size={KitSize.S} /> },
]

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.tabs.style, {
  view: {
    META: { hidden: true },
    type: {
      label: t('input.label.type'),
      type: 'select',
      layout: ['horizontal'],
      params: {
        options: [
          getEnumOption('ViewTabsElementType', ViewTabsElementType.solid),
          getEnumOption('ViewTabsElementType', ViewTabsElementType.splitted),
        ],
      },
    },
    borderRadius: {
      label: t('input.label.borderRadius'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.splitted }
      },
    },
  },
  border: {
    META: { label: t('input.label.border'), hidden: true },
    borderStyle: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.splitted }
      },
    },
    borderColor: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.splitted }
      },
    },
    borderWidth: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.splitted, max: 8 }
      },
    },
  },
  tabs: {
    META: { label: t('elements.tabs.tags.tabs') },
    position: {
      label: t('input.label.position'),
      type: 'segmented',
      layout: ['vertical'],
      params: {
        fluid: true,
        options: [
          getEnumOption('PositionTabsElementType', PositionTabsElementType.top),
          getEnumOption('PositionTabsElementType', PositionTabsElementType.left),
          getEnumOption('PositionTabsElementType', PositionTabsElementType.right),
        ],
      },
    },
    tabSize: {
      label: t('input.label.tabSize'),
      type: 'select',
      layout: ['horizontal', 'solid'],
      params: {
        options: [
          getEnumOption('SizeTabsElementType', SizeTabsElementType.fixed),
          getEnumOption('SizeTabsElementType', SizeTabsElementType.hug),
        ],
      },
    },
    width: {
      label: t('input.label.width'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const tabSize = simplifyStyleValue(element?.tabs?.tabSize)
        return { hidden: tabSize === SizeTabsElementType.hug, max: 760 }
      },
    },
    textAlign: {
      label: t('input.label.alignText'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const tabSize = simplifyStyleValue(element?.tabs?.tabSize)
        return {
          hidden: tabSize === SizeTabsElementType.hug,
          options: ALIGN_TEXT_OPTIONS,
          fluid: false,
        }
      },
    },
    backgroundColor: {},
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  activeTab: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.tabs.tags.selectTab'), params: { resettable: true } },
  },
  borderTab: {
    META: { hidden: true },
    borderRadius: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.solid }
      },
    },
    borderStyle: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.solid }
      },
    },
    borderColor: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.solid }
      },
    },
    borderWidth: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.solid }
      },
    },
  },
  splitter: {
    META: { label: t('elements.tabs.tags.splitter'), hidden: true },
    borderStyle: {
      label: t('input.label.showDivider'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.splitted }
      },
    },
    borderColor: {
      label: t('input.label.dividerColor'),

      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        const borderStyle = simplifyStyleValue(element?.splitter?.borderStyle)
        const isDivider = borderStyle === 'none'
        return { hidden: type === ViewTabsElementType.splitted || isDivider }
      },
    },
    borderWidth: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        const borderStyle = simplifyStyleValue(element?.splitter?.borderStyle)
        const isDivider = borderStyle === 'none'
        return {
          hidden: type === ViewTabsElementType.splitted || isDivider,
          max: 8,
        }
      },
    },
  },
  content: {
    META: { label: t('elements.tabs.tags.content') },
    backgroundColor: {},
    borderRadius: {
      label: t('input.label.borderRadius'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.solid }
      },
    },
    borderStyle: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.solid }
      },
    },
    borderColor: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return { hidden: type === ViewTabsElementType.solid }
      },
    },
    borderWidth: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const type = simplifyStyleValue(element?.view?.type)
        return {
          hidden: type === ViewTabsElementType.solid,
          max: 8,
        }
      },
    },
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.tabs.schema.boxShadow') },
  },
  content_effects: {
    META: { hidden: true },
    boxShadow: {
      label: t('elements.tabs.schema.contentBoxShadow'),
      params: (data, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, data, 2)
        const type = simplifyStyleValue(element?.view?.type)
        const hidden = type === ViewTabsElementType.solid
        return { hidden }
      },
    },
  },
  icons: {
    META: { label: t('elements.tabs.tags.icon') },
    show: {
      label: t('elements.tabs.tags.icon.showButton'),
      type: 'segmented',
      layout: ['horizontal', 'solid'],
      info: t('elements.tabs.tags.icon.description'),
    },
    backgroundColor: {
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const show = simplifyStyleValue(element?.icons?.show)
        return { hidden: !show }
      },
    },
  },
  iconsHover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('elements.tabs.schema.hoverColor'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const show = simplifyStyleValue(element?.icons?.show)
        return { hidden: !show, resettable: true }
      },
    },
  },
  iconsColor: {
    META: { hidden: true },
    color: {
      label: t('elements.tabs.schema.iconColor'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const show = simplifyStyleValue(element?.icons?.show)
        return { hidden: !show }
      },
    },
  },
  iconLeft: {
    META: { hidden: true },
    backgroundImage: {
      label: t('elements.tabs.schema.prev'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const show = simplifyStyleValue(element?.icons?.show)
        return { hidden: !show, resettable: true, preview: false }
      },
    },
  },
  iconRight: {
    META: { hidden: true },
    backgroundImage: {
      label: t('elements.tabs.schema.next'),
      params: (block, config) => {
        const { parent: element } = getParent<TabsSchemaType>(config.name, block, 2)
        const show = simplifyStyleValue(element?.icons?.show)
        return { hidden: !show, resettable: true, preview: false }
      },
    },
  },
  indents: {
    META: { label: t('elements.tabs.tags.indents') },
    padding: {
      label: t('elements.tabs.schema.activeTabPaddings'),
      layout: 'vertical',
      info: <img alt='image' src={indentTabs} style={{ width: 272 }} />,
    },
    margin: {
      label: t('elements.tabs.schema.contentPaddings'),
      layout: 'vertical',
      info: <img alt='image' src={indentContent} style={{ width: 272 }} />,
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.tabs.font, {
  selected: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.tabs.font.selected') },
  },
  tabs: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.tabs.font.tabs') },
  },
})

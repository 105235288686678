import { AccordionSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { hover } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

import { Position } from '../AccordionElement'
import { transformArrayToString } from './styled'

const right = css`
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
`

const TitleStyled = styled.div<{
  $isOpen?: boolean
  $styles: ElementStyleCss<AccordionSchemaType>
}>`
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  ${({ $styles }) => ($styles.icon?.position as Position) === 'right' && right}

  padding: ${({ $styles }) => transformArrayToString($styles.indents.accordion)};
  background-color: ${({ $styles }) => $styles.collapse.backgroundColor};
  background-color: ${({ $isOpen, $styles }) => $isOpen && $styles.select.backgroundColor};

  ${hover}
`

export default TitleStyled

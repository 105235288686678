import { useEffect, useState } from 'react'

import DataFallback from 'components/DataFallback'
import { EMPTY_OBJECT } from 'constants/commonConstans'
import { SCORM_PATHS } from 'constants/paths'
import { SuspendDataType } from 'gql/__generated__/graphql'
import { useBuildLastGetByProjectIdQuery } from 'gql/export/apollo'
import { useProjectsDeepGetById, projectsDeepGetSetCache } from 'gql/projects/apollo'
import { usePathParams } from 'routes/hooks'
import { SCORM_API_Wrapper } from 'scormWrapper/vendors/SCORM_API_Wrapper'

import ScormPreview from './ScormPreview'

const AppScormPreview = () => {
  const { projectId } = usePathParams(SCORM_PATHS.mainV2)
  const [loading, setLoading] = useState(true)
  const { data: buildData, loading: loadingBuild } = useBuildLastGetByProjectIdQuery(projectId)
  const { data: projectData, loading: loadingProject } = useProjectsDeepGetById(projectId)

  useEffect(() => {
    let needUnload = buildData?.data?.settings?.data?.suspendDataType?.includes(
      SuspendDataType.unload,
    )
    const unload = () => {
      if (needUnload) {
        SCORM_API_Wrapper.suspend()
        needUnload = false
      }
    }
    window.addEventListener('unload', unload)
    window.addEventListener('beforeunload', unload)
  }, [])

  useEffect(() => {
    if (!loadingBuild && !loadingProject && projectData?.data) {
      // @ts-ignore
      window.defaultData = {
        projectId,
        data: projectData,
        settings: buildData?.data?.settings,
      }

      projectsDeepGetSetCache(projectData?.data as any)
      SCORM_API_Wrapper.init(true)
      setLoading(false)
    }
  }, [buildData, projectData, loadingBuild, loadingProject])
  return (
    <DataFallback config={EMPTY_OBJECT} loading={loading}>
      {() => <ScormPreview />}
    </DataFallback>
  )
}
export default AppScormPreview

const PORTAL_POSTFIX = '__portal'

export const COMPANY_FORM_CONTROLS = `companyFormControls${PORTAL_POSTFIX}`
export const PROFILE_FORM_CONTROLS = `profileFormControls${PORTAL_POSTFIX}`
export const EMPLOYEE_FORM_CONTROLS = `employeeFormControls${PORTAL_POSTFIX}`
export const BRAND_THEME_FORM_CONTROLS = `brandThemeFormControls${PORTAL_POSTFIX}`
export const COURSE_TOOLTIP = `courseTooltip${PORTAL_POSTFIX}`
export const PROJECTS_TRASH_PORTAL = `projectTrash${PORTAL_POSTFIX}`
export const SECTIONS_TRASH_PORTAL = `sectionTrash${PORTAL_POSTFIX}`
export const HUBSPOT_PORTAL = `hubSpot${PORTAL_POSTFIX}`

import { AnimationTypeCardEnum, FlipMethodCardEnum, SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

import { ICON_ALIGN_OPTIONS } from './constants'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.card.style, {
  card: {
    META: { label: t('elements.card.tags.card') },
    height: { params: { placeholder: '380px', min: 152 } },
    width: { params: { placeholder: '290px', min: 152 } },
    borderRadius: { params: { max: 40 } },
  },
  border: {
    META: { label: t('elements.card.tags.border'), hidden: true },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  icon: {
    META: { label: t('elements.card.tags.iconButton') },
    height: { params: { placeholder: '40px', min: 1 } },
    width: { params: { placeholder: '40px', min: 1 } },
    borderRadius: { params: { min: 0, max: 100 } },
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  sideA: {
    META: { label: t('elements.card.tags.sideA') },
    backgroundColor: {},
    alignItems: {
      params: {
        fluid: false,
      },
    },
  },
  sideAIcon: {
    META: { hidden: true },
    backgroundImage: {
      label: t('elements.card.tags.sides.flipButton'),
      params: { preview: false },
    },
    backgroundColor: {
      label: t('style.buttonBackground'),
    },
    color: {
      label: t('style.iconColor'),
    },
    iconsAlign: genField({
      label: t('elements.card.tags.sides.button.position'),
      type: 'radio',
      layout: 'horizontal',
      params: {
        fluid: false,
        hideRadio: true,
        direction: 'horizontal',
        options: ICON_ALIGN_OPTIONS,
      },
    }),
  },
  sideB: {
    META: { label: t('elements.card.tags.sideB') },
    backgroundColor: {},
    alignItems: {
      params: {
        fluid: false,
      },
    },
  },
  sideBIcon: {
    META: { hidden: true },
    backgroundImage: {
      label: t('elements.card.tags.sides.flipButton'),
      params: { preview: false },
    },
    backgroundColor: {
      label: t('style.buttonBackground'),
    },
    color: {
      label: t('style.iconColor'),
    },
    iconsAlign: genField({
      label: t('elements.card.tags.sides.button.position'),
      type: 'radio',
      layout: 'horizontal',
      params: {
        fluid: false,
        hideRadio: true,
        direction: 'horizontal',
        options: ICON_ALIGN_OPTIONS,
      },
    }),
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.card.schema.boxShadow') },
  },
  animation: {
    META: { label: t('elements.card.tags.animation') },
    type: genField({
      label: t('elements.card.tags.animation.type'),
      type: 'select',
      layout: 'horizontal',
      params: {
        options: [
          getEnumOption('FlipMethodCardEnum', FlipMethodCardEnum.click),
          getEnumOption('FlipMethodCardEnum', FlipMethodCardEnum.hover),
        ],
      },
    }),
    cardFlip: genField({
      label: t('elements.card.tags.animation.cardFlip'),
      type: 'select',
      layout: 'horizontal',
      params: {
        options: [
          getEnumOption('AnimationTypeCardEnum', AnimationTypeCardEnum.horizontalFlip),
          getEnumOption('AnimationTypeCardEnum', AnimationTypeCardEnum.verticalFlip),
          getEnumOption('AnimationTypeCardEnum', AnimationTypeCardEnum.diagonalFlip),
          getEnumOption('AnimationTypeCardEnum', AnimationTypeCardEnum.book),
          getEnumOption('AnimationTypeCardEnum', AnimationTypeCardEnum.fadeEffect),
        ],
      },
    }),
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.card.font, FONT_SCHEMA_FIELDS)

import { useLocalStorageState } from 'ahooks'
import { SetState } from 'ahooks/lib/createUseStorageState'
import * as R from 'ramda'
import { useMemo, useState } from 'react'
import { createContext } from 'use-context-selector'

import { SortUiEnum } from 'components/uiKit/SortPopover/types'
import { NOOP } from 'constants/commonConstans'
import {
  TemplateCollectionCreatedFor,
  EditorTemplateAllTab,
  EmployeePreferencesTab,
  TemplatesListSchemaFragment,
  EditorTemplateOrder,
  RecentlyUsedTemplatesType,
} from 'gql/__generated__/graphql'
import { useTemplatesAll, useTemplatesAllIds } from 'gql/templates/apollo'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock } from 'services/Store/Project/selectors'
import { isCtrl } from 'utils/events'

import { UiTemplateTags, TemplatePickerMode } from './types'

export const useTags = ({
  sectionType,
  propTag,
  tagFromLocalStorage,
}: {
  sectionType?: SectionTypeEnum
  propTag?: UiTemplateTags
  tagFromLocalStorage?: boolean
}) => {
  const block = useProjectContext(getBlock)
  const landingSection = sectionType === SectionTypeEnum.landing

  const defaultTag = useMemo(() => {
    if (
      !landingSection &&
      (block?.mode === BlockMode.start ||
        block?.mode === BlockMode.questions ||
        block?.mode === BlockMode.end)
    ) {
      return block.mode as unknown as UiTemplateTags
    }
    if (!sectionType) {
      return UiTemplateTags.all
    }
    return UiTemplateTags.all
  }, [block?.mode])

  const initialTag = useMemo(() => propTag || defaultTag, [propTag, defaultTag])

  const [tag, setTag] = useState<UiTemplateTags>(initialTag || UiTemplateTags.all)

  const [tagsCollection, setTagsCollection] = useLocalStorageState<UiTemplateTags>(
    `tags-templates`,
    { defaultValue: UiTemplateTags.all },
  )

  const showTags = landingSection || !sectionType

  return {
    tag: tagFromLocalStorage
      ? tagsCollection || UiTemplateTags.all
      : landingSection || !sectionType
        ? tag
        : initialTag,
    setTag: (tagFromLocalStorage ? setTagsCollection : setTag) as React.Dispatch<
      React.SetStateAction<UiTemplateTags>
    >,
    showTags,
  }
}

export const useSelected = () => {
  const [selected, setSelected] = useState<{
    single: string[]
    multi: string[]
  }>({ single: [], multi: [] })

  const addItem = (item: any, e: React.MouseEvent) => {
    const lastIndex = selected.multi.map((uuid) => uuid === item.uuid).lastIndexOf(true)
    if (lastIndex >= 0 && !isCtrl(e)) {
      removeByIndex(lastIndex + 1, e)
      return
    }
    if ((lastIndex < 0 && !isCtrl(e)) || isCtrl(e) || !selected.multi.length) {
      setSelected((prev) => {
        return { ...prev, multi: [...prev.multi, item.uuid] }
      })
    }
  }

  const removeByIndex = (value: number, e: React.MouseEvent) => {
    if (!isCtrl(e)) {
      setSelected((prev) => ({ ...prev, multi: prev.multi.filter((_, i) => i !== value - 1) }))
    }
  }

  const clearSelect = () => {
    setSelected((prev) => ({ ...prev, multi: [] }))
  }

  return { selected, setSelected, addItem, removeByIndex, clearSelect }
}

export const getQueryVariables = (
  tag?: UiTemplateTags,
  type?: SectionTypeEnum,
  tab?: EmployeePreferencesTab | EditorTemplateAllTab,
) => {
  if (tab === EmployeePreferencesTab.favorites || tab === EmployeePreferencesTab.recently_used) {
    if (!type) {
      return { mode: [], tag: undefined }
    }
    if (type === SectionTypeEnum.cover) {
      return { tag: BlockMode.cover }
    }
    if (type === SectionTypeEnum.test && !tag) {
      return { tag: UiTemplateTags.questions, mode: [BlockMode.questions] }
    }
    if (type === SectionTypeEnum.test && tag) {
      return { tag, mode: [tag] }
    }
    if (type === SectionTypeEnum.landing) {
      const mode: string[] = [BlockMode.view, BlockMode.questions]
      return { mode, tag: undefined }
    }
  }

  if (!type) {
    if (tag === UiTemplateTags.all) {
      return { tag: undefined }
    }
    if (tag === UiTemplateTags.cover) {
      return { mode: [BlockMode.cover] }
    }
  }

  if (type === SectionTypeEnum.cover) {
    return { mode: [BlockMode.cover] }
  }
  if (type === SectionTypeEnum.test && tag) {
    return { mode: [tag] }
  }
  if (type === SectionTypeEnum.landing) {
    const mode: string[] = [BlockMode.view, BlockMode.questions]
    if (tag === UiTemplateTags.all) {
      return { mode, tag: undefined }
    }
    return { mode, tag }
  }

  return {
    mode: [],
    tag,
  }
}

const sectionTypeToRecentlyUsedTemplatesType = (type?: SectionTypeEnum) => {
  switch (type) {
    case SectionTypeEnum.test:
      return RecentlyUsedTemplatesType.test
    case SectionTypeEnum.landing:
      return RecentlyUsedTemplatesType.landing
  }
  return null
}

export const useTemplates = ({
  type,
  tag,
  search,
  editorTemplateGroupId,
  companyId,
  projectId,
  sectionId,
  tab = EmployeePreferencesTab.collections,
  order,
  limit = 20,
  offset = 0,
}: {
  type?: SectionTypeEnum
  tag?: UiTemplateTags
  search?: string
  editorTemplateGroupId?: string
  companyId: string
  projectId?: string
  tab?: EmployeePreferencesTab
  sectionId?: string
  order?: EditorTemplateOrder | null
  limit?: number
  offset?: number
}) => {
  const variables = {
    companyId,
    data: {
      projectId: projectId || undefined,
      ...getQueryVariables(tag, type, tab),
      editorTemplateGroupId:
        tab === EmployeePreferencesTab.collections ? editorTemplateGroupId : undefined,
      query: search,
      tab: tab as unknown as EditorTemplateAllTab,
      sectionId,
      type: sectionTypeToRecentlyUsedTemplatesType(type),
      pagination: {
        limit,
        offset,
      },
      order,
    },
  }

  const { data: dataTemplates, loading, fetchMore } = useTemplatesAll(variables)
  const { data: dataTemplatesAllIds } = useTemplatesAllIds(variables)

  const needFetchMore =
    !loading &&
    (dataTemplates?.data.pagination.total || 0) > (dataTemplates?.data.templates.length || 0)

  const fetchMoreTemplates = async () => {
    await fetchMore({
      variables: R.mergeDeepRight(variables, {
        data: { pagination: { offset: dataTemplates?.data.templates.length } },
      }),
    })
  }

  return {
    loading,
    templates: dataTemplates?.data.templates || [],
    templatesAllIds: dataTemplatesAllIds?.data || [],
    fetchMore: needFetchMore ? fetchMoreTemplates : undefined,
  }
}

export interface ITemplatePickerContext {
  collectionId?: string
  companyId: string
  mode: TemplatePickerMode
  onClick: (value: TemplatesListSchemaFragment, e: React.MouseEvent) => void
  projectId: string
  removeByIndex: (value: number, e: React.MouseEvent) => void
  search?: string
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>
  selected: {
    single: string[]
    multi: string[]
  }
  setCollection: React.Dispatch<
    React.SetStateAction<{
      id: string
      createdFor?: TemplateCollectionCreatedFor | undefined
      name?: string | undefined
    } | null>
  >
  setTemplates: React.Dispatch<React.SetStateAction<TemplatesListSchemaFragment[]>>
  step: number | null
  type?: SectionTypeEnum
  sectionId?: string
  setStep: React.Dispatch<React.SetStateAction<number | null>>
  allSelected: string[]
  blocksByMode?: Record<string, boolean>
  tag: UiTemplateTags
  setTag: React.Dispatch<React.SetStateAction<UiTemplateTags>>
  showTags: boolean
  columns?: number
  setColumns: (value?: number | SetState<number> | undefined) => void
  tab: EmployeePreferencesTab
  order: SortUiEnum | null
  setOrder: React.Dispatch<React.SetStateAction<SortUiEnum | null>>
  showBrand?: boolean
  collection: {
    id: string
    createdFor?: TemplateCollectionCreatedFor | undefined
    name?: string | undefined
  } | null
}

export const DEFAULT_CONTEXT_VALUE = {
  collectionId: '',
  companyId: '',
  mode: TemplatePickerMode.multi,
  onClick: NOOP,
  projectId: '',
  removeByIndex: NOOP,
  search: '',
  setSearch: NOOP,
  selected: { single: [], multi: [] },
  setCollection: NOOP,
  setTemplates: NOOP,
  step: null,
  type: undefined,
  sectionId: '',
  setStep: NOOP,
  allSelected: [],
  blocksByMode: {},
  tag: UiTemplateTags.all,
  setTag: NOOP,
  showTags: false,
  columns: 3,
  setColumns: NOOP,
  tab: EmployeePreferencesTab.collections,
  order: null,
  setOrder: NOOP,
  showBrand: false,
  collection: null,
}

export const TemplatePickerContext = createContext<ITemplatePickerContext>(DEFAULT_CONTEXT_VALUE)

import { DroppableProvided } from '@hello-pangea/dnd'
import { GroupingGroup } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import * as R from 'ramda'
import { useContext } from 'use-context-selector'

import GroupingContext from 'components/editor-v2/EditorElements/elements/GroupingElement/GroupingContext'
import { t } from 'services/Translation'
import { useImageWithCrop } from 'utils/files'

import ScaledAnswer from '../ScaledAnswer/ScaledAnswer'
import s from './DestinationCard.module.scss'

interface IDestinationProps {
  group: GroupingGroup
  provided: DroppableProvided
}

const DestinationCard = ({ group, provided }: IDestinationProps) => {
  const { styles, hasGroupImage, testResult, font } = useContext(GroupingContext)
  const cropped = useImageWithCrop(group.target.image)
  const labelClasses = cn(s.label, { [s.placeholder]: !group.target.label })
  const label =
    group.target.label || (group?.target.image?.id && hasGroupImage)
      ? group.target.label || ''
      : t('elements.grouping.placeholder.group')

  return (
    <div
      aria-label={group.target.image?.accessibility}
      className={s.destItem}
      ref={provided.innerRef}
      style={{
        ...styles.group,
        ...styles.group_effects,
        ...font?.base,
        ...styles.indents,
        backgroundImage:
          cropped?.path && hasGroupImage
            ? `url(${cropped?.path})`
            : `url(${group.target.image?.url})`,
      }}
      {...provided.droppableProps}
    >
      <div className={s.mask} style={{ backgroundColor: group.target.overlay }} />
      <div className={labelClasses} style={{ padding: styles.indents.padding }}>
        {label}
      </div>
      {group.items.map((i) => {
        const isCorrect = R.last(R.values(testResult))

        return (
          <ScaledAnswer
            isCorrect={isCorrect as boolean}
            item={i}
            key={i.value}
            value={group.value}
          />
        )
      })}
      {provided.placeholder}
    </div>
  )
}

export default DestinationCard

import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.image.style, {
  image: {
    objectFit: {},
    META: { hidden: true },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.image.schema.boxShadow') },
  },
})

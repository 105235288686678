import {
  getThemeValue,
  PositionTabsElementType,
  TabsFontSchemaType,
  TabsSchemaType,
  ThemeType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import { motion } from 'framer-motion'
import styled, { css, DefaultTheme } from 'styled-components'

import { font } from 'services/Branding/common.styled'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

import { DEFAULT_INDENT_TAB, Z_INDEX_TAB_MARKER } from './TabElementStyled'

const borderRadiusSolidTop = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  border-bottom: none;
  border-top-left-radius: ${$styles.view.borderRadius};
  border-top-right-radius: ${$styles.view.borderRadius};
`

const borderRadiusSolidLeft = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  border-right: none;
  border-radius: 0;
  border-bottom-left-radius: ${$styles.view.borderRadius};
  border-top-left-radius: ${$styles.view.borderRadius};
  border-top-right-radius: 0;
`

const borderRadiusSolidRight = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  border-left: none;
  border-radius: 0;
  border-bottom-right-radius: ${$styles.view.borderRadius};
  border-top-right-radius: ${$styles.view.borderRadius};
  border-top-left-radius: 0;
`

const solid = ($styles: ElementStyleCss<TabsSchemaType>) => {
  const position = $styles.tabs.position as PositionTabsElementType
  return css`
    inset: -${$styles.border.borderWidth};
    border-width: ${$styles.border.borderWidth};
    border-color: ${$styles.border.borderColor};
    border-style: ${$styles.border.borderStyle};
    ${position === PositionTabsElementType.top && borderRadiusSolidTop($styles)};
    ${position === PositionTabsElementType.left && borderRadiusSolidLeft($styles)};
    ${position === PositionTabsElementType.right && borderRadiusSolidRight($styles)};
  `
}

const activeBorderColorSolid = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  border-color: ${$styles.border.borderColor};
`

const activeBorderColorSplitted = (
  $styles: ElementStyleCss<TabsSchemaType>,
  theme: DefaultTheme,
) => {
  const borderStyle = getThemeValue($styles.borderTab.borderStyle, theme as ThemeType)
  return css`
    border-color: ${$styles.borderTab.borderColor};
    border-width: ${$styles.borderTab.borderWidth};
    border-style: ${$styles.borderTab.borderStyle};
    inset: -${borderStyle === 'solid' ? $styles.borderTab.borderWidth : 0};
  `
}

const activeTab = ($styles: ElementStyleCss<TabsSchemaType>, theme: DefaultTheme) => css`
  background: ${$styles.activeTab.backgroundColor};
  ${$styles.view.type === ViewTabsElementType.solid && activeBorderColorSolid($styles)};
  ${$styles.view.type === ViewTabsElementType.splitted &&
  activeBorderColorSplitted($styles, theme)};
`

const notActiveTabs = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  margin: ${DEFAULT_INDENT_TAB}px;
  &:hover {
    background-color: ${$styles.hover.backgroundColor};
    border-color: ${$styles.tabs.backgroundColor};
    border-radius: ${$styles.view.borderRadius};
  }
`

const splitted = (styles: ElementStyleCss<TabsSchemaType>) => css`
  border-radius: ${styles.borderTab.borderRadius};
  padding: ${styles.indents.padding};
  margin: 0;
  border-width: ${styles.borderTab.borderWidth};
  inset: -${styles.borderTab.borderWidth};
`

const TabItemMarkerStyled = styled(motion.div)<{
  $styles: ElementStyleCss<TabsSchemaType>
  $font: ElementFontCss<TabsFontSchemaType>
  $active: boolean
}>`
  position: absolute;
  z-index: ${Z_INDEX_TAB_MARKER};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-right: 0;
  border-left: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  ${({ $font: { tabs } }) => font(tabs)}
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.solid && solid($styles)};
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.splitted && splitted($styles)};
  ${({ $styles, $active, theme }) =>
    $active ? activeTab($styles, theme) : notActiveTabs($styles)};
`

export default TabItemMarkerStyled

import { DeviceMode } from '@leenda/editor'
import { MenuPositionType, SidebarMenuType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import { ReactNode } from 'react'

import { ElementStyleCss } from 'services/Branding/types'
import { IBlockMode } from 'services/Store/Project/types'

import s from './CourseSidebarLayout.module.scss'

interface ICourseSidebarLayout {
  styles: ElementStyleCss<SidebarSchemaType>
  children: ReactNode
  mode: IBlockMode
  width?: number | string
}

const CourseSidebarLayout: React.FC<ICourseSidebarLayout> = ({ styles, children, mode, width }) => {
  const menuPosition = styles.menu.position as MenuPositionType
  const drawer = styles.menu.type === SidebarMenuType.drawer
  const fixed = styles.menu.type === SidebarMenuType.fixed
  const isMobile = mode.deviceMode === DeviceMode.mobile
  const rootStyles = { backgroundColor: styles.menu.backgroundColor, width, ...styles.effects }
  const rootCn = cn(s.root, {
    [s.positionRight]: menuPosition === MenuPositionType.right,
    [s.fluid]: isMobile && fixed,
    [s.drawer]: drawer,
  })

  return (
    <div className={rootCn} style={rootStyles}>
      <div className={s.container}>{children}</div>
    </div>
  )
}
export default CourseSidebarLayout

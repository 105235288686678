import { useQuery, useMutation } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { gqlClient } from 'gql'

import { ProjectsDeepGetByIdQuery } from 'gql/__generated__/graphql'
import { employeesGetAllQuery } from 'gql/employees/gql/queries'
import { participantsAll } from 'gql/participants/gql/queries'
import { projectGroupAll } from 'gql/projectGroups/gql/queries'

import {
  createProject,
  projectsUpdateLocale,
  projectsUpdateOwners,
  projectUpdateOwner,
  updateProject,
} from './gql/mutations'
import { meInProjectQuery, projectDeepQuery, projectsGetByIdQuery } from './gql/queries'

export const useProjectsGetById = (
  projectId: string,
  options: { fetchPolicy: 'cache-and-network' | 'cache-first' } = { fetchPolicy: 'cache-first' },
) => {
  return useQuery(projectsGetByIdQuery, {
    variables: { projectId },
    nextFetchPolicy: 'cache-first',
    fetchPolicy: options.fetchPolicy,
    onError: (err) =>
      console.error('"useProjectsGetById" fn is crashed on operation: "useQuery"', err),
  })
}

export const useMeInProject = (projectId: string, skip = false) => {
  return useQuery(meInProjectQuery, {
    variables: { projectId },
    skip,
    onError: (err) =>
      console.error('"useProjectsGetById" fn is crashed on operation: "useQuery"', err),
  })
}
export const projectsDeepGetSetCache = (data: ProjectsDeepGetByIdQuery['data']): void => {
  try {
    gqlClient.core.writeQuery<ProjectsDeepGetByIdQuery>({
      query: projectDeepQuery,
      variables: {
        projectId: data?.id,
      },
      data: {
        data,
      },
    })
  } catch (err) {
    console.error('"useProjectsDeepGetSetCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const useProjectsDeepGetById = (
  projectId: string,
  isCacheOnly?: boolean,
  data?: { sectionId?: string | null; blockId?: string | null },
) =>
  useQuery(projectDeepQuery, {
    variables: { projectId, data },
    fetchPolicy: isCacheOnly ? 'cache-only' : 'cache-first',
    onError: (err) =>
      console.error('"useProjectsDeepGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useAppProjectsDeepGetById = (id: string) =>
  useQuery(projectDeepQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { projectId: id },
    onError: (err) =>
      console.error('"useLazyProjectsDeepGetById" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useProjectsCreate = () =>
  useMutation(createProject, {
    refetchQueries: [String(getOperationName(projectGroupAll))],
    onError: (err) =>
      console.error('"useProjectsCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useProjectsUpdate = () =>
  useMutation(updateProject, {
    onError: (err) =>
      console.error('"useProjectsUpdate" fn is crashed on operation: "useMutation"', err),
  })

export const useProjectsUpdateLocale = () =>
  useMutation(projectsUpdateLocale, {
    onError: (err) =>
      console.error('"useProjectsUpdateLocale" fn is crashed on operation: "useMutation"', err),
  })

export const useProjectUpdateOwner = () =>
  useMutation(projectUpdateOwner, {
    onError: (err) =>
      console.error('"useProjectUpdateOwner" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(participantsAll))],
  })

export const useProjectUpdateOwners = () =>
  useMutation(projectsUpdateOwners, {
    onError: (err) =>
      console.error('"useProjectUpdateOwners" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(employeesGetAllQuery))],
  })

import { PermissionsKey } from '@leenda/crud'
import { useMemo } from 'react'
import { useNavigationRoutesSelected } from 'utils'

import { COMPANY_PATHS, PROJECT_HOME_PATHS, PROJECT_PATHS } from 'constants/paths'
import { useProjectsGetById } from 'gql/projects/apollo'
import { useSectionsAll } from 'gql/sections/apollo'
import generateLink from 'routes/generateLink'
import { usePathParams } from 'routes/hooks'
import { usePermissions } from 'services/Permission/PermissionContext'
import { t } from 'services/Translation'
import { NavItem } from 'store/models/navigation.model'
import { useSectionsRightOrder } from 'utils/project.hooks'

export const useGetSectionsNavRoutes = (): {
  routes: NavItem[]
  selectedRoute?: NavItem
} => {
  const params = usePathParams(COMPANY_PATHS.project)
  const permissions = usePermissions()

  const linkToUsers = generateLink(PROJECT_HOME_PATHS.users, params)
  const linkToSections = generateLink(PROJECT_HOME_PATHS.sections, params)
  const linkToExport = generateLink(PROJECT_HOME_PATHS.export, params)
  const linkToFiles = generateLink(PROJECT_HOME_PATHS.files, { ...params, fileMetaGroupId: 'root' })
  const linkToTrello = generateLink(PROJECT_HOME_PATHS.trello, params)
  const linkToSettings = generateLink(PROJECT_HOME_PATHS.info, params)

  const routes = useMemo(
    () => [
      {
        label: t('page.editor.subtitle'),
        path: linkToSections.pathname,
        value: 'sections',
      },
      {
        label: t('page.finder.title'),
        path: linkToFiles.pathname,
        value: 'sources',
        hidden: !permissions.has([
          PermissionsKey.project_resource_r,
          PermissionsKey.project_pages_home,
        ]),
      },
      {
        label: t('page.trello.title'),
        path: linkToTrello.pathname,
        value: 'trello',
        hidden: !permissions.has([
          PermissionsKey.project_tasks_r,
          PermissionsKey.project_pages_home,
        ]),
      },
      {
        label: t('page.participants.title'),
        path: linkToUsers.pathname,
        value: 'users',
        hidden: !permissions.has([
          PermissionsKey.project_team_list_r,
          PermissionsKey.project_pages_home,
        ]),
      },
      {
        label: t('page.export.title'),
        path: linkToExport.pathname,
        value: 'export',
        hidden: !permissions.has([
          PermissionsKey.project_scormExport_r,
          PermissionsKey.project_pages_home,
        ]),
      },
      {
        label: t('common.settings'),
        path: linkToSettings.pathname,
        value: 'main',
        hidden: !permissions.has(PermissionsKey.project_pages_home),
      },
    ],
    [
      linkToExport.pathname,
      linkToFiles.pathname,
      linkToSections.pathname,
      linkToSettings.pathname,
      linkToTrello.pathname,
      linkToUsers.pathname,
      permissions,
    ],
  )

  const selectedRoute = useNavigationRoutesSelected(routes)

  return { routes, selectedRoute }
}

export const useGetSortedSections = () => {
  const { projectId } = usePathParams(PROJECT_PATHS.home)
  const { data: projectData, loading: projectLoading } = useProjectsGetById(projectId, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: sectionsAllData, loading: isSectionsLoading } = useSectionsAll(projectId)

  const sections = useSectionsRightOrder(
    sectionsAllData?.data.sections,
    projectData?.data?.sectionsOrder,
  )
  const firstSection = useMemo(() => sections.find((section) => !section.isChapter), [sections])
  return {
    sections,
    project: projectData?.data,
    loading: projectLoading || isSectionsLoading,
    firstSection,
  }
}

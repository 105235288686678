"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/TestKeyboardInput/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    input: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightBackgroundColor }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
        padding: (0, genStyle_1.genStyle)({ value: [16, 24, 16, 24] }),
    },
    input_hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    input_focus: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
    effects: {
        boxShadow: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.boxShadow }),
    },
};
const fontSchema = {
    base: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
    placeholder: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }) }),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };

import React, { ImgHTMLAttributes, useMemo, useState } from 'react'

import { EMPTY_OBJECT, preventDefault } from 'constants/commonConstans'
import { FileSchemaFragment } from 'gql/__generated__/graphql'

import s from './ResponsiveImage.module.scss'

interface IResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  file?: FileSchemaFragment
  overlay?: string
}

const ResponsiveImage = React.forwardRef<HTMLImageElement, IResponsiveImageProps>(
  ({ file, src, overlay, ...rest }, ref) => {
    const [loading, setLoading] = useState(true)
    const imgWidth = '100%'
    const imgHeight = '100%'
    const canvas = useMemo(() => (loading ? { width: '100%' } : { display: 'none' }), [loading])
    const img = useMemo(
      () =>
        loading ? { ...rest.style, position: 'absolute', inset: 0 } : rest.style || EMPTY_OBJECT,
      [loading, rest.style],
    )
    const handleLoad = () => setLoading(false)

    return (
      <>
        <canvas height={imgHeight} style={canvas} width={imgWidth} />
        {src && (
          <img
            {...rest}
            className={s.img}
            onDragStart={preventDefault}
            onLoad={handleLoad}
            ref={ref}
            src={src}
            style={img}
          />
        )}

        <div
          className={s.mask}
          style={{
            backgroundColor: overlay,
            borderRadius: img.borderRadius,
            width: imgWidth,
            height: imgHeight,
            top: rest.style?.borderWidth,
            left: rest.style?.borderWidth,
          }}
        />
      </>
    )
  },
)

ResponsiveImage.displayName = 'ResponsiveImage'

export default ResponsiveImage

import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconSectionsMap } from 'utils'

import {
  EDITABLE_INDENTS_EDITOR,
  MAX_LENGTH_SECTION_TITLE,
} from 'components/sections/SectionsTree/constants'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import Editable from 'components/uiKit/Editable'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { useSectionsUpdateById } from 'gql/sections/apollo'
import { scrollProps } from 'services/Scroll/ScrollService'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getProjectId } from 'services/Store/Project/selectors'
import { Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getEnumLabel } from 'utils/enum'
import { testPropsEl } from 'utils/test/qaData'

import { MoreActionsEditorSections } from '../../MoreActionsEditorSections'
import s from './SectionTreeItemEditor.module.scss'

export interface IDragCollectedProps {
  isDragging: boolean
}

interface ISectionTreeItemEditorProps {
  item: Section
  onSelect?: (sectionId: Section) => void
  searchValue?: string
  isEditSectionId?: string
  setIsEditSectionId: (activeId: string) => void
  hideActions?: boolean
  sectionId?: string
  disabledByTypes?: SectionTypeEnum[]
}

const SectionTreeItemEditor: React.FC<ISectionTreeItemEditorProps> = ({
  item,
  onSelect,
  searchValue,
  isEditSectionId,
  setIsEditSectionId,
  hideActions,
  sectionId,
  disabledByTypes,
}) => {
  const inputRef = useRef<HTMLSpanElement | null>(null)
  const { type, isDone, isChapter, test, publicLink, isHide, blocks } = item
  const cover = type === SectionTypeEnum.cover
  const iconName = IconSectionsMap[type as SectionTypeEnum]
  const active = item.id === sectionId
  const edit = isEditSectionId === item.id
  const disableItem = disabledByTypes?.includes(item.type)

  const testCount = test?.randomCount || 0
  const questionCount = useMemo(
    () => blocks.filter((block) => block.mode === 'questions' && !block.isHide).length,
    [blocks],
  )

  const handleClick = useCallback(
    () => !disableItem && onSelect?.(item),
    [onSelect, item, disableItem],
  )

  const [sectionUpdate, { loading }] = useSectionsUpdateById()

  const projectId = useProjectContext(getProjectId)
  const [value, setValue] = useState(item.name)

  const sectionNameUpdate = useCallback(async () => {
    await sectionUpdate({ variables: { data: { name: value }, id: item.id, projectId } })
    setIsEditSectionId('')
  }, [item.id, projectId, sectionUpdate, value, setIsEditSectionId])

  const handleSectionEdit = useCallback(
    (item: Section) => {
      if (disableItem) {
        return
      }
      setIsEditSectionId(item.id)
      onSelect?.(item)
      inputRef.current?.focus()
    },
    [setIsEditSectionId, onSelect, disableItem],
  )

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && active) {
        e.stopPropagation()
        e.preventDefault()
        sectionNameUpdate()
      }
      if (e.key === 'Escape' && active) {
        e.stopPropagation()
        e.preventDefault()
        setIsEditSectionId('')
      }
    },
    [active, sectionNameUpdate, setIsEditSectionId],
  )
  const onBlurSection = () => sectionNameUpdate()

  useEffect(() => {
    if (edit && active && !disableItem) {
      handleSectionEdit(item)
    }
  }, [active, isEditSectionId, handleSectionEdit, item.id, edit, item, disableItem])

  const rootCn = cn(s.root, { [s.activeItem]: active })
  const iconCn = cn(s.icon, active && !cover && s.activeIcon)
  const itemIconCn = cn(s.itemIcon, cover && s.itemIconCover, isChapter && s.itemIconChapter)
  const itemCn = cn(s.item, s[type], { [s.disabled]: disableItem })
  const itemContentCn = cn(s.itemContent, active && !cover && s.reset)

  return (
    <div className={rootCn} onClick={handleClick} {...scrollProps(item.id)}>
      <div
        {...testPropsEl(
          'sectionItem',
          { label: item.name, active: Boolean(active) },
          { id: item.id },
        )}
        className={itemCn}
      >
        <div className={cn(s.dragControl)}>
          <div className={itemIconCn}>{iconName?.icon}</div>
        </div>
        <div className={itemContentCn}>
          <Editable
            highlight={searchValue}
            indents={EDITABLE_INDENTS_EDITOR}
            max={MAX_LENGTH_SECTION_TITLE}
            name={`${item.type}.${item.id}`}
            onBlur={onBlurSection}
            onChange={setValue}
            onKeyDown={onKeyDown}
            placeholder={getEnumLabel('SectionTypeEnumPlaceholder', type)}
            readOnly={!edit}
            ref={inputRef}
            styleType={isChapter || cover ? 'hint' : 'default'}
            value={value}
            wordBreak='break-word'
            fluid
          />
          {!isChapter && (
            <>
              {isDone && (
                <Tooltip overlay={t('page.sections.section.closeSectionIcon')}>
                  <div className={iconCn}>
                    <Icon name='statusLock20Px' />
                  </div>
                </Tooltip>
              )}
              {publicLink.isOpen && !edit && (
                <Tooltip overlay={t('page.sections.section.publicLinkIcon')}>
                  <div className={iconCn}>
                    <Icon name='statusLink20Px' />
                  </div>
                </Tooltip>
              )}
              {isHide && !edit && (
                <Tooltip overlay={t('page.sections.section.hideIcon')}>
                  <div className={iconCn}>
                    <Icon name='statusHide20Px' />
                  </div>
                </Tooltip>
              )}
              {!isDone && test?.isShuffleQuestions && (test.randomCount || 0) > 0 && !edit && (
                <Tooltip
                  overlay={t('page.sections.section.randomLimitIcon', {
                    testCount,
                    questionCount,
                  })}
                >
                  <div className={iconCn}>
                    <Icon name='statusSumm20Px' />
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
      <div className={s.wrapActions}>
        <div className={cn(s.actions, { [s.selected]: active })}>
          {!edit ? (
            <MoreActionsEditorSections
              handleSectionClick={onSelect}
              handleSectionEdit={handleSectionEdit}
              hidden={edit || !!searchValue || !!hideActions}
              item={item}
            />
          ) : (
            <IconButton
              icon='checkmark1'
              loading={loading}
              name='saveSectionName'
              onClick={sectionNameUpdate}
              size={KitSize.S}
              styleType='ghost'
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SectionTreeItemEditor

import { gql } from 'gql/__generated__'

export const participantsInvite = gql(`
  mutation ParticipantsInvite($projectId: String!, $data: ParticipantCreateInput!) {
    data: participantsInvite(projectId: $projectId, data: $data) {
      participants {
        id
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const participantsBulkUpdate = gql(`
  mutation ParticipantsBulkUpdate($data: ParticipantUpdateInput!) {
    data: participantsBulkUpdate(data: $data) {
      id
    }
  }
`)

export const participantsBulkDelete = gql(`
  mutation ParticipantsBulkDelete($projectId: String!, $participantIds: [String!]!) {
    data: participantsBulkDelete(projectId: $projectId, participantIds: $participantIds) {
      message
      data
    }
  }
`)

import { gql } from 'gql/__generated__'

export const companyCreate = gql(`
  mutation CompanyCreate($payload: CompanyInput!) {
    data: companiesCreate(data: $payload) {
      data {
        ...CompanyListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const companyUpdate = gql(`
  mutation CompanyUpdateById($companyId: ID!, $data: CompanyUpdateInput!) {
    data: companiesUpdateById(companyId: $companyId, data: $data) {
      data {
        ...CompanyListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const companyForceDelete = gql(`
  mutation CompanyForceDeleteById($companyId: ID!, $data: CompanyDeleteInput!) {
    data: companiesForceDeleteById(companyId: $companyId, data: $data)
  }
`)

export const companiesDelete = gql(`
  mutation CompaniesDelete($companyIds: [ID!]!) {
    data: companiesDelete(companyIds: $companyIds)
  }
`)

export const createSsoConfig = gql(`
  mutation CreateSsoConfig($companyId: ID!, $data: CreteSsoConfigInput!) {
    data: createSsoConfig(companyId: $companyId, data: $data) {
      id
      ssoConfig {
        ...SsoConfigSchema
      }
    }
  }
`)

export const deleteSsoConfig = gql(`
  mutation DeleteSsoConfig($companyId: ID!) {
    data: deleteSsoConfig(companyId: $companyId) {
      id
      ssoConfig {
        ...SsoConfigSchema
      }
    }
  }
`)

export const updateSsoConfig = gql(`
  mutation UpdateSsoConfig($companyId: ID!, $data: UpdateSsoConfigInput!) {
    data: updateSsoConfig(companyId: $companyId, data: $data) {
      id
      ssoConfig {
        ...SsoConfigSchema
      }
    }
  }
`)

export const updateCompanyOwner = gql(`
  mutation UpdateCompanyOwner($companyId: ID!, $email: String!) {
    data: updateCompanyOwner(companyId: $companyId, email: $email)
  }
`)

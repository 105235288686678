"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/TestHotspot/constants");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    image: {
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
        borderStyle: (0, genStyle_1.genStyle)({ value: 'none' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    area: {
        backgroundColor: (0, genStyle_1.genStyle)({ value: '#E6E6E633' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    spot: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        height: (0, genStyle_1.genStyle)({ value: 32 }),
        width: (0, genStyle_1.genStyle)({ value: 32 }),
    },
    correct: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.successColor }),
    },
    incorrect: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.errorColor }),
    },
    effects: {
        boxShadow: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.boxShadow }),
    },
    animation: {
        pulse: (0, genStyle_1.genStyle)({ value: true }),
    },
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: {} } };

import { useMemo } from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import { Typography } from 'components/uiKit/Typography'
import { FIELD_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { SplitterValueFormType } from './SplitterElement.types'

const genField = genDataField<Block>()

const position = (name: string) =>
  genField({
    name: `${name}.position`,
    type: 'number',
    label: t('elements.splitter.form.splitter'),
    defaultValue: 50,
    layout: 'horizontal',
    rules: [{ max: 100 }],
    params: { min: 0, max: 100, width: 64 },
  })

const leftBlockImage = genField({
  name: 'leftBlock.image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
    showSource: true,
  },
})

export const leftBlockOverlay = genField({
  name: 'leftBlock.overlay',
  type: 'color',
  label: t('input.label.overlay'),
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

// --------------------------------

const rightBlockImage = genField({
  name: 'rightBlock.image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
    showSource: true,
  },
})

export const rightBlockOverlay = genField({
  name: 'rightBlock.overlay',
  type: 'color',
  label: t('input.label.overlay'),
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

const SplitterForm: SplitterValueFormType = ({ name }) => (
  <Padding sides>
    <Typography indents={FIELD_BOTTOM_INDENT} styleType='hint'>
      {t('elements.splitter.form.info')}
    </Typography>
    <RealtimeField config={position(name)} />
    <RealtimeField config={chainName(name, leftBlockImage)} />
    <RealtimeField config={chainName(name, leftBlockOverlay)} />
    <Divider />
    <RealtimeField config={chainName(name, rightBlockImage)} />
    <RealtimeField config={chainName(name, rightBlockOverlay)} />
  </Padding>
)

export default SplitterForm

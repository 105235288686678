import { TabsFontSchemaType, TabsSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, TabsElementValue } from '@leenda/editor/lib/elements'
import { useState } from 'react'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TabsElement'

const DEMO_VALUE: TabsElementValue = {
  items: [
    { label: t('elements.table.value.header', { number: 1 }), value: 'id1' },
    { label: t('elements.table.value.header', { number: 2 }), value: 'id2' },
    { label: t('elements.table.value.header', { number: 3 }), value: 'id3' },
  ],
}

const TabsDemo: React.FC<IElementDemoProps<TabsSchemaType, TabsFontSchemaType>> = ({
  styles,
  fonts,
}) => {
  const [state, setState] = useState<{ active: string | null; pauseAnimation: boolean }>({
    active: 'id1',
    pauseAnimation: true,
  })
  return (
    <DemoWrapper>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.tabs.type}-demo`,
          type: CONFIG_ELEMENTS.tabs.type,
          value: DEMO_VALUE,
          font: {},
          style: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        setState={setState}
        state={state}
        styles={styles}
      />
    </DemoWrapper>
  )
}

export default TabsDemo

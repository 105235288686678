import { LabeledGraphicPoint, LabeledGraphicElementValue } from '@leenda/editor/lib/elements'
import { createItem } from '@leenda/editor/lib/elements/elements/LabeledGraphic/constants'
import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import { generateId } from '@leenda/editor/lib/utils/id'
import * as R from 'ramda'
import { useMemo } from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Collapse from 'components/uiKit/Collapse'
import { Typography } from 'components/uiKit/Typography'
import { DEFAULT_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { FULL_COLLAPSE_PADDING, TITLE_COLLAPSE_PADDING } from 'constants/styles'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import { LabeledGraphicValueFormType } from './LabeledGraphic.types'

const genField = genDataField<Block>()

export const type = genField({
  name: 'type',
  type: 'select',
  layout: 'horizontal',
  label: t('input.label.type'),
  defaultValue: 'image',
  params: {
    options: [
      { label: t('input.option.number'), value: 'number' },
      { label: t('input.option.icons'), value: 'image' },
      { label: t('input.option.withoutIcon'), value: 'withoutIcon' },
    ],
    fluid: true,
  },
})
const id = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: { fileType: 'image', nullable: true, preview: true, showSource: true },
})

export const overlay = genField({
  name: 'overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

const pointImg = (defaultValue?: string | null) =>
  genField({
    name: 'pointIcon',
    type: 'file',
    label: t('elements.labeledGraphic.form.pointIcon'),
    defaultValue: defaultValue ? { type: FileUsageTypeEnum.image, id: defaultValue } : undefined,
    params: (block, { name }) => {
      const { parent } = getParent<LabeledGraphicElementValue>(name, block, 2)
      return {
        hidden: parent?.type !== 'image',
        fileType: 'image',
        nullable: true,
        showSource: true,
      }
    },
  })

const image = genField({
  name: 'image',
  type: 'file',
  label: t('elements.labeledGraphic.form.icon'),
  params: { fileType: 'image', nullable: true, preview: true, showSource: true },
})

export const itemOverlay = genField({
  name: 'overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

const items = (
  onFocusItem: (index: string | null) => void,
  onActive: (id: string) => void,
  active?: string | null,
  defaultIcon?: null | string,
) =>
  genField({
    name: 'items',
    type: 'array',
    rules: [{ max: 200 }],
    params: {
      itemLabel: t('input.label.point'),
      ordering: true,
      onFocusItem,
      onActive,
      active,
      styleType: 'padding',
      min: 1,
      onAdd: (changeData) => {
        const value = changeData.value as LabeledGraphicPoint[]
        return [...value, createItem()]
      },
      onClone: (changeData) => {
        const value = changeData.value as LabeledGraphicPoint[]
        const source = changeData.meta?.item as LabeledGraphicPoint
        const index = changeData.meta?.index

        if (R.is(Number, index) && source) {
          const clone = { ...source, value: generateId(), x: `${parseInt(source.x) + 3}%` }
          // onFocus(clone.value) // broken
          return R.insert(index + 1, clone, value)
        }

        return value
      },
    },
    fields: [pointImg(defaultIcon), image, itemOverlay],
  })

const LabeledGraphicForm: LabeledGraphicValueFormType = ({ name, state, setState, styles }) => {
  const defaultIcon = styles.point.backgroundImage

  const onActive = (id: string) =>
    ScrollService.scroll({ container: ScrollContainerEnum.toolbar, id }, null)

  const onFocusItem = (i: string | null) => setState({ activePoint: i })

  return (
    <>
      <Collapse
        header={t('elements.labeledGraphic.form.image')}
        iconPosition='right'
        name='image'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, id)} />
        <RealtimeField config={chainName(name, overlay)} />
      </Collapse>
      <Collapse
        header={t('elements.chart.form.points')}
        iconPosition='right'
        name='label'
        paddings={TITLE_COLLAPSE_PADDING}
        initialOpen
      >
        <Padding sides>
          <RealtimeField config={chainName(name, type)} />
          <Typography indents={DEFAULT_BOTTOM_INDENT} styleType='hint'>
            {t('elements.labeledGraphic.form.pointDescription')}
          </Typography>
        </Padding>
        <RealtimeField
          config={chainName(name, items(onFocusItem, onActive, state?.activePoint, defaultIcon))}
        />
      </Collapse>
    </>
  )
}
export default LabeledGraphicForm

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const types_1 = require("../../../files/types");
const constants_1 = require("../../constants");
exports.TYPE = 'video';
exports.DEFAULT_VALUE = {
    playbackRate: 1,
    duration: null,
    coverImage: constants_1.imageValue,
    file: {
        source: types_1.FileUsageImageSource.file,
        id: '',
    },
    controls: true,
    autoPlay: false,
    repeat: false,
    volume: 50,
    percent: 0,
    captions: null,
    enableCCByDefault: false,
    overlay: '#FFFFFF00',
};

import { Crop as CropType } from 'react-image-crop/src/types'

import { IconButton } from 'components/uiKit/Button'
import Divider from 'components/uiKit/Divider'
import Tooltip from 'components/uiKit/Dropdown/Tooltip'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Radio from 'components/uiKit/Radio'
import Select from 'components/uiKit/Select'
import { t } from 'services/Translation'

import { IImageEditValue } from '../ImageEditModal'
import { makeCrop } from '../cropUtils'
import s from './CropActions.module.scss'

const ROUND_OPTIONS = [
  { value: true, label: <Icon name='otherCircleCrop' size={KitSize.S} /> },
  { value: false, label: <Icon name='otherSquare' size={KitSize.S} /> },
]

const ASPECT_OPTIONS = [
  { value: null, label: t('input.option.original') },
  { value: 1, label: '1:1' },
  { value: 4 / 3, label: '4:3' },
  { value: 16 / 9, label: '16:9' },
]

interface ICropActionsProps {
  localValue: IImageEditValue
  setLocalValue: (value: React.SetStateAction<IImageEditValue>) => void
  imgElement: HTMLImageElement | null
  normalizedCrop: (crop: CropType | undefined) => CropType | undefined
  id?: string
}

const CropActions = ({
  localValue,
  setLocalValue,
  imgElement,
  normalizedCrop,
  id,
}: ICropActionsProps) => {
  const handleChangeAspect = (aspect: number) => {
    setLocalValue(({ cropParams, accessibility }) =>
      imgElement
        ? {
            accessibility,
            cropParams: {
              aspect,
              round: cropParams?.round,
              crop: normalizedCrop(makeCrop(imgElement, cropParams?.crop, aspect)),
            },
          }
        : { accessibility, cropParams: { aspect, round: cropParams?.round } },
    )
  }

  const handleReset = () => {
    setLocalValue({ accessibility: '', cropParams: { crop: undefined, round: false, aspect: 0 } })
  }

  const handleChangeRound = (round: boolean) =>
    setLocalValue(({ cropParams, accessibility }) =>
      imgElement
        ? {
            accessibility,
            cropParams: {
              aspect: cropParams?.aspect,
              round,
              crop: normalizedCrop(makeCrop(imgElement, cropParams?.crop, cropParams?.aspect)),
            },
          }
        : { accessibility, cropParams: { aspect: cropParams?.aspect, round } },
    )

  return (
    <div className={s.root}>
      <Select
        //@ts-ignore
        defaultValue={null}
        name='croppingAspect'
        onChange={handleChangeAspect}
        //@ts-ignore
        options={ASPECT_OPTIONS}
        size={KitSize.S}
        //@ts-ignore
        value={localValue.cropParams?.aspect || null}
      />
      <Divider size={KitSize.S} styleType='vertical' />
      <Radio
        direction='horizontal'
        fluid={false}
        name='croppingRound'
        onChange={handleChangeRound}
        options={ROUND_OPTIONS}
        value={localValue.cropParams?.round ?? false}
        hideRadio
      />
      <Divider size={KitSize.S} styleType='vertical' />
      <Tooltip overlay={t('uiKit.tooltip.reset')}>
        <IconButton
          disabled={!(localValue.cropParams?.crop || localValue.cropParams?.round)}
          icon='arrowRight'
          name={`undo-${id}`}
          onClick={handleReset}
          size={KitSize.S}
          styleType='free'
          type='button'
        />
      </Tooltip>
    </div>
  )
}

export default CropActions

import { ButtonElementValue } from '@leenda/editor/lib/elements'
import { useCallback } from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { testPropsEl } from 'utils/test/qaData'

import { Properties } from '../../elements.types'
import { ButtonElementType } from './ButtonElement.types'
import BaseButtonElementStyled from './styled/BaseButtonElementStyled'

interface IBaseButtonElementProps
  extends Pick<Properties<ButtonElementType>, 'font' | 'styles' | 'mode'> {
  label?: string
  disabled?: boolean
  onClick?: () => void
  icon?: React.ReactElement | null
  iconPosition?: 'left' | 'right'
  action?: ButtonElementValue['action']
  tooltip?: string
}

const BaseButtonElement: React.FC<IBaseButtonElementProps> = ({
  label,
  disabled: disabledProp,
  styles,
  onClick,
  icon,
  iconPosition,
  action,
  tooltip,
  font,
  mode,
}) => {
  const isInteractive = useProjectContext(getIsInteractiveMode)
  const disabled = !isInteractive ? false : disabledProp

  const onClickHandler = useCallback(() => {
    if (disabled || !isInteractive || mode.editorMode === AppMode.comment) {
      return
    }
    onClick && onClick()
  }, [disabled, isInteractive, mode.editorMode, onClick])

  return (
    //TODO: think about using tooltip
    <Tooltip overlay={tooltip} inlineBlock>
      <BaseButtonElementStyled
        {...testPropsEl('courseButton', { value: action }, { label, disabled })}
        $disabled={disabled}
        $fonts={font}
        $iconPosition={iconPosition}
        $styles={styles}
        disabled={disabled}
        onClick={onClickHandler}
      >
        {label}
        {icon && iconPosition && icon}
      </BaseButtonElementStyled>
    </Tooltip>
  )
}

export default BaseButtonElement

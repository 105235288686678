"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const constants_1 = require("../../constants");
const rich_text_1 = require("@leenda/rich-text");
exports.TYPE = 'splitter';
exports.DEFAULT_VALUE = {
    leftBlock: {
        image: constants_1.imageValue,
        showImage: false,
        text: (0, rich_text_1.textToRtValue)('', rich_text_1.SlateElementType.heading3),
        overlay: '#FFFFFF00',
    },
    rightBlock: {
        image: constants_1.imageValue,
        showImage: false,
        text: (0, rich_text_1.textToRtValue)('', rich_text_1.SlateElementType.heading3),
        overlay: '#FFFFFF00',
    },
    position: 50,
};

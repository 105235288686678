import { gql } from 'gql/__generated__'

export const templatesGetAllIdsQuery = gql(`
  query TemplatesAllIds($companyId: String!, $data: EditorTemplateAllInput!) {
    data: editorTemplatesAllIds(companyId: $companyId, data: $data) 
  }
`)

export const templatesCreateFromBlock = gql(`
  mutation TemplatesCreateFromBlock($data: EditorTemplateCreateInput!) {
    data: editorTemplateCreateFromBlock(data: $data) {
      data {
        ...TemplatesListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templateCopy = gql(`
  mutation TemplateCopy($payload: EditorTemplateMultiCopyInput!) {
    data: editorTemplatesMultiCopy(payload: $payload) {
      ...TemplatesListSchema
    }
  }
`)

export const templatesSave = gql(`
  mutation TemplatesSave($companyId: String!, $data: EditorTemplateInput!) {
    data: editorTemplatesUpdateById(companyId: $companyId, data: $data) {
      data {
        ...TemplatesListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templatesDeleteByIds = gql(`
  mutation TemplatesDeleteByIds($companyId: String!, $uuid: [String!]!) {
    data: editorTemplatesDeleteByIds(companyId: $companyId, uuid: $uuid)
  }
`)

export const templatesAddToFavs = gql(`
  mutation TemplatesAddToFavs($companyId: String!, $projectId: String, $uuids: [String!]!) {
    data: editorTemplatesFavoritesAdd(companyId: $companyId, uuids: $uuids, projectId: $projectId) {
      uuid
      isFavorite
    }
  }
`)

export const templatesRemoveFromFavs = gql(`
  mutation templatesRemoveFromFavs($companyId: String!, $uuids: [String!]!) {
    data: editorTemplatesFavoritesRemove(companyId: $companyId, uuids: $uuids) {
      uuid
      isFavorite
    }
  }
`)

export const templateGroupCreate = gql(`
  mutation EditorTemplateCollectionCreate(
    $companyId: ID!
    $payload: TemplateCollectionCreateInput!
  ) {
    data: templateCollectionCreate(companyId: $companyId, payload: $payload) {
      data {
        ...TemplateCollectionsSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templateGroupDuplicate = gql(`
  mutation EditorTemplateCollectionDuplicate($companyId: ID!, $id: ID!) {
    data: templateCollectionDuplicate(companyId: $companyId, id: $id) {
      id
      name
    }
  }
`)

export const templateGroupUpdate = gql(`
  mutation EditorTemplateCollectionUpdate($id: ID!, $payload: TemplateCollectionUpdateInput!) {
    data: templateCollectionUpdate(id: $id, payload: $payload) {
      data {
        ...TemplateCollectionsSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templateGroupDelete = gql(`
  mutation EditorTemplateCollectionArchive($id: ID!) {
    data: templateCollectionArchive(id: $id)
  }
`)

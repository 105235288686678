import { useCallback } from 'react'

import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Button from 'components/uiKit/Button'
import { Typography } from 'components/uiKit/Typography'
import { DEFAULT_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { updateBlock } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockId } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { LinkValueFormType } from './LinkElement.types'
import s from './LinkElementForm.module.scss'
import { getFileInfo } from './helper'
import {
  description,
  showCustomImage,
  showPreview,
  linkLabel,
  linkUrl,
  title,
  image,
  overlay,
} from './linkFormConfig'

const LinkElementForm: LinkValueFormType = ({ name, element }) => {
  const dispatch = useProjectDispatch()
  const blockId = useProjectContext(getBlockId)

  const onChange = useCallback(
    async (value: unknown) => blockId && dispatch(updateBlock({ id: blockId, name, value })),
    [blockId, dispatch, name],
  )

  const onClick = useCallback(async () => {
    const data = await getFileInfo(element.value, name)
    onChange(Object.assign({}, element.value, data.value))
  }, [element.value, name, onChange])

  const values = element.value

  return (
    <Padding sides>
      <RealtimeField config={chainName(name, linkUrl)} />
      <RealtimeField config={chainName(name, linkLabel)} />
      <RealtimeField config={chainName(name, showPreview)} />
      <RealtimeField config={chainName(name, title)} />
      <RealtimeField config={chainName(name, description)} />
      {values.showPreview && (
        <Typography indents={DEFAULT_BOTTOM_INDENT} styleType='hint'>
          {t('elements.link.form.uploadImageHint')}
        </Typography>
      )}
      <RealtimeField config={chainName(name, showCustomImage)} />
      <RealtimeField config={chainName(name, image)} />
      <RealtimeField config={chainName(name, overlay)} />
      {values.showPreview && (
        <div className={s.fillButton}>
          <Button
            disabled={!values.linkURL && !values.title && !values.description}
            name='request'
            onClick={onClick}
            styleType='secondary'
            fluid
          >
            {t('elements.link.form.fillAuto')}
          </Button>
        </div>
      )}
    </Padding>
  )
}

export default LinkElementForm

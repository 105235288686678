"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionDisplayDivider = exports.AccordionType = void 0;
var AccordionType;
(function (AccordionType) {
    AccordionType["SOLID"] = "solid";
    AccordionType["SPLITTED"] = "splitted";
})(AccordionType || (exports.AccordionType = AccordionType = {}));
var AccordionDisplayDivider;
(function (AccordionDisplayDivider) {
    AccordionDisplayDivider["ALL"] = "all";
    AccordionDisplayDivider["INNER"] = "inner";
    AccordionDisplayDivider["FIRST"] = "first";
    AccordionDisplayDivider["HIDE"] = "hide";
})(AccordionDisplayDivider || (exports.AccordionDisplayDivider = AccordionDisplayDivider = {}));

import { useCallback, useMemo, useState } from 'react'

import Form from 'components/controls/Form'
import SubmitButton from 'components/controls/SubmitButton/SubmitButton'
import Breadcrumbs from 'components/uiKit/Breadcrumbs'
import { BreadcrumbItem } from 'components/uiKit/Breadcrumbs/Breadcrumbs'
import Button from 'components/uiKit/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/uiKit/Modal'
import { MCWithParams } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { useTableState } from 'components/uiKit/Table'
import { getDataGrouping } from 'components/uiKit/Table/utils'
import { FileMetaFor } from 'gql/__generated__/graphql'
import { useFileMetaAndGroupMove } from 'gql/files/apollo'
import { t } from 'services/Translation'
import { FinderItem } from 'store/models/entry.model'
import { getFilterByCreatedFor, isFinderFolder, useFinder } from 'utils/useFinder'

import s from './FileMove.module.scss'
import FileTable from './FolderTable'
import { MODAL_MOVE_FINDER_TABLE_NAME } from './FolderTable/FolderTable'

type Params = {
  companyId: string
  projectId: string
  fileMetaGroupId: string
  createdFor?: FileMetaFor
  movingItems: FinderItem[]
}

const excludedIdsOptions = (excludedIds: FinderItem[]) =>
  excludedIds?.filter(isFinderFolder).map(({ id }) => id)

const FileMove: MCWithParams<Params> = ({ onClose, params }) => {
  const { companyId, projectId, fileMetaGroupId, createdFor, movingItems } = params
  const [fileMetaAndGroupMove] = useFileMetaAndGroupMove()

  const { paginationConfig, pagination, sortConfig, order } = useTableState(
    MODAL_MOVE_FINDER_TABLE_NAME,
    { defaultSort: 'name' },
  )

  const [targetGroupId, setTargetGroupId] = useState<string>(fileMetaGroupId)

  const { data, breadcrumbs, total, loading } = useFinder(
    { companyId, projectId, fileMetaGroupId: targetGroupId || 'root' },
    {
      fileMetaType: getFilterByCreatedFor(createdFor),
      excludedIds: excludedIdsOptions(movingItems),
      pagination,
      order,
    },
    'modalMove',
  )

  const folders = useMemo(() => data.filter(isFinderFolder), [data])

  const isDisabledMove = useMemo(
    () => fileMetaGroupId === targetGroupId || (!fileMetaGroupId && !targetGroupId),
    [targetGroupId, fileMetaGroupId],
  )

  const moveFiles = useCallback(async () => {
    const dataGrouping = getDataGrouping(movingItems)

    const dataMove = {
      ...dataGrouping,
      targetGroupId,
      currentGroupId: fileMetaGroupId,
    }

    try {
      const res = await fileMetaAndGroupMove({
        variables: { data: dataMove, companyId, projectId },
      })
      if (res.data?.data) {
        onClose()
        notify({
          type: NotificationType.success,
          message: t('notify.move', { count: movingItems?.length }),
        })
      }
    } catch (err) {
      onClose()
      notify({
        type: NotificationType.warning,
        message: t('notify.objectsMovingError'),
      })
      console.error(err)
    }
  }, [
    companyId,
    fileMetaAndGroupMove,
    movingItems,
    targetGroupId,
    fileMetaGroupId,
    projectId,
    onClose,
  ])

  const handleClickBreadcrumbs = useCallback(
    (item: BreadcrumbItem) => setTargetGroupId(item.id || 'root'),
    [setTargetGroupId],
  )

  const breadcrumbsItems: BreadcrumbItem[] = useMemo(
    () => [
      { name: t('modal.move.resources'), id: 'root' },
      ...breadcrumbs.map(({ id, name }) => ({ name, id })),
    ],
    [breadcrumbs],
  )

  return (
    <Form layout='vertical' name='fileMove' onSubmit={moveFiles}>
      <Modal maxWidth={560} name='fileMove'>
        <ModalHeader description={t('modal.move.destination')} title={t('modal.move.title')} />
        <ModalBody sizeAutoCapable>
          <div className={s.modalBody}>
            <Breadcrumbs items={breadcrumbsItems} onClickBreadcrumbs={handleClickBreadcrumbs} />
            <div className={s.projectTable}>
              <FileTable
                folders={folders}
                isDisabledMove={isDisabledMove}
                isLoading={loading}
                paginationConfig={paginationConfig}
                setTargetGroupId={setTargetGroupId}
                sortConfig={sortConfig}
                totalFolders={total}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name='cancel' onClick={() => onClose()} styleType='ghost'>
            {t('uiKit.button.cancel')}
          </Button>
          <SubmitButton disabled={isDisabledMove} label={t('uiKit.button.move')} name='move' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default FileMove

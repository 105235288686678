import { useMemo } from 'react'

import { useOpenModal } from 'components/uiKit/Modal'
import { EDITOR_PATHS } from 'constants/paths'
import { EmployeePreferencesTab } from 'gql/__generated__/graphql'
import { useBlocksMultiCreate, useBlocksRevert } from 'gql/blocks/apollo'
import { usePathParams } from 'routes/hooks'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setProjectNavigation } from 'services/Store/Project/actions'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockId, getBlocks, getSection, getProject } from 'services/Store/Project/selectors'

import TemplatePickerModal from './TemplatePickerModal'
import { TemplatePickerModalType, UiTemplateTags } from './TemplatePickerModal/types'

export const useAddBlockTemplate = (defaultTag?: UiTemplateTags) => {
  const blockId = useProjectContext(getBlockId)
  const { type } = useProjectContext(getSection) || { type: SectionTypeEnum.landing }
  const { companyId, projectId } = usePathParams(EDITOR_PATHS.main)
  const section = useProjectContext(getSection)
  const blocks = useProjectContext(getBlocks)
  const project = useProjectContext(getProject)

  const blocksByMode = useMemo(() => {
    return blocks.reduce(
      (acc, block) => {
        acc[block.mode] = true
        return acc
      },
      {} as Record<string, boolean>,
    )
  }, [blocks])

  let tag

  switch (type) {
    case SectionTypeEnum.test:
      tag = blocksByMode[BlockMode.start] ? UiTemplateTags.questions : UiTemplateTags.start
      break
    case SectionTypeEnum.landing:
      tag = UiTemplateTags.all
      break
    case SectionTypeEnum.cover:
      tag = UiTemplateTags.cover
      break
    default:
      tag = UiTemplateTags.all
  }

  const finalTag = defaultTag || tag

  const pickTemplateModal = useOpenModal(TemplatePickerModal, {
    tag: finalTag,
    type,
    companyId,
    projectId,
    sectionId: section?.id,
    blocksByMode,
    modalType: TemplatePickerModalType.add,
    employeePreferences: project?.employeePreferences,
  })
  const dispatch = useProjectDispatch()
  const [createBlocks] = useBlocksMultiCreate(section?.id)
  const [restoreBlock] = useBlocksRevert(section?.id)

  const onBlocksAdd = async (insertAfter = true) => {
    const val = await pickTemplateModal.open({})
    if (!val) {
      return
    }

    if (val.tab === EmployeePreferencesTab.archived) {
      const uuids = val.selected
      const res = await restoreBlock({
        variables: {
          companyId,
          payload: { uuids, sectionId: section?.id || '', insertAfter, activeBlockUUID: blockId },
        },
      })
      if (res?.data?.data.blocks) {
        const data = res.data?.data
        const block = data.blocks[data.blocks.length - 1]
        dispatch(
          setProjectNavigation({
            sectionId: block.sectionId,
            blockId: block.uuid,
            nodeId: null,
            scroll: [
              {
                container: ScrollContainerEnum.canvas,
                id: block.uuid,
                block: 'begin',
                scroll: ['never', 'always'],
              },
              {
                container: ScrollContainerEnum.blocksNav,
                id: block.uuid,
                block: 'begin',
                scroll: 'always',
              },
            ],
          }),
        )
      }
    } else {
      const result = await createBlocks({
        variables: {
          payload: {
            insertAfter,
            uuids: val.selected,
            activeBlockUUID: blockId,
            sectionId: section?.id || '',
          },
        },
      })
      if (result?.data?.data.blocks) {
        const data = result.data?.data
        const blockIds = data.blocksOrder?.filter((id) => data.blocks.some((b) => b.uuid === id))
        const block = data.blocks.find((b) => b.uuid === blockIds?.[blockIds.length - 1])
        if (!block) {
          return
        }
        dispatch(
          setProjectNavigation({
            sectionId: block.sectionId,
            blockId: block.uuid,
            nodeId: null,
            scroll: [
              {
                container: ScrollContainerEnum.canvas,
                id: block.uuid,
                block: 'begin',
                scroll: ['never', 'always'],
              },
              {
                container: ScrollContainerEnum.blocksNav,
                id: block.uuid,
                block: 'begin',
                scroll: 'always',
              },
            ],
          }),
        )
      }
    }
  }

  return onBlocksAdd
}

import { ApolloError } from '@apollo/client'
import { debounce } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { TargetUploadFileEnum } from 'utils'

import { FINDER_PAGE_NAME } from 'components/pages/FinderPage/FinderTable'
import { UpsertType, FileMetaFor } from 'gql/__generated__/graphql'
import { useMyInfo } from 'gql/__utils__/useMyInfo'
import { useCompanyMe } from 'gql/companies/apollo'
import { DEPRECATED_EditorParams, UppyType } from 'store/models'
import { storageLimitReached } from 'utils/errors'
import { getIdFileMeta } from 'utils/useFinder'

import { useFilesGroupsParams, useFilesGroupsSearchParams } from './FilesHelpers'

interface IUseFileMeta {
  uppy: UppyType
  createdFor?: FileMetaFor | UpsertType
  createdById?: string
  type?: string
  targetUploadFile?: TargetUploadFileEnum
  fieldName?: string
  companyId: string
  projectId?: string
  fileMetaGroupId?: string
  taskId?: string
}

const debouncedErrorNotification = debounce(storageLimitReached, 500)

export const useFileMeta = (params: IUseFileMeta) => {
  const { uppy, createdFor, createdById, type, targetUploadFile, fieldName } = params
  const routerParams = useParams<DEPRECATED_EditorParams>()
  const { companyId, projectId, fileMetaGroupId, taskId } = { ...routerParams, ...params }
  const { user } = useMyInfo(companyId)

  const idFileMeta = getIdFileMeta({ projectId, companyId, taskId }, createdFor)
  const queryParams = useFilesGroupsParams(FINDER_PAGE_NAME, 'updateAt')

  const {
    location: { pathname },
  } = useHistory()
  const isSearch = pathname.includes('/search')
  const queryDefaultParams = useFilesGroupsParams(FINDER_PAGE_NAME, 'updateAt')
  const searchParams = useFilesGroupsSearchParams(isSearch ? queryParams : queryDefaultParams)
  const { data: companyData } = useCompanyMe(companyId)
  const employeeId = companyData?.data?.employee?.id
  const userId = user?.id

  const upsertTypes = useMemo(() => {
    return createdFor
      ? [
          {
            id:
              getIdFileMeta(
                { projectId, companyId, ownerId: createdById || userId, taskId },
                createdFor,
              ) || '',
            type: createdFor,
          },
          { id: createdById || userId || 'root', type: 'owner' },
        ]
      : [{ id: companyId, type: 'companies' }]
  }, [createdFor, companyId, createdById, projectId, userId, taskId])

  // Parameter for the backend. Backend doesn't know where we are uploading files (ProfileLogo or EmployeeLogo  or ProfileCompanyLogo or Resource)
  const upsertTargetUploadFile = useMemo(() => {
    switch (targetUploadFile) {
      case TargetUploadFileEnum.FONT:
        return { category: TargetUploadFileEnum.FONT, companyId: companyId }
      case TargetUploadFileEnum.COMPANY:
        return { category: 'logo', companyId: companyId }
      case TargetUploadFileEnum.EMPLOYEE:
        return { category: 'avatar', employeeId: employeeId }
      default:
        return { category: 'image', companyId: companyId }
    }
  }, [targetUploadFile, companyId, employeeId])

  const addFiles = useCallback(
    (files: any) => {
      files.forEach(async (file: any) => {
        const meta = {
          type,
          fieldName,
          contentType: type,
          // optional, store the directory path of a file so Uppy can tell identical files in different directories apart
          name: file.name,
          relativePath: file.relativePath || null,
        } as { [key: string]: string }

        if (createdFor === FileMetaFor.projects) {
          meta.projectId = projectId
        }

        if (createdFor === FileMetaFor.companies) {
          meta.companyId = companyId
        }

        meta.createdById = createdById || userId || ''

        const dataVariables = {
          data: {
            createdById: userId || '',
            upsertTypes: upsertTypes,
            type: file.type || 'other',
            name: file.name,
            size: file.size,
            fileMetaGroupId,
          },
          companyId,
        }

        const paramsUpdateCacheFiles = {
          companyId,
          search: {
            ...searchParams,
            parentId: !isSearch ? fileMetaGroupId || 'root' : null,
            id: idFileMeta,
            contentType: searchParams.contentType,
          },
          parentId: fileMetaGroupId || null,
        }

        uppy.on('file-added', async (fileAdded) => {
          uppy.setFileMeta(fileAdded.id, {
            ...fileAdded.meta,
            dataVariables,
            paramsUpdateCacheFiles,
            ...upsertTargetUploadFile,
            contentType: file.type,
            taskId,
          })
        })

        uppy.addFile({
          name: file.name,
          type: file.type,
          data: file,
          meta: meta,
        })

        const handleError = (err: unknown) => {
          if (err instanceof ApolloError && companyData?.data) {
            debouncedErrorNotification(err, companyData.data.company)
          }
          uppy.off('error', handleError)
        }

        uppy.on('error', handleError)

        return {
          source: 'DragDrop',
          name: file.name,
          type: file.type,
          data: file,
          meta: meta,
        }
      })
    },
    [
      createdFor,
      companyId,
      createdById,
      employeeId,
      fileMetaGroupId,
      idFileMeta,
      isSearch,
      projectId,
      searchParams,
      type,
      uppy,
      userId,
      upsertTargetUploadFile,
      upsertTypes,
      taskId,
    ],
  )
  return addFiles
}

import { gql } from 'gql/__generated__'

export const fileMetaGroupSubscription = gql(`
  subscription FilesGroupsSubscription(
    $companyId: String!
    $projectId: String
    $parentId: String!
  ) {
    data: fileMetaGroup(companyId: $companyId, projectId: $projectId, parentId: $parentId) {
      groups {
        ...FilesGroups
      }
      fileMetas {
        ...FileMetaSchema
      }
      type
    }
  }
`)

export const filesSubscription = gql(`
  # same like in schema
  subscription SubcribeOnCompanyFilesUpdate(
    $companyId: String!
    $projectId: String
    $parentId: String
  ) {
    data: SubcribeOnCompanyFilesUpdate(
      companyId: $companyId
      projectId: $projectId
      parentId: $parentId
    ) {
      type
      counts {
        type
        count
      }
      companyId
      projectId
      files {
        ...FileMetaSchema
      }
    }
  }
`)

export const projectFilesSubscription = gql(`
  subscription SubscribeOnProjectFilesUpdate($projectId: ID!) {
    data: fileMetaUsageSubscription(projectId: $projectId) {
      file {
        ...FileMetaSchema
      }
      projectId
    }
  }
`)

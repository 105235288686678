import { gql } from 'gql/__generated__'

export const upsertFileMeta = gql(`
  mutation UpsertFileMeta($companyId: String, $data: FileMetaUpsert!) {
    data: upsertFileMeta(companyId: $companyId, data: $data) {
      ...FileMetaSchema
    }
  }
`)

export const fileMetaGroupCreateMutation = gql(`
  mutation FileMetaGroupCreate($data: FileMetaGroupInput!, $companyId: String!) {
    data: fileMetaGroupCreate(data: $data, companyId: $companyId) {
      data {
        ...FilesGroups
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const fileMetasUpdateById = gql(`
  mutation FileMetasUpdateById($id: String!, $data: FileMetaInput!) {
    data: fileMetasUpdateById(id: $id, data: $data) {
      ...FileMetaSchema
    }
  }
`)

export const fileMetaGroupUpdateByIdMutation = gql(`
  mutation FileMetaGroupUpdateById(
    $id: String!
    $companyId: String!
    $data: FileMetaGroupUpdateInput!
  ) {
    data: fileMetaGroupUpdateById(id: $id, companyId: $companyId, data: $data) {
      data {
        ...FilesGroups
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const fileMetaAndGroupMove = gql(`
  mutation FileMetaAndGroupMove(
    $data: FileMetaAndGroupMoveInput!
    $companyId: String!
    $projectId: String
  ) {
    data: fileMetaAndGroupMove(data: $data, companyId: $companyId, projectId: $projectId)
  }
`)

export const unsplashTrackDownloads = gql(`
  mutation UnsplashTrackDownloads($ids: [ID!]!) {
    data: unsplashTrackDownloads(ids: $ids)
  }
`)

export const finderForceDelete = gql(`
  mutation FinderForceDelete($data: MultiDeleteForceInput!, $companyId: String!) {
    data: fileMetaGroupMultiForceDeleteByIds(data: $data, companyId: $companyId) {
      groupIds
      elementIds
    }
  }
`)

export const unsplashCreateFileMeta = gql(`
  mutation UnsplashCreateFileMeta($payload: UnsplashCreateFileMetaPayload!) {
    data: unsplashCreateFileMeta(payload: $payload) {
      ...FileMetaSchema
    }
  }
`)

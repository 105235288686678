import { useMemo } from 'react'

import Form from 'components/controls/Form'
import FormControls from 'components/controls/FormControls'
import FormField from 'components/controls/FormField'
import Modal, { MCWithParams, ModalBody, ModalFooter } from 'components/uiKit/Modal'
import { FileMetasUpdateByIdMutation } from 'gql/__generated__/graphql'
import { useFileMetasUpdateById } from 'gql/files/apollo'
import { t } from 'services/Translation'

import { InputErrorValues, FILES_AND_GROUPS_FIELDS, IFileFormValues } from './config'

interface IFolderFinderEditProps {
  editableFile: string
  name: string
  type?: string
}

const FileFinderEdit: MCWithParams<IFolderFinderEditProps> = ({ onClose, params }) => {
  const { editableFile: id, name = '', type } = params
  const extendFile = '.' + name.split('.')[name.split('.').length - 1]
  const [updateFileMetasUpdateById] = useFileMetasUpdateById()

  const initialValues = useMemo(() => {
    return { name: name.split('.').slice(0, -1).join('.') }
  }, [name])

  const onFinish = async (values: IFileFormValues) => {
    if (!id && !type) {
      return
    }
    const result = await updateFileMetasUpdateById({
      variables: {
        data: {
          name: `${values.name}${extendFile}`,
          isProcessed: false,
          type: type || '',
        },
        id,
      },
    })
    onSuccessClose(result.data)
  }

  const onSuccessClose = (data?: FileMetasUpdateByIdMutation | null) => {
    if (data?.data) {
      onClose && onClose()
    }
    return data
  }

  return (
    <Form
      initialValues={initialValues}
      inputErrorValues={InputErrorValues}
      layout='vertical'
      name='fileGroup'
      onReset={onClose}
      onSubmit={onFinish}
    >
      <Modal maxWidth={560} name='edit' title={t('modal.editFile.title')}>
        <ModalBody sizeAutoCapable>
          <FormField config={FILES_AND_GROUPS_FIELDS.name} />
        </ModalBody>
        <ModalFooter>
          <FormControls justify='right' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default FileFinderEdit

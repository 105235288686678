import { gql } from 'gql/__generated__'

export const employeesGetAllQuery = gql(`
  query EmployeesAll($companyId: String!, $search: EmployeeSearch) {
    data: employeesAll(companyId: $companyId, search: $search) {
      employees {
        ...EmployeeListSchema
      }
      limit
      offset
      total
    }
  }
`)

export const employeesGetByIdQuery = gql(`
  query EmployeesGetById($id: String!) {
    data: employeesGetById(id: $id) {
      ...EmployeeListSchema
      ...EmployeeInfoSchema
    }
  }
`)

export const employeeNotificationSettings = gql(`
  query EmployeeNotificationSettings($id: String!) {
    data: employeesGetById(id: $id) {
      id
      notificationSettings {
        notificationGroup
        skipEmail
        skipSystem
      }
    }
  }
`)

export const employeeGetTaskInfo = gql(`
  query EmployeeGetTaskInfo($employeeIds: [String!]!) {
    data: employeeGetTaskInfo(employeeIds: $employeeIds) {
      ...EmployeeGetTaskInfo
    }
  }
`)

export const employeesGetKByKUserId = gql(`
  query EmployeesGetKByKUserId($id: String!, $companyId: String!) {
    data: employeesGetKByKUserId(id: $id, companyId: $companyId) {
      ...EmployeeListSchema
      ...EmployeeInfoSchema
    }
  }
`)

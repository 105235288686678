import React from 'react'
import { colorsTypes } from 'utils'

import { IFolderFormValues } from 'components/forms/Folder'
import Modal, { MCWithParams, ModalBody, ModalFooter } from 'components/uiKit/Modal'
import { FileMetaFor } from 'gql/__generated__/graphql'
import { useFileMetaGroupCreate } from 'gql/files/apollo'
import { t } from 'services/Translation'

import Form from '../../controls/Form'
import FormControls from '../../controls/FormControls'
import FormField from '../../controls/FormField'
import { FILES_AND_GROUPS_FIELDS, InputErrorValues } from '../../forms/Folder/config'

const INITIAL_VALUES = {
  name: '',
  color: colorsTypes.yellow,
}

interface IFolderAddModalParams {
  createdFor: FileMetaFor
  fileMetaGroupId?: string
  projectId?: string
  companyId: string
}

const FolderAddModal: MCWithParams<IFolderAddModalParams> = ({
  onClose,
  params: { createdFor, projectId, companyId, fileMetaGroupId },
}) => {
  const parentId = fileMetaGroupId === 'root' ? undefined : fileMetaGroupId

  const [createFileGroup] = useFileMetaGroupCreate()

  const onFinish = async (values: IFolderFormValues) => {
    const data = { isArchived: false, createdFor: createdFor, parentId, projectId, ...values }
    console.warn('FolderAddModal:onFinish', data, companyId)

    const result = await createFileGroup({ variables: { data, companyId } })
    if (!result?.data?.data.errors) {
      onClose?.()
    }
    return result?.data
  }

  return (
    <Form
      initialValues={INITIAL_VALUES}
      inputErrorValues={InputErrorValues}
      layout='vertical'
      name='fileGroup'
      onReset={onClose}
      onSubmit={onFinish}
    >
      <Modal maxWidth={560} name='addFolder' title={t('modal.createFolder.title')}>
        <ModalBody sizeAutoCapable>
          <FormField config={FILES_AND_GROUPS_FIELDS.name} />
          <FormField config={FILES_AND_GROUPS_FIELDS.color} />
        </ModalBody>
        <ModalFooter>
          <FormControls justify='right' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default FolderAddModal

import { createSelector } from '@reduxjs/toolkit'
import lodash from 'lodash'
import * as R from 'ramda'

import { EMPTY_OBJECT } from 'constants/commonConstans'
import { IProjectContext, Block, ElementState, MapType } from 'services/Store/Project/types'

import { getBlock, getBlocks } from '..'
import { elementLens, elementsLens } from '../../lenses'

const getElements = (state: IProjectContext) => R.view(elementsLens)(state)
const collectIds = R.compose(
  R.keys,
  R.reduce<Block, MapType<ElementState>>((acc, block) => ({ ...acc, ...block?.elements }), {}),
)

export const getActiveNodeId = createSelector(
  ({ urlParams }: IProjectContext) => urlParams.nodeId,
  ({ state }: IProjectContext) => state.comments.opened?.nodeId,
  (nodeId, openedNodeId) => nodeId || openedNodeId,
)

export const getSectionElementsState = (state: IProjectContext, sectionId: string) => {
  const elements = R.view(elementsLens)(state)
  const blocks = getBlocks(state, sectionId)
  return lodash.pick(elements, collectIds(blocks))
}

export const getBlockElementsState = createSelector(
  getElements,
  (state: IProjectContext, blockId?: string) => getBlock(state, { id: blockId }),
  (elements, block) =>
    lodash.pick(elements, R.keys(block?.elements || EMPTY_OBJECT)) as MapType<ElementState>,
)

export const getElementState = (state: IProjectContext, id: string) =>
  R.view(elementLens(id))(state)

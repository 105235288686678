import { useDebounce } from 'ahooks'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { FileTypeEnum } from 'utils'

import DataFallback from 'components/DataFallback'
import { DataFallbackConfigType, DataFallbackTypeEnum } from 'components/DataFallback/types'
import { DragFileContainer } from 'components/FilesUploaderDrawer/DragFileContainer'
import useFilesUpload from 'components/FilesUploaderDrawer/useFilesUpload'
import Header, { HeaderLeft } from 'components/Header'
import { LayoutContent, LayoutRow } from 'components/LayoutPage'
import { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import { FieldGroup } from 'components/controls/Field/Layout'
import FormField from 'components/controls/FormField'
import { useUppy } from 'components/finder-v2/useUppy'
import StorageLimitAlert from 'components/limits/StorageLimitAlert'
import Breadcrumbs from 'components/uiKit/Breadcrumbs'
import { BreadcrumbItem } from 'components/uiKit/Breadcrumbs/Breadcrumbs'
import { IconButton } from 'components/uiKit/Button'
import DropdownMenu from 'components/uiKit/DropdownMenu'
import FilterPopover from 'components/uiKit/FilterPopover'
import { KitSize } from 'components/uiKit/KitTypes'
import Search, { isEmptyKeys } from 'components/uiKit/Search'
import SortPopover from 'components/uiKit/SortPopover'
import { ENUM_VALUE_MAP, valueToEnum } from 'components/uiKit/SortPopover/constants'
import { SortUiEnum } from 'components/uiKit/SortPopover/types'
import {
  TableOptions,
  useQueryParams,
  useQuerySearchParam,
  useTableViewStorage,
} from 'components/uiKit/Table'
import { usePageSize, useSort } from 'components/uiKit/Table'
import { TableView } from 'components/uiKit/Table/TableView'
import { ViewResourceEnum } from 'components/uiKit/Table/TableView/types'
import { NOOP } from 'constants/commonConstans'
import { PROJECT_PATHS } from 'constants/paths'
import { FileMetaFor, FileMetaGroupsSearch } from 'gql/__generated__/graphql'
import { useCompanyGetById } from 'gql/companies/apollo'
import { selectSortedEmployeesAll } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { t } from 'services/Translation'
import { FinderItem } from 'store/models/entry.model'
import { getEmployeesSelectParams } from 'utils/employee/employee'
import { getEnumOption } from 'utils/enum'
import { FilterTemplate, PARSE_FILTER } from 'utils/filters'
import { getResourcesMeta } from 'utils/resources'
import { testProps } from 'utils/test/qaData'
import { getFilterByCreatedFor } from 'utils/useFinder'
import { useFinderInfinityLoad } from 'utils/useFinderInfinitLoad'

import FinderCard from './FinderCard'
import s from './FinderPage.module.scss'
import FinderTable, { FINDER_PAGE_NAME } from './FinderTable/FinderTable'
import { columnsConfig } from './FinderTable/config'
import {
  authorField,
  contentTypeField,
  CREATED_FROM_FIELD,
  CREATED_TO_FIELD,
  FilterValues,
} from './fieldsConfig'
import { Paths } from './types'
import { usePageMenu, useTableMenu } from './useHandlers'

const config = (path: string, backCb?: () => void): DataFallbackConfigType => ({
  backUrl: backCb ? null : { path, params: { fileMetaGroupId: 'root' } },
  backCb,
  textNotification: t('notify.fileFolder.noLongerExists'),
  type: DataFallbackTypeEnum.notification,
})

const DEFAULT_SORT = [ENUM_VALUE_MAP.CREATED_AT_DESC[0][0], ENUM_VALUE_MAP.CREATED_AT_DESC[0][1]]

const SORT_OPTIONS = [
  getEnumOption('SortUiEnum', SortUiEnum.CREATED_AT, {
    options: [
      getEnumOption('SortUiEnum', SortUiEnum.CREATED_AT_DESC),
      getEnumOption('SortUiEnum', SortUiEnum.CREATED_AT_ASC),
    ],
  }),
  getEnumOption('SortUiEnum', SortUiEnum.NAME, {
    options: [
      getEnumOption('SortUiEnum', SortUiEnum.NAME_ASC),
      getEnumOption('SortUiEnum', SortUiEnum.NAME_DESC),
    ],
  }),
]

const RESOURCE_COMPONENTS_MAP = {
  [ViewResourceEnum.card]: FinderCard,
  [ViewResourceEnum.list]: FinderTable,
}

export const FINDER_FILTER_TEMPLATE: FilterTemplate<FilterValues> = {
  createFrom: PARSE_FILTER.number,
  createTo: PARSE_FILTER.number,
  ownersIds: PARSE_FILTER.array,
  contentType: PARSE_FILTER.array,
}

interface IFinderPageProps {
  params: {
    fileMetaGroupId: string
    companyId: string
    projectId?: string
  }
  defaultContentType?: FileTypeEnum
  createdFor: FileMetaFor.companies | FileMetaFor.projects
  onClickBreadcrumbs: (breadcrumbItem: BreadcrumbItem) => void
  paths?: Paths
  onClickItem?: (item: FinderItem) => void
  hiddenAddFile?: boolean
  isModal?: boolean
  backCb?: () => void
}

const FinderPage: React.FC<IFinderPageProps> = ({
  params,
  createdFor,
  defaultContentType,
  onClickItem,
  onClickBreadcrumbs,
  paths = PROJECT_PATHS,
  hiddenAddFile,
  isModal,
  backCb,
}) => {
  const [contentType, setContentType] = useState(defaultContentType ? [defaultContentType] : [])
  const [selected, setSelected] = useState<FinderItem[]>([])
  const uppy = useUppy()
  const employees = useAppSelector(selectSortedEmployeesAll)
  const onUploadFile = useFilesUpload({ createdFor, uppy, params, contentType })
  const containerRef = useRef<ScrollRef>(null)
  const employeesParams = useMemo(() => getEmployeesSelectParams(employees), [employees])
  const { companyId, projectId, fileMetaGroupId } = params
  const { tableViewState: view, setTableViewState: setView } = useTableViewStorage(FINDER_PAGE_NAME)
  const [filter, setFilter] = useQueryParams(FINDER_FILTER_TEMPLATE)
  const [query, setQuery] = useQuerySearchParam()
  const { pagination, setPage } = usePageSize(FINDER_PAGE_NAME)
  const { onClickTableMenu, tableActions } = useTableMenu(selected, params, createdFor, setSelected)
  const showTableActions = selected.length > 0
  const isSearch = Boolean(query) || !isEmptyKeys(filter)
  const { order, sortOrder, setSort, sort } = useSort(FINDER_PAGE_NAME, DEFAULT_SORT[0])
  const { data: companyData } = useCompanyGetById(companyId)
  const { disableUpload, showResourcesLimitAlert } = getResourcesMeta(
    companyData?.data?.overallResources.total,
    companyData?.data?.availableStorage,
    companyData?.data?.throwLimit,
  )
  const hideExternalImages = !companyData?.data?.showExternalImages || isModal
  const { onClickPageMenu, pageActions } = usePageMenu(
    params,
    onUploadFile,
    disableUpload,
    createdFor,
    hideExternalImages,
  )
  const debounceQuery = useDebounce(query, { wait: 500 })

  const search: Partial<FileMetaGroupsSearch> = useMemo(
    () => ({
      fileMetaType: getFilterByCreatedFor(createdFor),
      contentType: contentType.length ? contentType : undefined,
      query: debounceQuery,
      order,
      ...(view === ViewResourceEnum.list && { pagination }),
      ...filter,
    }),
    [createdFor, contentType, debounceQuery, order, view, pagination, filter],
  )

  const onRefetch = useCallback(() => containerRef.current?.osInstance()?.scroll({ y: 0 }), [])

  const { breadcrumbs, data, loading, chunkLoading, total, fetchNextChunk, error } =
    useFinderInfinityLoad(
      {
        ...params,
        parentId: fileMetaGroupId,
        projectId: createdFor === FileMetaFor.projects ? projectId : undefined,
      },
      search,
      onRefetch,
      view === ViewResourceEnum.card,
    )

  const breadcrumbsItems: BreadcrumbItem[] = useMemo(
    () => [
      { name: t('page.finder.title'), id: 'root' },
      ...(breadcrumbs?.map(({ id, name }) => ({ id, name: name || '' })) || []),
    ],
    [breadcrumbs],
  )

  const handleSetFilter = useCallback(
    (value: FilterValues) => {
      setPage(1)
      setFilter(value)
      setContentType(value.contentType || [])
    },
    [setPage, setFilter],
  )

  const handleSetQuery = useCallback(
    (value?: string) => {
      setPage(1)
      setQuery(value)
    },
    [setPage, setQuery],
  )

  const handleSetSort = useCallback(
    (value: SortUiEnum | null) => {
      const [field, order] = value ? ENUM_VALUE_MAP[value][0] : [DEFAULT_SORT[0], DEFAULT_SORT[1]]
      setSort(field, order)
    },
    [setSort],
  )

  const clearQuery = useCallback(() => setQuery(), [setQuery])

  const FinderComponent = RESOURCE_COMPONENTS_MAP[view]

  return (
    <>
      <LayoutRow horizontalFree={isModal} justify='between'>
        <Header
          onClickTableMenu={onClickTableMenu}
          showMenu={showTableActions && !!selected.length}
          tableActions={tableActions}
        >
          <HeaderLeft>
            {!selected.length && (
              <Breadcrumbs items={breadcrumbsItems} onClickBreadcrumbs={onClickBreadcrumbs} />
            )}
            <DropdownMenu
              name='finderActions'
              onClick={onClickPageMenu}
              options={pageActions}
              placement='bottomLeft'
            >
              {({ open }) => (
                <IconButton
                  active={open}
                  hidden={hiddenAddFile || selected.length > 0}
                  icon='otherAdd'
                  name='create-folder-file'
                  size={KitSize.XS}
                  styleType='primary'
                />
              )}
            </DropdownMenu>
          </HeaderLeft>
          <TableOptions
            defaultColumns={columnsConfig(paths, clearQuery)}
            formArgs={{ view, setView }}
            hidden={view === ViewResourceEnum.card}
            name={FINDER_PAGE_NAME}
          />
          <TableView setView={setView} view={view} />
          <div className={s.flex}>
            <SortPopover
              defaultValue={SortUiEnum.CREATED_AT_DESC}
              name='filePage'
              onChange={handleSetSort}
              options={SORT_OPTIONS}
              size={KitSize.S}
              value={valueToEnum(sort, sortOrder)}
            />
            <FilterPopover
              name='finder'
              onFiltersChange={handleSetFilter}
              placement='bottomRight'
              size={KitSize.S}
              value={filter}
              width={460}
            >
              <FormField config={contentTypeField(Boolean(defaultContentType))} />
              <FormField config={authorField(employeesParams)} />
              <FieldGroup layout='horizontal'>
                <FormField config={CREATED_FROM_FIELD} />
                <FormField config={CREATED_TO_FIELD} />
              </FieldGroup>
            </FilterPopover>
            <Search
              name='finder'
              onChange={handleSetQuery}
              size={KitSize.S}
              value={query}
              wait={500}
              {...testProps({ el: 'title', name: 'finder' })}
            />
          </div>
        </Header>
      </LayoutRow>
      {companyData?.data && showResourcesLimitAlert && (
        <LayoutRow horizontalFree={isModal} styleType='clear'>
          <div className={s.alert}>
            <StorageLimitAlert companyData={companyData?.data} />
          </div>
        </LayoutRow>
      )}
      <LayoutContent free={isModal}>
        <DragFileContainer createdFor={createdFor} params={params} type='image' uppy={uppy}>
          <DataFallback config={config(paths.main, backCb)} data={data} error={Boolean(error)}>
            {(data) => (
              <FinderComponent
                clearQuery={clearQuery}
                data={data}
                empty={{ search: isSearch, icon: 'folder', size: KitSize.S }}
                fetchMore={fetchNextChunk}
                isModal={isModal}
                loading={loading || chunkLoading}
                onChangeSelection={setSelected}
                onClickItem={onClickItem || NOOP}
                paths={paths}
                ref={containerRef}
                selectedItems={selected}
                total={total}
              />
            )}
          </DataFallback>
        </DragFileContainer>
      </LayoutContent>
    </>
  )
}

export default React.memo(FinderPage)

import { useMutation, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import { getMyUserInfoQuery } from 'gql/companies/gql/queries'

import { aiUsageAccept, generateText, testAiConfig, updateAiConfig } from './gql/mutations'
import { createAiConfig, deleteAiConfig } from './gql/mutations'
import { getAiConfigs } from './gql/queries'

export const useGenerateText = () =>
  useMutation(generateText, {
    onError: (error) =>
      console.error('"useGenerateText" fn is crashed on operation: "useMutation"', error),
    refetchQueries: [String(getOperationName(getMyUserInfoQuery))],
  })

export const useAiUsageAccept = () => useMutation(aiUsageAccept)

export const useCreateAiConfig = () =>
  useMutation(createAiConfig, {
    onError: (error) =>
      console.error('"useCreateAiConfig" fn is crashed on operation: "useMutation"', error),
    refetchQueries: [String(getOperationName(getAiConfigs))],
  })

export const useUpdateAiConfig = () =>
  useMutation(updateAiConfig, {
    onError: (error) =>
      console.error('"useUpdateAiConfig" fn is crashed on operation: "useMutation"', error),
    refetchQueries: [String(getOperationName(getAiConfigs))],
  })

export const useDeleteAiConfig = () =>
  useMutation(deleteAiConfig, {
    onError: (error) =>
      console.error('"useDeleteAiConfig" fn is crashed on operation: "useMutation"', error),
    refetchQueries: [String(getOperationName(getAiConfigs))],
  })

export const useGetAiConfigs = (companyId: string) => {
  const { data } = useQuery(getAiConfigs, {
    variables: { filter: { companyId } },
    onError: (error) =>
      console.error('"useGetAiConfig" fn is crashed on operation: "useQuery"', error),
  })

  return data?.data
}

export const useTestAiConfig = () =>
  useMutation(testAiConfig, {
    onError: (error) =>
      console.error('"testAiConfig" fn is crashed on operation: "useMutation"', error),
  })

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router'

import { usePathActive, usePathParamsSafe } from 'routes/hooks'
import { LinkType, RouteState } from 'routes/routes.types'

import { MCWithParams, ModalContext, useModalContainer } from './ModalContext'
import ModalRender from './ModalRender'
import { EXIT_ANIMATION_DURATION } from './contants'

interface IRouterModalProps<P, R> {
  path: string
  backPath?: LinkType<RouteState> | string
  component: MCWithParams<P, R>
}

const CHANGE_ROUTE_DELAY = EXIT_ANIMATION_DURATION * 1000 + 50

const RouterModal = <P extends RouteComponentProps, R>({
  path,
  component,
  backPath,
}: IRouterModalProps<P, R>) => {
  const activeRoute = usePathActive(path)
  const params = usePathParamsSafe(path) as any
  const [open, setOpen] = useState(false)
  const { modals } = useContext(ModalContext)

  const { push } = useHistory()
  const getContainer = useModalContainer()
  const hasOpen = useMemo(() => [...modals.values()].some((modal) => modal.open), [modals])

  // TODO: HOTFIX: for https://www.notion.so/2369-15a5457f94c58044b3c1efe90b62ce50
  // problem with using url params in modal(AnimatePresence)
  const [forceClose, setForceClose] = useState(false)

  const onClose = useCallback(() => {
    setForceClose(false)
    setOpen(false)
    setTimeout(() => {
      backPath ? push(backPath) : history.back()
    }, CHANGE_ROUTE_DELAY)
  }, [backPath, push])

  useEffect(() => {
    if (activeRoute) {
      setForceClose(false)
      setOpen(true)
    }
  }, [activeRoute])

  useEffect(() => {
    const handlePopState = () => !forceClose && setForceClose(true)

    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [forceClose, onClose])
  //
  if (forceClose) {
    return null
  }

  return (
    <ModalRender
      component={component}
      currentModalIndex={hasOpen ? 0 : -1}
      getContainer={getContainer}
      index={-1}
      onClose={onClose}
      open={open}
      params={params}
    />
  )
}

export default RouterModal

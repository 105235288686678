import { actions } from './reducer'

export const {
  scroll,

  init,
  reset,
  setPath,
  setDataProject,
  setDataSections,
  setDataBlocks,
  setDataComments,
  setDataTasks,
  setScormSettings,
  setUrlParams,

  updateBlock,
  updateBlockVersion,
  remoteBlockPatch,
  addUndoPatch,
  undoBlock,
  redoBlock,
  resetBlock,
  waitBlock,

  setBlockViewed,
  setElement,
  setCourseNavigation,
  testStart,
  testEnd,
  testRestart,
  setQuestion,
  completeElement,
  questionValidate,
  questionReset,
  answerReset,
  continueBlock,
  courseStart,
  courseContinue,
  courseEnd,
  setCourseStatus,
  setAppMode,
  setDeviceMode,
  setSidebar,
  setSidebarChapter,
  setSidebarSearch,
  setToolbar,
  setNavbar,
  setSettings,
  setCommentsFilter,
  setTasksFilter,
  setHighlight,
  setProjectNavigation,
  setScrollWait,
  clearScrollWait,
  setCommentForm,
  togglePinnedComment,
  setOpenedComment,
  setOpenedTask,
  setSyncing,

  setEmployeeToProjectPreferences,
} = actions

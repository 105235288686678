"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Splitter/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    border: {
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
    },
    icon: {
        inversion: (0, genStyle_1.genStyle)({ value: true }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    splitter: {
        width: (0, genStyle_1.genStyle)({ value: 1 }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
    },
    textLeft: {
        alignItems: (0, genStyle_1.genStyle)({ value: 'flex-start' }),
    },
    textRight: {
        alignItems: (0, genStyle_1.genStyle)({ value: 'flex-start' }),
    },
    effects: {
        boxShadow: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.boxShadow }),
    },
    indents: {
        padding: (0, genStyle_1.genStyle)({ value: [24, 24, 24, 24] }),
    },
};
const fontSchema = Object.assign({}, font_1.FONT_SCHEMA_EMPTY);
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };

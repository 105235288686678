import { gql } from 'gql/__generated__'

export const companySubscribe = gql(`
  subscription CompanySubscribe($userId: String!) {
    data: company(userId: $userId) {
      company {
        ...CompanyListSchema
      }
      type
    }
  }
`)

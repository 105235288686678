import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import s from './RectangleElement.module.scss'
import { RectangleElementType } from './RectangleTypes'

const RectangleElement: RectangleElementType = ({ styles }) => {
  const rootStyles = useElementRootStyles(
    styles.root,
    styles.rectangle,
    styles.border,
    styles.effects,
  )

  return <div className={s.root} style={rootStyles} />
}
export default RectangleElement

import { GroupingSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

export const AnswerDragIconStyled = styled.div<{
  $styles: ElementStyleCss<GroupingSchemaType>
  $isDragging: boolean
}>`
  transition: 0.2s;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: ${({ $styles }) => $styles.icon.color};
  background-color: ${({ $styles, $isDragging }) =>
    $isDragging ? $styles.icon_hover.backgroundColor : $styles.icon.backgroundColor};
`

const PortalGroupingItemStyled = styled.div<{
  $styles: ElementStyleCss<GroupingSchemaType>
}>`
  box-shadow: ${({ $styles }) => $styles.answer_effects.boxShadow};
  &:hover ${AnswerDragIconStyled} {
    background-color: ${({ $styles }) => $styles.icon_hover.backgroundColor} !important;
  }
`

export default PortalGroupingItemStyled

import { LinkElementValue } from '@leenda/editor/lib/elements'
import { gqlClient } from 'gql'

import { getFileUrlInfoQuery } from 'gql/files/gql/queries'
import { validateLinkUrl } from 'utils/websiteValidation'

export const getFileInfo = async (value: LinkElementValue, name: string) => {
  const url = value.linkURL
  const isURLcorrect = url ? /^https?/.test(url) : false
  const correctUrl = isURLcorrect ? url || '' : `https://${url}`

  return await gqlClient.core
    .query({ query: getFileUrlInfoQuery, variables: { url: correctUrl } })
    .then((res) => {
      const { title, siteName, description, images, favicons } = res.data.data?.payload || {}

      const newValue: LinkElementValue = Object.assign({}, value, {
        title,
        siteName,
        description,
        images,
        favicons,
        showCustomImage: false,
      })

      return { name, value: newValue }
    })
}

export const labelFromUrl = (url?: string) => {
  if (!url) {
    return
  }
  if (validateLinkUrl(url)) {
    try {
      const domain = new URL(url)
      return domain.hostname
    } catch (e) {
      return url
    }
  } else {
    return url
  }
}

import { useUnmount } from 'ahooks'
import { leftAiTokens } from 'entities/AiConfig/helpers'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Editor } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'

import AppText from 'components/AppText'
import {
  getPrompt,
  getSelectedFormat,
  getSetPrompt,
  getSetToolbarForm,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import { insertWithFormating } from 'components/form/RichText/utils/common'
import { collapseSelectionToEnd } from 'components/form/RichText/utils/selection'
import { IconButton } from 'components/uiKit/Button'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import TextArea from 'components/uiKit/TextArea'
import { PROJECT_PATHS } from 'constants/paths'
import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { useMyInfo } from 'gql/__utils__/useMyInfo'
import { usePathParams } from 'routes/hooks'
import { t } from 'services/Translation'
import { getEnumLabel } from 'utils/enum'

import { useAi } from '../../useAi'
import { useToolbarPosition } from '../../useToolbarPosition'
import s from './AiForm.module.scss'
import Response from './Response'

type Command = {
  key: AiTextPromptTogglesEnum
  instruction: string
}

export const TEXT_AREA_CONFIG = {
  minRows: 1,
  maxRows: 3,
}

const AiForm: React.FC = () => {
  const prompt = useRichTextContext(getPrompt)
  const setPrompt = useRichTextContext(getSetPrompt)
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { companyId } = usePathParams(PROJECT_PATHS.editor)
  const [value, setValue] = useState(
    prompt !== AiTextPromptTogglesEnum.CUSTOM
      ? getEnumLabel('AiTextPromptTogglesEnum', prompt)
      : '',
  )
  const {
    sendPrompt,
    acceptAiUsage,
    slate,
    response: { data, loading, error, called },
  } = useAi()
  const { profile } = useMyInfo(companyId)
  const format = useRichTextContext(getSelectedFormat)

  const setToolbarForm = useRichTextContext(getSetToolbarForm)
  const company = profile?.company
  const disableAi = leftAiTokens(company?.aiConfigs) < 0

  const editor = useSlate()
  const onRun = useCallback(
    async (command: Command) => {
      try {
        const prompt = editor.selection && Editor.string(editor, editor.selection)

        if (!prompt) {
          return
        }
        sendPrompt(command.key, command.instruction)
      } catch (error) {
        console.error(error)
      }
    },
    [editor, sendPrompt],
  )

  const onCustomEdit = useCallback(() => {
    prompt &&
      onRun({
        key: prompt,
        instruction: prompt === AiTextPromptTogglesEnum.CUSTOM ? value : '',
      })
  }, [onRun, prompt, value])

  const handleClose = useCallback(() => setToolbarForm('default'), [setToolbarForm])

  const handleConfirm = useCallback(() => {
    if (data && slate) {
      acceptAiUsage({ variables: { id: data.id } })
      ReactEditor.focus(editor)
      insertWithFormating(editor, format, slate)
      handleClose()
    }
  }, [acceptAiUsage, data, editor, format, handleClose, slate])

  const handleInsert = useCallback(() => {
    if (data && slate) {
      acceptAiUsage({ variables: { id: data.id } })
      collapseSelectionToEnd(editor)
      editor.insertBreak()
      ReactEditor.focus(editor)
      editor.insertFragment(slate)
      editor.onChange()
      handleClose()
    }
  }, [acceptAiUsage, data, editor, handleClose, slate])

  useEffect(() => {
    if (prompt && prompt !== AiTextPromptTogglesEnum.CUSTOM && !called) {
      sendPrompt(prompt)
    }
  }, [prompt, called, sendPrompt])

  if (error) {
    handleClose()
  }

  useEffect(() => {
    if (prompt === AiTextPromptTogglesEnum.CUSTOM) {
      inputRef.current?.focus()
    }
  }, [prompt])

  useUnmount(() => {
    setPrompt(null)
  })

  useToolbarPosition(ref)

  if (data || loading) {
    return (
      <Response
        disabled={disableAi}
        error={error}
        loading={loading}
        onInsert={handleInsert}
        onReplace={handleConfirm}
        onSendPrompt={onCustomEdit}
        responseText={<AppText name='aiResponse' value={slate} readOnly />}
        setValue={setValue}
        value={value}
      />
    )
  }

  return (
    <div className={s.root} ref={ref}>
      <div className={s.prompt}>
        <IconButton
          icon='builderInputBack'
          name='closeCustomPanel'
          onClick={handleClose}
          size={KitSize.S}
          styleType='secondary'
        />
        <div className={s.iconWrapper}>
          <Icon name='builderAi' size={KitSize.S} />
        </div>
        <div className={s.input}>
          <TextArea
            autoSize={TEXT_AREA_CONFIG}
            lineHeight={18}
            name='aiInput'
            onChange={setValue}
            placeholder={t('elements.richText.ai.placeholder')}
            ref={inputRef}
            size='mini'
            styleType='ghost'
            value={value}
          />
        </div>
        <div className={s.controls}>
          <IconButton
            disabled={!value}
            icon={!value ? 'iconSendDisabled' : 'iconSend'}
            name='runAi'
            onClick={onCustomEdit}
            size={KitSize.S}
            styleType='ghost'
          />
        </div>
      </div>
    </div>
  )
}

export default AiForm

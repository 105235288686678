import { TestFeedbackFontSchemaType, TestFeedbackSchemaType } from '@leenda/editor/lib/brand'
import { TestFeedbackElementValue } from '@leenda/editor/lib/elements'
import { RichTextValue, SlateElementType, textToRtValue, rtValueToText } from '@leenda/rich-text'
import { useUpdateEffect } from 'ahooks'
import cn from 'classnames'
import * as R from 'ramda'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import RichText from 'components/form/RichText/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { getBlockState as getBlockStatePreview } from 'services/Store/Project/selectors/state/block'
import { testProps } from 'utils/test/qaData'

import s from './TestFeedbackElement.module.scss'
import { TestFeedbackElementType } from './TestFeedbackTypes'
import failIcon from './assets/fail.svg'
import infoIcon from './assets/info.svg'
import successIcon from './assets/success.svg'

const RT_MAP = {
  [SlateElementType.elementDefault]: 'base',
  [SlateElementType.heading1]: 'h1',
  [SlateElementType.heading2]: 'h2',
  [SlateElementType.heading3]: 'h3',
  [SlateElementType.heading4]: 'h4',
  [SlateElementType.caption]: 'caption',
}
const ICONS_MAP = { fail: failIcon, info: infoIcon, success: successIcon }
const ANIMATION_TIME = 500
const TRANSITION = `max-height ${ANIMATION_TIME}ms ease-in-out`

const getView = (
  value: TestFeedbackElementValue,
  type: 'info' | 'success' | 'fail',
  styles: ElementStyleCss<TestFeedbackSchemaType>,
  font: ElementFontCss<TestFeedbackFontSchemaType>,
) => {
  const panelKey = `${type}Panel` as const
  const iconPath = styles[`${type}Icon` as const].backgroundImage || `url(${ICONS_MAP[type]})`
  //@ts-ignore
  const firstRow = font?.[RT_MAP?.[value[type]?.[0]?.type] || 'base']
  const height = `calc(${parseInt(firstRow?.lineHeight) || 1.2} * ${parseInt(firstRow?.fontSize) || 16}px)`
  return {
    text: value[type],
    stylesPanel: styles[panelKey],
    stylesIcon: {
      backgroundImage: iconPath,
      height,
    },
  }
}

const TestFeedbackElement: TestFeedbackElementType = ({
  mode,
  block,
  element,
  state,
  styles,
  waiting,
  onChange,
  font,
}) => {
  const isPreview = useProjectContext(getIsInteractiveMode)
  const isEditor =
    mode.previewMode === PreviewMode.editor || mode.previewMode === PreviewMode.skeleton
  const ref = useRef<HTMLDivElement>(null)

  const blockResult = useProjectContext(getBlockStatePreview, block?.uuid)?.result
  const [maxHeight, setMaxHeight] = useState<number | string>(blockResult || state ? 'none' : 0)
  const isValid = (blockResult || state)?.isValid
  const type = element.value.type === 'info' ? 'info' : isValid ? 'success' : 'fail'
  const view = useMemo(
    () => getView(element.value, type, styles, font),
    [element.value, styles, font, type],
  )
  const { text, stylesIcon, stylesPanel } = view
  const rootStyles = useElementRootStyles(styles.root, styles.common, stylesPanel)
  const { isActiveElement, isFill, onLabelSelect, activeIndex } = useGetRichTextProps({
    elementId: element.id,
    mode,
  })

  const onChangeLabel = (labelValue: RichTextValue) => {
    onChange?.(R.assoc(type, labelValue, element.value))
  }

  useEffect(() => {
    if (isPreview) {
      ref.current && blockResult && setMaxHeight(ref.current.offsetHeight)
    } else {
      setMaxHeight('none')
    }
  }, [isPreview, blockResult, mode.deviceMode, view])

  useUpdateEffect(() => {
    if (element.value.show && blockResult && ref.current) {
      ScrollService.scroll(
        {
          container: ScrollContainerEnum.canvas,
          el: ref.current,
          block: 'begin',
          scroll: 'always',
          async: ANIMATION_TIME,
        },
        null,
      )
    }
  }, [element.value.show, blockResult])

  const textName = `feedback.${type}`

  if (
    (isEditor && !state?.hidden) ||
    (element.value.show && blockResult) ||
    mode.previewMode === PreviewMode.demo
  ) {
    return (
      <div
        className={s.wrapper}
        style={{
          ...styles.effects,
          borderRadius: styles.common.borderRadius,
          transition: TRANSITION,
          maxHeight,
        }}
      >
        <div className={cn(s.root, s[mode.deviceMode])} ref={ref} style={rootStyles}>
          <span
            {...testProps({ el: 'iconFeedback', type })}
            className={s.icon}
            style={stylesIcon}
          />
          <div {...testProps({ el: 'titleFeedback', label: rtValueToText(text) })}>
            <span
              className={s.defaultTitleContent}
              onMouseDown={() => {
                onLabelSelect(textName)
              }}
            >
              <RichText
                active={isActiveElement && activeIndex === textName}
                disabled={!isFill}
                name={textName}
                onChange={(val) => onChangeLabel(val)}
                styles={font}
                value={text || textToRtValue('')}
                waiting={waiting}
              />
            </span>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default TestFeedbackElement

import { LabeledGraphicFontSchemaType } from '@leenda/editor/lib/brand'
import { LabeledGraphicPoint, LabeledGraphicElementValue } from '@leenda/editor/lib/elements'
import { rtValueToText } from '@leenda/rich-text'
import cn from 'classnames'
import * as R from 'ramda'
import React, { useRef, useState } from 'react'

import CourseTooltip from 'components/editor-v3/cource/components/CourseTooltip'
import Icon from 'components/uiKit/Icon'
import { ElementFontCss } from 'services/Branding/types'
import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { getImageByUsage } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import { useLabeledGraphicContext, useLabeledGraphicImgRef } from '../LabeledGraphicContext'
import PointStyled from '../styled/PointStyled'
import PointWrapperStyled from '../styled/PointWrapperStyled'
import RingStyled from '../styled/RingStyled'
import RingsStyled from '../styled/RingsStyled'
import s from './Point.module.scss'
import PointTooltip from './PointTooltip'

const toPercents = (full: number) => (value: number) => (value / full) * 100
const setX = (i: number, x: string) =>
  R.assocPath<string, LabeledGraphicElementValue>(['items', i, 'x'], x)
const setY = (i: number, y: string) =>
  R.assocPath<string, LabeledGraphicElementValue>(['items', i, 'y'], y)

interface IPointProps {
  point: LabeledGraphicPoint
  index: number
  isActive: boolean
  value: LabeledGraphicElementValue
  id: string
  isAnimated?: boolean
  font: ElementFontCss<LabeledGraphicFontSchemaType>
}

const Point: React.FC<IPointProps> = ({ point, index, isActive, value, id, isAnimated, font }) => {
  const { type = 'image' } = value
  const styles = useLabeledGraphicContext((c) => c.styles)
  const mode = useLabeledGraphicContext((c) => c.mode)
  const isFill = mode.editorMode === AppMode.fill
  const element = useLabeledGraphicContext((c) => c.element)
  const onChange = useLabeledGraphicContext((c) => c.onChange)
  const imgRef = useLabeledGraphicImgRef()
  const setActivePoint = useLabeledGraphicContext((c) => c.setActivePoint)

  const ref = useRef<HTMLDivElement>(null)
  const isEditor = mode.previewMode === PreviewMode.editor
  const isSkeleton = mode.previewMode === PreviewMode.skeleton
  const isImage = type === 'image'
  const isMobile = mode.deviceMode === 'mobile'

  const file = getImageByUsage(point.pointIcon)
  const [isDragging, setIsDragging] = useState(false)

  const iconClasses = cn(s.icon, { [s.drag]: isDragging })
  const dragIconClasses = cn(s.dragIcon, { [s.drag]: isDragging })

  const handleClose = () => setActivePoint(null)
  const Component: Record<string, JSX.Element | null> = {
    number: (
      <div className={cn(isDragging && s.hidden)} style={font?.numbering}>
        {index + 1}
      </div>
    ),
    image:
      !styles.point.backgroundImage && !file?.path && !point.image?.url ? (
        <div className={iconClasses}>
          <Icon name='otherAdd' />
        </div>
      ) : null,
    withoutIcon: null,
  }

  const onMouseDown = (e: React.MouseEvent) => {
    const { clientX, clientY } = e
    const clickStart = { x: clientX, y: clientY }
    let isDragDistance = false
    setActivePoint(null)

    const onMouseMove = (event: MouseEvent) => {
      isDragDistance =
        isDragDistance ||
        Math.abs(event.clientX - clickStart.x) > 5 ||
        Math.abs(event.clientY - clickStart.y) > 5
      if (isFill && isDragDistance) {
        setIsDragging(true)
        const { clientX, clientY } = event
        const img = imgRef?.current?.getBoundingClientRect()
        const { width = 900, height = 300, x: imgX = 0, y: imgY = 0 } = img || {}
        const percentsW = toPercents(width)
        const percentsH = toPercents(height)

        const point = ref.current?.getBoundingClientRect()
        const { width: pointWidth = 32, height: pointHeight = 32 } = point || {}

        const minX = 0 + percentsW(pointWidth) / 2
        const maxX = 100 - percentsW(pointWidth) / 2
        const x = R.clamp(minX, maxX, percentsW(clientX - imgX))

        const minY = 0 + percentsH(pointHeight) / 2
        const maxY = 100 - percentsH(pointHeight) / 2
        const y = R.clamp(minY, maxY, percentsH(clientY - imgY))
        onChange?.(R.compose(setX(index, `${x}%`), setY(index, `${y}%`))(value))
      }
    }

    const onMouseUp = () => {
      if (isDragDistance) {
        setIsDragging(false)
      } else {
        setActivePoint(isActive ? null : point.value)
      }
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  const PointInner = (
    <PointWrapperStyled $styles={styles}>
      {isAnimated && (
        <RingsStyled $styles={styles}>
          <RingStyled $styles={styles} />
          <RingStyled $styles={styles} />
        </RingsStyled>
      )}
      <PointStyled
        {...testProps({
          el: 'labeledGraphicElPoint',
          value: point.value,
          label: rtValueToText(point.description),
          index,
        })}
        $active={isActive}
        $editor={isEditor}
        $isImage={isImage}
        $path={file?.path || point.image?.url}
        $styles={styles}
        ref={ref}
      >
        {type && Component[type]}
        <div className={dragIconClasses}>
          <Icon name='builderDrug' />
        </div>
      </PointStyled>
    </PointWrapperStyled>
  )

  return (
    <div className={s.root} style={{ top: point.y, left: point.x }}>
      {isMobile ? (
        <div>{PointInner}</div>
      ) : (
        <CourseTooltip
          isAnimated={styles.animation.fade && !isEditor}
          key={index}
          mode={mode}
          name={String(index)}
          onClose={handleClose}
          onMouseDown={onMouseDown}
          open={!isSkeleton && isActive}
          title={
            <PointTooltip
              count={value.items.length}
              font={font}
              id={id}
              index={index}
              items={element.value.items}
              point={point}
              value={value}
            />
          }
          destroyTooltipOnHide
        >
          {PointInner}
        </CourseTooltip>
      )}
    </div>
  )
}

export default Point

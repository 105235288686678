import { useMutation, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import { ParticipantInput } from 'gql/__generated__/graphql'
import { companyGetByIdQuery } from 'gql/companies/gql/queries'
import { employeesGetAllQuery } from 'gql/employees/gql/queries'
import { projectsGetByIdQuery } from 'gql/projects/gql/queries'

import { participantsBulkDelete, participantsBulkUpdate, participantsInvite } from './gql/mutations'
import { participantsAll } from './gql/queries'

export const useParticipantsAll = (projectId: string, data: ParticipantInput, cache?: boolean) =>
  useQuery(participantsAll, {
    variables: { projectId, data },
    onError: (err) =>
      console.error('"useParticipantsAll" fn is crashed on operation: "useQuery"', { err }),
    fetchPolicy: cache ? 'cache-only' : 'cache-and-network',
  })

export const useParticipantsInvite = () =>
  useMutation(participantsInvite, {
    refetchQueries: [
      String(getOperationName(participantsAll)),
      String(getOperationName(employeesGetAllQuery)),
      String(getOperationName(companyGetByIdQuery)),
    ],
  })

export const useParticipantsBulkUpdate = () =>
  useMutation(participantsBulkUpdate, {
    onError: (error) =>
      console.error('"useParticipantsBulkUpdate" fn is crashed on operation: "useMutation"', error),
    refetchQueries: [String(getOperationName(participantsAll))],
  })

export const useParticipantsBulkDelete = () =>
  useMutation(participantsBulkDelete, {
    onError: (error) =>
      console.error('"useParticipantsBulkDelete" fn is crashed on operation: "useMutation"', error),
    refetchQueries: [
      String(getOperationName(projectsGetByIdQuery)),
      String(getOperationName(participantsAll)),
    ],
  })

import { gql } from 'gql/__generated__'

export const createBrand = gql(`
  mutation CreateBrand($companyId: ID!, $data: BrandInput!) {
    data: createBrand(companyId: $companyId, data: $data) {
      ...BrandTableItem
    }
  }
`)

export const cloneCompanyBrand = gql(`
  mutation CloneCompanyBrands($brandIds: [ID!]!) {
    data: cloneCompanyBrand(brandIds: $brandIds) {
      ...BrandTableItem
    }
  }
`)

export const setCompanyBrand = gql(`
  mutation SetCompanyBrand($companyId: ID!, $brandId: String!) {
    data: setCompanyBrand(companyId: $companyId, brandId: $brandId) {
      ...BrandTableItem
    }
  }
`)

export const deleteBrandsByIds = gql(`
  mutation DeleteBrandsByIds($payload: DeleteBrandByIds!) {
    data: deleteBrandByIds(payload: $payload)
  }
`)

export const updateCompanyBrand = gql(`
  mutation UpdateCompanyBrand($brandId: ID!, $data: BrandInput!) {
    data: updateCompanyBrand(brandId: $brandId, data: $data) {
      ...BrandEditable
    }
  }
`)

import {
  PositionTabsElementType,
  SizeTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const borderByTopPosition = css`
  flex-direction: row;
`

const borderByLeftPosition = (
  borderRadius: ElementStyleCss<TabsSchemaType>['view']['borderRadius'],
) => css`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column;
  border-bottom-left-radius: ${borderRadius};
  border-top-left-radius: ${borderRadius};
`

const borderByRightPosition = (
  borderRadius: ElementStyleCss<TabsSchemaType>['view']['borderRadius'],
) => css`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column;
  border-bottom-right-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
`

const borderStyle = (styles: ElementStyleCss<TabsSchemaType>) => {
  const position = styles.tabs.position as PositionTabsElementType
  const borderRadius = styles.view.borderRadius
  return css`
    ${position === PositionTabsElementType.top && borderByTopPosition};
    ${position === PositionTabsElementType.left && borderByLeftPosition(borderRadius)};
    ${position === PositionTabsElementType.right && borderByRightPosition(borderRadius)};
  `
}

const alignItemsTop = css`
  align-items: stretch;
`

const alignItemsLeft = css`
  align-items: flex-start;
`

const alignItemsRight = css`
  align-items: flex-end;
`

const getAlignItems = (styles: ElementStyleCss<TabsSchemaType>) => {
  const position = styles.tabs.position as PositionTabsElementType
  return css`
    ${position === PositionTabsElementType.top && alignItemsTop};
    ${position === PositionTabsElementType.left && alignItemsLeft};
    ${position === PositionTabsElementType.right && alignItemsRight};
  `
}

const splitted = ($styles: ElementStyleCss<TabsSchemaType>) =>
  $styles.view.type === ViewTabsElementType.splitted &&
  css`
    gap: 8px;
    ${$styles.tabs.tabSize === SizeTabsElementType.hug && getAlignItems($styles)}
  `

const TabItemsStyled = styled.ul<{
  $styles: ElementStyleCss<TabsSchemaType>
}>`
  position: relative;
  display: flex;
  list-style: none;
  cursor: pointer;
  flex-shrink: 0;
  margin-bottom: 0;
  white-space: nowrap;
  pointer-events: all;
  box-sizing: border-box;
  ${({ $styles }) => borderStyle($styles)};
  ${({ $styles }) => splitted($styles)};
`

export default TabItemsStyled

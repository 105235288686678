import { CardSide, ScalingCardEnum } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { useMemo } from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'
import { chainName, getParent } from 'utils/form'

import { CardValueFormType } from './CardElement.types'
import s from './CardForm.module.scss'

const genField = genDataField<Block>()

export const frontImage = genField({
  name: 'front.image',
  label: t('elements.card.form.sideA'),
  type: 'file',
  layout: 'vertical',
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
    showSource: true,
  },
})

export const frontOverlay = genField({
  name: 'front.overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

export const backImage = genField({
  name: 'back.image',
  type: 'file',
  layout: 'vertical',
  label: t('elements.card.form.sideB'),
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
    showSource: true,
  },
})

export const backOverlay = genField({
  name: 'back.overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

export const frontScaling = genField({
  name: 'front.scaling',
  type: 'select',
  label: t('elements.card.form.scaling'),
  layout: 'horizontal',
  defaultValue: ScalingCardEnum.fit,
  params: (block, { name }) => {
    const { parent } = getParent<CardSide>(name, block, 1)
    return {
      hidden: !parent?.image,
      options: [
        getEnumOption('ScalingCardEnum', ScalingCardEnum.fill),
        getEnumOption('ScalingCardEnum', ScalingCardEnum.fit),
        getEnumOption('ScalingCardEnum', ScalingCardEnum.stretch),
      ],
    }
  },
})

export const backScaling = genField({
  name: 'back.scaling',
  type: 'select',
  label: t('elements.card.form.scaling'),
  layout: 'horizontal',
  defaultValue: ScalingCardEnum.fit,
  params: (block, { name }) => {
    const { parent } = getParent<CardSide>(name, block, 1)
    return {
      hidden: !parent?.image,
      options: [
        getEnumOption('ScalingCardEnum', ScalingCardEnum.fill),
        getEnumOption('ScalingCardEnum', ScalingCardEnum.fit),
        getEnumOption('ScalingCardEnum', ScalingCardEnum.stretch),
      ],
      labeled: true,
    }
  },
})

const CardForm: CardValueFormType = ({ name, state, setState }) => {
  const handleAClick = () => setState({ isBack: false, pauseAnimation: false })
  const handleBClick = () => setState({ isBack: true, pauseAnimation: false })

  return (
    <>
      <div className={cn({ [s.active]: !state?.isBack })} onClick={handleAClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, frontImage)} />
          <RealtimeField config={chainName(name, frontScaling)} />
          <RealtimeField config={chainName(name, frontOverlay)} />
        </Padding>
      </div>
      <Divider free />
      <div className={cn({ [s.active]: state?.isBack })} onClick={handleBClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, backImage)} />
          <RealtimeField config={chainName(name, backScaling)} />
          <RealtimeField config={chainName(name, backOverlay)} />
        </Padding>
      </div>
    </>
  )
}

export default CardForm

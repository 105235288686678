import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import React from 'react'
import { useContextSelector, createContext } from 'use-context-selector'

import { PortalContent, PortalTarget } from 'components/uiKit/Portal'
import { EMPTY_ARRAY, VOID } from 'constants/commonConstans'

import FixedElementsContainer from '../FixedElementsContainer'

interface IUseAddFixedElementsHOCProps {
  id: string
  order?: number
  children: ReactNode
}

interface IContextValueType {
  portalIds: string[]
  removePortalId: (id: string) => void
  addPortalIdByIndex: (id: string, order?: number) => void
}

const FixedElementsContext = createContext<IContextValueType>({
  portalIds: EMPTY_ARRAY,
  removePortalId: VOID,
  addPortalIdByIndex: VOID,
})

export const FixedElementsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [portalIds, setPortalIds] = useState<string[]>([])

  const addPortalIdByIndex = useCallback(
    (id: string, order?: number) => {
      setPortalIds((prev) => {
        const copyPortalId = [...prev]
        copyPortalId.splice(order ?? portalIds.length, 0, id)
        return copyPortalId
      })
    },
    [portalIds.length],
  )

  const removePortalId = useCallback(
    (id: string) => setPortalIds((prev) => prev.filter((portalId) => portalId !== id)),
    [],
  )

  const value = useMemo(
    () => ({ portalIds, removePortalId, addPortalIdByIndex }),
    [portalIds, removePortalId, addPortalIdByIndex],
  )

  return (
    <FixedElementsContext.Provider value={value}>
      {children}
      <FixedElementsContainer>
        {portalIds.map((id) => (
          <PortalTarget id={id} key={id} />
        ))}
      </FixedElementsContainer>
    </FixedElementsContext.Provider>
  )
}

export const useFixedElementsContext = (): IContextValueType => {
  const portalIds = useContextSelector(FixedElementsContext, ({ portalIds }) => portalIds)
  const addPortalIdByIndex = useContextSelector(
    FixedElementsContext,
    ({ addPortalIdByIndex }) => addPortalIdByIndex,
  )
  const removePortalId = useContextSelector(
    FixedElementsContext,
    ({ removePortalId }) => removePortalId,
  )

  return useMemo(
    () => ({
      portalIds,
      addPortalIdByIndex,
      removePortalId,
    }),
    [portalIds, addPortalIdByIndex, removePortalId],
  )
}

export const FixedElementsWrapper = ({ id, order, children }: IUseAddFixedElementsHOCProps) => {
  const { addPortalIdByIndex, removePortalId } = useFixedElementsContext()

  useEffect(() => {
    addPortalIdByIndex(id, order)
    return () => removePortalId(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, id])

  return <PortalContent id={id}>{children}</PortalContent>
}

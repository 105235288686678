import {
  ThemeKeys,
  ThemeType,
  SchemaKeys,
  KEYS,
  StyleFullValue,
  RichTextFontSchemaType,
} from '@leenda/editor/lib/brand'
import { getThemeValue, setThemeVariable } from '@leenda/editor/lib/brand/utils'
import { StyleValue } from '@leenda/editor/lib/elements'
import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import cn from 'classnames'
import lodash from 'lodash'
import * as R from 'ramda'
import { useMemo } from 'react'

import { genField } from 'components/controls/Field'
import { IFieldConfig } from 'components/controls/Field/Field.types'
import { IArrayOption } from 'components/controls/inputComponents/FieldNumber/FieldNumber'
import { useBrandForm } from 'components/pages/BrandPage/hooks'
import Badge from 'components/uiKit/Badge'
import { ColorOption, IColorOption } from 'components/uiKit/ColorPicker'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { DeviceMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { useFontFamilyOptions, useFontWeightOptions } from 'utils/font/hooks'
import { getParent } from 'utils/form'

import BrandCustomShadowField from '../BrandCustomShadowField'
import { fontsDeviceComparator } from '../comparators'
import { useBrandTheme } from '../hooks'
import s from './Fields.module.scss'
import { BRAND_THEME_LABELS } from './labels'

export const simplifyStyleValue = <V extends StyleValue>(
  v?: Partial<StyleFullValue<V>> | V,
): V | undefined => {
  if (lodash.isObject(v) && 'value' in v) {
    return v.value
  }
  return v as V
}

export const getBrandParent = <T,>(name?: string, data?: unknown, level = 1) => {
  const pathParts = name?.split('.') || []
  const parentName = pathParts.slice(0, pathParts.length - level).join('.')

  return {
    parent: (parentName ? lodash.get(data, parentName) : data) as T | undefined,
    parentName,
  }
}

const DesktopIcon = ({ name }: { name: SchemaKeys }) => {
  const defaultValue = useBrandForm((state) => state.defaultValues?.schema?.[name]?.font?.desktop)
  const value = useBrandForm((state) => state.values?.schema?.[name]?.font?.desktop)
  const isEqual = fontsDeviceComparator(defaultValue, value)

  return (
    <Tooltip overlay={t('uiKit.tooltip.webVersion')}>
      <Badge count={isEqual ? 1 : undefined} styleType='dot'>
        <Icon name='builderDesktop' size={KitSize.S} />
      </Badge>
    </Tooltip>
  )
}

const TabletIcon = ({ name }: { name: SchemaKeys }) => {
  const defaultValue = useBrandForm((state) => state?.defaultValues?.schema?.[name]?.font?.tablet)
  const value = useBrandForm((state) => state.values?.schema?.[name]?.font?.tablet)
  const isEqual = fontsDeviceComparator(defaultValue, value)

  return (
    <Tooltip overlay={t('uiKit.tooltip.tabletVersion')}>
      <Badge count={isEqual ? 1 : undefined} styleType='dot'>
        <Icon name='builderTablet' size={KitSize.S} />
      </Badge>
    </Tooltip>
  )
}

const MobileIcon = ({ name }: { name: SchemaKeys }) => {
  const defaultValue = useBrandForm((state) => state.defaultValues?.schema?.[name]?.font?.mobile)
  const value = useBrandForm((state) => state.values?.schema?.[name]?.font?.mobile)
  const isEqual = fontsDeviceComparator(defaultValue, value)

  return (
    <Tooltip overlay={t('uiKit.tooltip.mobileVersion')}>
      <Badge count={isEqual ? 1 : undefined} styleType='dot'>
        <Icon name='builderMobile' size={KitSize.S} />
      </Badge>
    </Tooltip>
  )
}

export const DEVICE_MODE_OPTIONS = (name: SchemaKeys) => [
  { value: DeviceMode.desktop, label: <DesktopIcon name={name} /> },
  { value: DeviceMode.tablet, label: <TabletIcon name={name} /> },
  { value: DeviceMode.mobile, label: <MobileIcon name={name} /> },
]

export const HORIZONTAL_ALIGN_OPTIONS = [
  { value: 'flex-start', label: <Icon name='alignmentAlignLeft' size={KitSize.S} /> },
  { value: 'center', label: <Icon name='alignmentAlignMid' size={KitSize.S} /> },
  { value: 'flex-end', label: <Icon name='alignmentAlignRight' size={KitSize.S} /> },
]

export const VERTICAL_ALIGN_OPTIONS = [
  { value: 'flex-start', label: <Icon name='alignmentAlignTop' size={KitSize.S} /> },
  { value: 'center', label: <Icon name='alignmentAlignCenter' size={KitSize.S} /> },
  { value: 'flex-end', label: <Icon name='alignmentAlignBottom' size={KitSize.S} /> },
]

export const OBJECT_FIT_OPTIONS = [
  { label: t('input.option.cover'), value: 'cover' },
  { label: t('input.option.contain'), value: 'contain' },
  { label: t('input.option.fill'), value: 'fill' },
]

export const FONT_SIZE_OPTIONS = [
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 18, label: '18' },
  { value: 20, label: '20' },
  { value: 22, label: '22' },
  { value: 24, label: '24' },
  { value: 28, label: '28' },
  { value: 32, label: '32' },
  { value: 36, label: '36' },
  { value: 42, label: '42' },
  { value: 56, label: '56' },
  { value: 72, label: '72' },
]

export const BORDER_OPTIONS = [
  {
    value: 'none',
    label: t('common.no'),
  },
  {
    value: 'solid',
    label: t('common.yes'),
  },
]

export const LINE_HEIGHT_OPTIONS = [
  { label: '1', value: 1 },
  { label: '1.5', value: 1.5 },
  { label: '2', value: 2 },
  { label: 'auto', value: 'normal' },
]

export const OFFSET_ARRAY: IArrayOption[] = [
  { icon: <Icon name='builderInputUp' size={KitSize.XS} /> },
  { icon: <Icon name='builderInputForward' size={KitSize.XS} /> },
  { icon: <Icon name='builderInputDown' size={KitSize.XS} /> },
  { icon: <Icon name='builderInputBack' size={KitSize.XS} /> },
]

export const FONT_ITALIC_PARAMS = {
  style: { padding: 0 },
  hideCheckbox: true,
  option: {
    label: ({ disabled }: { disabled?: boolean }) => (
      <div className={cn(s.italic, { [s.disabled]: disabled })}>
        <Tooltip disabled={disabled} overlay={t('uiKit.tooltip.italic')} placement='bottomRight'>
          <Icon name='builderItalic' size={KitSize.S} />
        </Tooltip>
      </div>
    ),
    valueTrue: 'italic',
    valueFalse: 'normal',
  },
}

const FLEX_DIRECTION_OPTIONS = [
  {
    label: (
      <Tooltip overlay={t('elements.layoutList.tooltip.row')}>
        <Icon name='builderArrowRight' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'row',
  },
  {
    label: (
      <Tooltip overlay={t('elements.layoutList.tooltip.column')}>
        <Icon name='builderArrowDown' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'column',
  },
]

export const COLORS_THEME = [
  KEYS.primaryColor,
  KEYS.secondaryColor,
  KEYS.hoverColor,
  KEYS.focusedColor,
  KEYS.inactiveColor,
  KEYS.neutralColor,
  KEYS.borderColor,
]

export const TEXT_COLORS = [
  KEYS.primaryTextColor,
  KEYS.secondaryTextColor,
  KEYS.lightTextColor,
  KEYS.accentTextColor,
]

export const BACKGROUND_COLORS = [
  KEYS.primaryBackgroundColor,
  KEYS.darkBackgroundColor,
  KEYS.lightBackgroundColor,
  KEYS.accentBackgroundColor,
]

export const FEEDBACK_COLORS = [
  KEYS.infoColor,
  KEYS.warningColor,
  KEYS.successColor,
  KEYS.errorColor,
]

export const genColorOptions = (theme: ThemeType): IColorOption[] => [
  {
    value: 'brand',
    label: t('input.option.brandColors'),
    options: COLORS_THEME.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      rootLabel: `${t('input.option.brandColors')}/${BRAND_THEME_LABELS[key]}`,
      color: String(theme[key]),
    })),
  },
  {
    value: 'text',
    label: t('input.option.textColors'),
    options: TEXT_COLORS.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      rootLabel: `${t('input.option.textColors')}/${BRAND_THEME_LABELS[key]}`,
      color: String(theme[key]),
    })),
  },
  {
    value: 'background',
    label: t('input.option.backgroundColors'),
    options: BACKGROUND_COLORS.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      rootLabel: `${t('input.option.backgroundColors')}/${BRAND_THEME_LABELS[key]}`,
      color: String(theme[key]),
    })),
  },
  {
    value: 'feedback',
    label: t('input.option.additionalColors'),
    options: FEEDBACK_COLORS.map((key) => ({
      value: setThemeVariable(key),
      label: genColorLabel(key, theme[key] as string),
      rootLabel: `${t('input.option.additionalColors')}/${BRAND_THEME_LABELS[key]}`,
      color: String(theme[key]),
    })),
  },
]
export const genColorLabel = (name: ThemeKeys, color: string) => (
  <ColorOption color={color} name={BRAND_THEME_LABELS[name]} />
)

export const DEFAULT_BRAND_FIELDS: Partial<Record<string, IFieldConfig>> = {
  backgroundColor: genField({
    type: 'color',
    label: t('input.label.backgroundColor'),
    layout: ['horizontal', 'solid'],
    useParams: () => {
      const theme = useBrandTheme()
      const options = useMemo(() => genColorOptions(theme), [theme])
      return {
        transparent: true,
        labeled: true,
        options,
      }
    },
  }),
  backgroundImage: genField({
    type: 'fileId',
    layout: 'vertical',
    params: { fileType: FileUsageTypeEnum.image, preview: true },
  }),
  color: genField({
    type: 'color',
    label: t('input.label.color'),
    layout: ['horizontal', 'solid'],
    useParams: () => {
      const theme = useBrandTheme()
      const options = useMemo(() => genColorOptions(theme), [theme])
      return {
        labeled: true,
        options,
      }
    },
  }),

  borderStyle: genField({
    type: 'segmented',
    label: t('input.label.border'),
    layout: ['horizontal', 'solid'],
    params: { options: BORDER_OPTIONS },
    useConfig: (_, config) => {
      const theme = useBrandTheme()
      return { ...config, getValue: R.curry(getThemeValue)(R.__, theme) }
    },
  }),
  borderColor: genField({
    type: 'color',
    label: t('input.label.borderColor'),
    layout: ['horizontal', 'solid'],
    useParams: (data, { name }) => {
      const theme = useBrandTheme()
      const { parent } = getParent<{ borderStyle?: 'none' }>(name, data)
      const borderStyle = simplifyStyleValue(parent?.borderStyle)
      const options = useMemo(() => genColorOptions(theme), [theme])
      return {
        hidden: borderStyle === 'none',
        labeled: true,
        options,
      }
    },
  }),
  borderWidth: genField({
    type: 'slider',
    label: t('input.label.borderWidth'),
    layout: 'horizontal',
    params: (data, { name }) => {
      const { parent } = getParent<{ borderStyle?: 'none' }>(name, data)
      const borderStyle = simplifyStyleValue(parent?.borderStyle)
      return {
        min: 1,
        max: 8,
        hidden: borderStyle === 'none',
        showInput: true,
      }
    },
  }),
  borderRadius: genField({
    type: 'slider',
    label: t('input.label.borderRadius'),
    layout: 'horizontal',
    params: { min: 0, max: 50, showInput: true },
    useConfig: (_, config) => {
      const theme = useBrandTheme()
      return { ...config, getValue: R.curry(getThemeValue)(R.__, theme) }
    },
  }),
  borderTopStyle: genField({
    type: 'segmented',
    label: t('input.label.showDivider'),
    layout: ['horizontal', 'solid'],
    params: {
      options: BORDER_OPTIONS,
    },
  }),
  borderTopColor: genField({
    type: 'color',
    label: t('input.label.dividerColor'),
    layout: ['horizontal', 'solid'],
    useParams: () => {
      const theme = useBrandTheme()
      const options = useMemo(() => genColorOptions(theme), [theme])
      return {
        labeled: true,
        options,
      }
    },
  }),
  borderTopWidth: genField({
    type: 'slider',
    label: t('input.label.borderWidth'),
    layout: 'horizontal',
    params: {
      min: 1,
      max: 8,
      showInput: true,
    },
  }),

  height: genField({
    type: 'number',
    label: t('input.label.height'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  width: genField({
    type: 'number',
    label: t('input.label.width'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginTop: genField({
    type: 'number',
    label: t('input.label.marginTop'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginRight: genField({
    type: 'number',
    label: t('input.label.marginSide'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginLeft: genField({
    type: 'number',
    label: t('input.label.marginSide'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  marginBottom: genField({
    type: 'number',
    label: t(t('input.label.paddingBottom')),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingTop: genField({
    type: 'number',
    label: t('input.label.paddingTop'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingLeft: genField({
    type: 'number',
    label: t('input.label.paddingLeft'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingRight: genField({
    type: 'number',
    label: t('input.label.paddingRight'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  paddingBottom: genField({
    type: 'number',
    label: t('input.label.paddingBottom'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  padding: genField({
    type: 'number',
    label: t('input.label.padding'),
    layout: 'vertical',
    params: { array: OFFSET_ARRAY },
  }),
  margin: genField({
    type: 'number',
    label: t('input.label.padding'),
    layout: 'vertical',
    params: { array: OFFSET_ARRAY },
  }),

  alignItems: genField({
    type: 'radio',
    label: t('input.label.alignment'),
    layout: ['horizontal', 'solid'],
    params: {
      options: VERTICAL_ALIGN_OPTIONS,
      hideRadio: true,
      direction: 'horizontal',
    },
  }),
  justifyContent: genField({
    type: 'radio',
    label: t('input.label.justifyContent'),
    layout: ['horizontal', 'solid'],
    params: {
      options: HORIZONTAL_ALIGN_OPTIONS,
      hideRadio: true,
      direction: 'horizontal',
    },
  }),
  textAlign: genField({
    type: 'radio',
    label: t('input.label.justifyContent'),
    layout: ['horizontal', 'solid'],
    params: {
      options: HORIZONTAL_ALIGN_OPTIONS,
      hideRadio: true,
      direction: 'horizontal',
    },
  }),
  objectFit: genField({
    label: t('input.label.objectFit'),
    type: 'select',
    layout: ['horizontal', 'solid'],
    params: {
      options: OBJECT_FIT_OPTIONS,
      fluid: true,
    },
  }),
  flexDirection: genField({
    label: t('input.label.layout'),
    type: 'segmented',
    layout: 'horizontal',
    params: {
      options: FLEX_DIRECTION_OPTIONS,
    },
  }),
  gap: genField({
    type: 'number',
    label: t('input.label.gap'),
    layout: ['horizontal', 'solid'],
    params: {
      width: 64,
    },
  }),

  fontFamily: genField({
    type: 'select',
    label: t('input.label.font'),
    layout: ['horizontal', 'overflow'],
    useParams: (data, { name }) => {
      const { parent } = getParent<Partial<RichTextFontSchemaType['base']>>(name, data)
      const fontFamily = simplifyStyleValue(parent?.fontFamily)
      const options = useFontFamilyOptions(simplifyStyleValue(fontFamily))
      return {
        fluid: true,
        dropdownType: 'dropdown',
        options,
      }
    },
  }),
  fontSize: genField({
    type: 'select',
    label: t('input.label.fontSize'),
    layout: 'horizontal',
    params: {
      options: FONT_SIZE_OPTIONS,
      inputable: true,
      hideEmptyList: true,
      inputType: 'number',
      minWidth: 64,
    },
  }),
  lineHeight: genField({
    label: t('input.label.lineHeight'),
    layout: ['horizontal', 'solid'],
    type: 'select',
    params: {
      options: LINE_HEIGHT_OPTIONS,
      inputable: true,
      inputType: 'number',
      hideEmptyList: true,
      minWidth: 64,
    },
  }),
  fontWeight: genField({
    type: 'select',
    label: t('input.label.fontWeight'),
    layout: ['horizontal', 'solid'],
    useParams: (data, { name }) => {
      const { parent } = getParent<Partial<RichTextFontSchemaType['base']>>(name, data)
      const fontFamily = simplifyStyleValue(parent?.fontFamily)
      const fontStyle = simplifyStyleValue(parent?.fontStyle)
      const options = useFontWeightOptions(fontFamily, fontStyle)
      return {
        fluid: true,
        options,
      }
    },
  }),
  fontStyle: genField({
    type: 'checkbox',
    label: t('input.label.italic'),
    layout: ['horizontal', 'solid'],
    params: FONT_ITALIC_PARAMS,
  }),
  textDecoration: genField({
    type: 'segmented',
    label: t('input.label.textDecoration'),
    layout: ['horizontal', 'solid'],
    params: {
      options: [
        { value: 'none', label: t('common.no') },
        { value: 'underline', label: t('common.yes') },
      ],
    },
  }),

  borderLeftColor: genField({
    type: 'color',
    label: t('input.label.color'),
    layout: ['horizontal', 'solid'],
    useParams: () => {
      const theme = useBrandTheme()
      const options = useMemo(() => genColorOptions(theme), [theme])
      return {
        labeled: true,
        options,
      }
    },
  }),
  spacing: genField({
    type: 'number',
    label: t('input.label.spacing'),
    layout: ['horizontal', 'solid'],
    params: { width: 64 },
  }),
  hoverColor: genField({
    type: 'color',
    label: t('style.hoverColor'),
    layout: ['horizontal', 'solid'],
    useParams: () => {
      const theme = useBrandTheme()
      const options = useMemo(() => genColorOptions(theme), [theme])
      return {
        transparent: true,
        labeled: true,
        resettable: true,
        options,
      }
    },
  }),
  boxShadow: genField({
    type: 'custom',
    layout: ['horizontal', 'solid'],
    component: BrandCustomShadowField,
    label: t('style.boxShadow'),
    useConfig: (_, config) => {
      const theme = useBrandTheme()
      return { ...config, getValue: R.curry(getThemeValue)(R.__, theme) }
    },
  }),
}

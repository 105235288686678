import { FileUsage, FileUsageImageSource } from '@leenda/editor/lib/files'
import { useCallback } from 'react'
import { FileTypeEnum } from 'utils'

import { IAsyncOnChange } from 'components/controls/Field/Field.types'
import { useFilePickerModal } from 'components/pages/FinderPage'

import { FileTypes } from './FileLoader'

type FieldFileValue = FileUsage | null

type IFieldFile<V> = {
  value: V
  fileType: FileTypes
  onBlur: ((e?: unknown) => void) | undefined
  onFocus: ((e?: unknown) => void) | undefined
  name: string
  onChange: IAsyncOnChange<V>
}

export const useFileHandlers = <V extends FieldFileValue = FieldFileValue>({
  onChange,
  name,
  value,
  onFocus,
  onBlur,
  fileType,
}: IFieldFile<V>) => {
  const filePick = useFilePickerModal({
    defaultContentType: (fileType === 'document' ? undefined : fileType) as FileTypeEnum,
  })

  const handlePickFile = async () => {
    onFocus?.()
    const fileId = await filePick.open({})
    fileId &&
      onChange?.({
        value: { ...value, id: fileId, source: FileUsageImageSource.file },
        name,
      })
    onBlur?.()
  }

  const handleResetFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    value &&
      onChange?.({
        value: {
          source: FileUsageImageSource.file,
        },
        name,
      })
  }

  const handleChangeSource = useCallback(
    (source: FileUsageImageSource) => {
      onChange({
        name,
        value: { source },
      })
    },
    [name, onChange],
  )

  const handleChangeUrl = useCallback(
    (url: string) => onChange({ name, value: { ...value, source: FileUsageImageSource.url, url } }),
    [name, onChange, value],
  )

  return {
    handlePickFile,
    handleResetFile,
    handleChangeSource,
    handleChangeUrl,
  }
}

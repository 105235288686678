import { css } from 'styled-components'

export const outline = css`
  &:focus-visible {
    box-shadow: 0 0 0 2px black;
    outline: white 2px solid;
    outline-offset: 2px;
    box-sizing: border-box;
  }
`

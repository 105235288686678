import { CONFIG_COURSE } from '@leenda/editor/lib/elements'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { useElementStyleCss } from 'services/Branding/hooks'
import { DeviceMode } from 'services/Store/Project/enums'

import s from './Mask.module.scss'

interface IMaskProps {
  deviceMode: DeviceMode
  isCorrect?: boolean
  borderRadius?: string | number
}

const Mask = ({ deviceMode, isCorrect, borderRadius }: IMaskProps) => {
  const stylesTest = useElementStyleCss(CONFIG_COURSE.testAnswerFeedback.type, deviceMode)

  return (
    <div className={s.mask} style={{ borderRadius }}>
      {isCorrect ? (
        stylesTest.correct.backgroundImage ? (
          <div className={s.icon} style={stylesTest.correct} />
        ) : (
          <Icon name='success' size={KitSize.M} />
        )
      ) : stylesTest.incorrect.backgroundImage ? (
        <div className={s.icon} style={stylesTest.incorrect} />
      ) : (
        <Icon name='error' size={KitSize.M} />
      )}
    </div>
  )
}

export default Mask

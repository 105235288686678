import { useLocalStorageState } from 'ahooks'
import cn from 'classnames'
import lodash from 'lodash'
import React, { useEffect, useRef, useState, useMemo } from 'react'

import Modal, { MCWithParams, ModalFooter, ModalHeader } from 'components/uiKit/Modal'
import { ENUM_VALUE_MAP } from 'components/uiKit/SortPopover/constants'
import { SortUiEnum } from 'components/uiKit/SortPopover/types'
import {
  TemplateCollectionCreatedFor,
  EmployeePreferencesTab,
  TemplatesListSchemaFragment,
  EmployeePreferencesSchemaFragment,
  EditorTemplateOrder,
  CollectionOrder,
  EditorTemplateAllTab,
} from 'gql/__generated__/graphql'
import { projectModifyEmployeePreference } from 'gql/projects/cache'
import { useTemplatesGetTemplatesAndCollections } from 'gql/templates/apollo'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import ArchivedBlocksList from './ArchivedBlocksList/ArchivedBlocksList'
import CollectionsList from './CollectionsList'
import HeaderTemplate from './HeaderTemplate'
import { STEPS } from './Stepper/Stepper'
import Stepper from './Stepper/Stepper'
import Tags from './Tags'
import TemplatePickerFooter from './TemplatePickerFooter'
import s from './TemplatePickerModal.module.scss'
import TemplatesList from './TemplatesList'
import { employeePreferencesToOrder, notLandingTab, singleTags } from './helper'
import { useSelected, TemplatePickerContext, useTags, ITemplatePickerContext } from './hooks'
import { UiTemplateTags, TemplatePickerMode, TemplatePickerModalType } from './types'

interface IParams {
  mode?: TemplatePickerMode
  tag?: UiTemplateTags
  type?: SectionTypeEnum
  companyId: string
  projectId?: string
  sectionId?: string
  blocksByMode?: Record<string, boolean>
  modalType: TemplatePickerModalType
  employeePreferences?: Partial<EmployeePreferencesSchemaFragment> | null
  tagFromLocalStorage?: boolean
  showBrand?: boolean
}

const TemplatePickerModal: MCWithParams<IParams, { selected: string[]; tab: string }> = ({
  onClose,
  params: {
    mode: defaultMode = TemplatePickerMode.multi,
    tag: defaultTagProps,
    type,
    companyId,
    projectId = '',
    sectionId,
    blocksByMode,
    modalType,
    employeePreferences,
    tagFromLocalStorage,
    showBrand,
  },
}) => {
  const [tab, setTab] = useState<EmployeePreferencesTab>(
    employeePreferences?.templates?.tab && type !== 'cover'
      ? employeePreferences?.templates?.tab
      : EmployeePreferencesTab.collections,
  )

  const [order, setOrder] = useState<SortUiEnum | null>(
    employeePreferencesToOrder(employeePreferences?.templates?.order || null),
  )
  // TODO: add type on BE
  const employeePreferencesTag = employeePreferences?.templates?.tag as UiTemplateTags

  const [search, setSearch] = useState<string>()

  const initialCollection = employeePreferences?.templates?.editorTemplateGroupId
    ? {
        id: employeePreferences?.templates?.editorTemplateGroupId || '',
        name: employeePreferences?.templates?.editorTemplateGroupName || '',
      }
    : null
  const [collection, setCollection] = useState<{
    id: string
    createdFor?: TemplateCollectionCreatedFor
    name?: string
  } | null>(initialCollection)
  const [templates, setTemplates] = useState<TemplatesListSchemaFragment[]>([])
  const [columns, setColumns] = useLocalStorageState('templateColumn', { defaultValue: 3 })
  const [selectedSteps, setSelectedSteps] = useState<number[]>([])

  const currentStep =
    type === SectionTypeEnum.test && modalType === TemplatePickerModalType.add
      ? STEPS.findIndex((step) => step.value === defaultTagProps) || 0
      : null
  const [step, setStep] = useState<number | null>(currentStep)

  const defaultTag =
    step !== null
      ? STEPS[step]?.value
      : employeePreferencesTag && !notLandingTab.includes(employeePreferencesTag)
        ? employeePreferencesTag
        : defaultTagProps || UiTemplateTags.all

  const { tag, setTag, showTags } = useTags({
    sectionType: type,
    propTag: modalType === TemplatePickerModalType.change ? defaultTagProps : defaultTag,
    tagFromLocalStorage,
  })

  const { selected, setSelected, addItem, clearSelect, removeByIndex } = useSelected()
  const allSelected = lodash.concat(selected.single, selected.multi)

  const hasSingleMode =
    ((type && type === SectionTypeEnum.cover) ||
      (step !== null && singleTags.includes(STEPS[step]?.value))) &&
    defaultMode !== TemplatePickerMode.change

  const mode = hasSingleMode ? TemplatePickerMode.single : defaultMode
  const showStepper = step !== null && modalType === TemplatePickerModalType.add
  const disabledStepper =
    ((tab === EmployeePreferencesTab.collections && !collection?.id) ||
      tab === EmployeePreferencesTab.archived) &&
    type === SectionTypeEnum.test

  const showSidePanel = showTags || (type === SectionTypeEnum.test && !collection?.id)

  const onClick = (value: TemplatesListSchemaFragment, e: React.MouseEvent) => {
    setSelectedSteps((prev) => lodash.uniq([...prev, step as number]))
    if (mode === TemplatePickerMode.single) {
      setSelected((prev) => ({ ...prev, single: [value.uuid] }))
    }
    if (mode === TemplatePickerMode.change) {
      setSelected((prev) => ({ ...prev, single: [value.uuid] }))
    }
    if (mode === TemplatePickerMode.multi) {
      addItem(value, e)
    }
  }

  const onSubmit = async (selected: string[]) => {
    onClose({ selected, tab })
  }

  const templateProps = useMemo(
    () => ({
      selected: selected.multi,
      onClick,
      removeByIndex,
      search,
      companyId,
      projectId,
      tab,
      allSelected,
    }),
    [selected, search, onClick, projectId, allSelected, companyId, removeByIndex, tab],
  )

  const contextValue: ITemplatePickerContext = {
    companyId,
    collectionId: collection?.id,
    collection,
    projectId,
    sectionId,
    setCollection,
    setStep,
    step,
    type,
    mode,
    onClick,
    removeByIndex,
    search,
    setSearch,
    selected,
    setTemplates,
    allSelected,
    blocksByMode,
    tag,
    setTag,
    showTags,
    columns,
    setColumns,
    tab,
    order,
    setOrder,
    showBrand,
  }

  const collectionsMode = useMemo(() => {
    if (type === SectionTypeEnum.landing) {
      return [BlockMode.view, BlockMode.questions]
    }
    if (type === SectionTypeEnum.test) {
      return [BlockMode.questions, BlockMode.start, BlockMode.end]
    }
    if (type === SectionTypeEnum.cover) {
      return [BlockMode.cover]
    }
    return []
  }, [type, tag])

  const { data, loading } = useTemplatesGetTemplatesAndCollections({
    mode: collectionsMode,
    companyId,
    data: {
      projectId,
      mode: collectionsMode,
      query: search,
      tab: EditorTemplateAllTab.collections,
      ...(order && {
        order: {
          field: ENUM_VALUE_MAP[order][0][0],
          value: ENUM_VALUE_MAP[order][0][1],
        } as CollectionOrder,
      }),
    },
  })

  const Content = {
    [EmployeePreferencesTab.collections]: (
      <CollectionsList data={data} loading={loading} templateProps={templateProps} />
    ),
    [EmployeePreferencesTab.archived]: <ArchivedBlocksList />,
    [EmployeePreferencesTab.favorites]: (
      <TemplatesList tab={EmployeePreferencesTab.favorites} templateProps={templateProps} />
    ),
    [EmployeePreferencesTab.recently_used]: (
      <TemplatesList tab={EmployeePreferencesTab.recently_used} templateProps={templateProps} />
    ),
  }

  const lastPreference = useRef(employeePreferences)

  lastPreference.current = {
    templates: {
      editorTemplateGroupId: collection?.id || null,
      editorTemplateGroupName: collection?.name || null,
      tab,
      tag: tag || UiTemplateTags.all,
      ...(order && {
        order: {
          field: ENUM_VALUE_MAP[order][0][0],
          value: ENUM_VALUE_MAP[order][0][1],
        } as EditorTemplateOrder,
      }),
    },
  }

  useEffect(() => {
    const updatePreferences = () => {
      projectModifyEmployeePreference(projectId, lastPreference.current)
    }

    updatePreferences()
    window.addEventListener('beforeunload', updatePreferences)
    return () => {
      updatePreferences()
      window.removeEventListener('beforeunload', updatePreferences)
    }
  }, [projectId, tab, collection, tag])

  useEffect(() => {
    setSearch('')
    if (tab !== EmployeePreferencesTab.collections) {
      setCollection(null)
    }
  }, [collection?.id])

  return (
    <TemplatePickerContext.Provider value={contextValue}>
      <Modal
        minHeight='100%'
        name='pickerTemplate'
        styleType='tabs'
        title={t('modal.pickerTemplate.title')}
      >
        <ModalHeader>
          {type === SectionTypeEnum.test && showStepper && (
            <div className={cn(s.stepper, { [s.disabled]: disabledStepper })}>
              <Stepper selectedSteps={selectedSteps} />
            </div>
          )}
          <HeaderTemplate dataCollections={data} />
        </ModalHeader>
        <div className={cn(s.body, { [s.extraPadding]: type === 'cover' })}>
          {showSidePanel && <Tags setTab={setTab} />}
          <div className={s.content}>
            {collection?.id && tab === EmployeePreferencesTab.collections ? (
              <TemplatesList
                tab={EmployeePreferencesTab.collections}
                templateProps={templateProps}
              />
            ) : (
              Content[tab]
            )}
          </div>
        </div>

        <ModalFooter>
          <TemplatePickerFooter
            clearSelect={clearSelect}
            disabled={tab === EmployeePreferencesTab.archived}
            hideAddAll={tab === EmployeePreferencesTab.collections && !collection?.id}
            onSubmit={onSubmit}
            setSelected={setSelected}
            tab={tab}
            templates={templates}
          />
        </ModalFooter>
      </Modal>
    </TemplatePickerContext.Provider>
  )
}

export default React.memo(TemplatePickerModal)

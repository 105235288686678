import { gql } from 'gql/__generated__'

export const sectionCreate = gql(`
  mutation SectionsCreate($projectId: String!, $data: EditorSectionInput!) {
    data: editorSectionsCreate(projectId: $projectId, data: $data) {
      sectionsOrder {
        ...SectionOrderSchema
      }
      section {
        ...SectionEditorSchema
      }
    }
  }
`)

export const blockOrderUpdate = gql(`
  mutation BlockOrderUpdate($id: String!, $data: EditorSectionUpdateInput!, $projectId: String!) {
    data: editorSectionsUpdateById(id: $id, data: $data, projectId: $projectId) {
      section {
          id
          blocksOrder
      }
    }
  }
`)

export const sectionUpdate = gql(`
  mutation SectionsUpdateById(
    $id: String!
    $data: EditorSectionUpdateInput!
    $projectId: String!
    $dragId: String
  ) {
    data: editorSectionsUpdateById(id: $id, data: $data, projectId: $projectId, dragId: $dragId) {
      sectionsOrder {
        ...SectionOrderSchema
      }
      section {
        ...SectionEditorSchema
      }
    }
  }
`)

export const sectionsUpdateByIds = gql(`
  mutation SectionsUpdateByIds(
    $ids: [ID!]!
    $data: EditorSectionUpdateManyInput!
    $projectId: String!
  ) {
    data: editorSectionsUpdateByIds(ids: $ids, data: $data, projectId: $projectId) {
      ...SectionEditorSchema
    }
  }
`)

export const sectionUpdateHide = gql(`
  mutation SectionsUpdateHideById($id: String!, $isHide: Boolean!) {
    editorSectionUpdateHide(id: $id, isHide: $isHide) {
      data {
        ...SectionEditorSchema
      }
    }
  }
`)

export const sectionsDeleteByIds = gql(`
  mutation SectionsDeleteByIds($ids: [String!]!, $projectId: String!) {
    data: editorSectionsDeleteByIds(ids: $ids, projectId: $projectId) {
      sectionIdsDeleted
      sectionsOrder {
        ...SectionOrderSchema
      }
    }
  }
`)

export const sectionDuplicate = gql(`
  mutation SectionsDuplicate($companyId: ID!, $ids: [ID!]!) {
    data: editorSectionsDuplicate(companyId: $companyId, ids: $ids) {
      data {
        section {
          ...SectionEditorSchema
        }
        sectionsOrder {
          ...SectionOrderSchema
        }
      }
    }
  }
`)

export const sectionsTransfer = gql(`
  mutation SectionsTransfer($projectId: ID!, $payload: SectionsTransferPayload!) {
    data: editorSectionsTransfer(projectId: $projectId, payload: $payload) {
      data {
        sectionIds
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)



import { SCHEMA } from '@leenda/editor/lib/brand'
import { getParent } from 'utils'

import { genField } from 'components/controls/Field'
import { IFieldConfig } from 'components/controls/Field/Field.types'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.splitter.style, {
  border: {
    META: { label: t('elements.splitter.tags.base') },
    borderRadius: {
      params: { min: 0, max: 50 },
    },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 50 } },
  },

  icon: {
    META: { label: t('elements.splitter.tags.splitter') },
    inversion: genField({
      layout: ['horizontal', 'solid'],
      label: t('input.label.showIcon'),
      type: 'segmented',
    }),
    backgroundImage: {
      label: t('elements.splitter.schema.sliderIcon'),
      layout: 'vertical',
      params: (block: unknown, config: IFieldConfig) => {
        const { parent } = getParent<{ inversion?: boolean }>(config.name, block)
        return { hidden: parent?.inversion === false, preview: false }
      },
    },
    backgroundColor: {},
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  splitter: {
    META: { hidden: true },
    borderColor: {},
    width: genField({
      type: 'slider',
      label: t('input.label.borderWidth'),
      layout: 'horizontal',
      params: { showInput: true, min: 1, max: 50 },
    }),
  },
  textLeft: {
    META: { label: t('elements.splitter.tags.textLeft') },
    alignItems: {},
  },
  textRight: {
    META: { label: t('elements.splitter.tags.textRight') },
    alignItems: {},
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.splitter.schema.boxShadow') },
  },
  indents: {
    META: { label: t('elements.splitter.tags.indents') },
    padding: {
      label: t('elements.splitter.schema.innerSlideIndents'),
      layout: 'vertical',
      params: { min: 0 },
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.splitter.font, FONT_SCHEMA_FIELDS)

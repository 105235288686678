import { RectangleSchemaType } from '@leenda/editor/lib/brand'
import { textToRtValue } from '@leenda/rich-text'

import { RichTextViewer } from 'components/form/RichText/RichTextViewer'
import { useFontCss } from 'services/Branding/hooks'
import { DeviceMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'

const RectangleDemo: React.FC<IElementDemoProps<RectangleSchemaType>> = ({ styles }) => {
  const fontStyles = useFontCss(DeviceMode.desktop)
  const rootStyles = useElementRootStyles(
    styles.root,
    { width: 470, padding: '100px 0', textAlign: 'center' },
    styles.rectangle,
    styles.border,
    styles.effects,
  )
  return (
    <DemoWrapper center>
      {/* RUDE SHIT FIX */}
      <div style={rootStyles}>
        <RichTextViewer
          styles={fontStyles}
          value={textToRtValue(t('elements.rectangle.label.demo'))}
        />
      </div>
      {/* <Element
      element={{
        id: 'rectangle-demo',
        type: CONFIG_ELEMENTS.rectangle.type,
        value: {},
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={{ ...styles, root: { width: 470, height: 470 } }}
    /> */}
    </DemoWrapper>
  )
}
export default RectangleDemo

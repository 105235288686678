import { gql } from 'gql/__generated__'

export const fileMetaPreview = gql(`
  query FileMetaPreview(
    $companyId: ID!
    $parentId: ID!
    $fileId: ID!
    $search: FileMetaGroupsSearch!
  ) {
    data: fileMetaPreview(
      companyId: $companyId
      parentId: $parentId
      fileId: $fileId
      search: $search
    ) {
      fileMeta {
        ...FileMetaSchema
      }
      previousFileMetaId
      nextFileMetaId
      fileMetaIndex
      count
    }
  }
`)

export const getFileUrlInfoQuery = gql(`
  query FileUrlInfo($url: String!) {
    data: fileUrlInfo(url: $url) {
      payload {
        url
        contentType
        mediaType
        title
        siteName
        description
        images
        favicons
      }
      errors {
        url
        message
      }
    }
  }
`)

export const fileMetaGroupAllQuery = gql(`
  query FileMetaGroupAll($companyId: String!, $search: FileMetaGroupsSearch, $parentId: String) {
    breadcrumb: fileMetaGroupGetById(companyId: $companyId, id: $parentId) {
      # ...FilesGroups
      id
      name
      breadcrumbs {
        # ...FilesGroups
        id
        name
      }
    }
    data: fileMetaGroupAll(companyId: $companyId, parentId: $parentId, search: $search) {
      groups {
        ...FilesGroups
      }
      fileMetas {
        ...FileMetaSchema
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const fileMetaAllQuery = gql(`
  query FileMetaAll($companyId: String!, $search: FileMetaGroupsSearch, $parentId: String) {
    breadcrumb: fileMetaGroupGetById(companyId: $companyId, id: $parentId) {
      # ...FilesGroups
      id
      name
      breadcrumbs {
        # ...FilesGroups
        id
        name
      }
    }
    data: fileMetaGroupAll(companyId: $companyId, parentId: $parentId, search: $search) {
      fileMetas {
        ...FileMetaSchema
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const fileGroupAllQuery = gql(`
  query FileGroupAll($companyId: String!, $search: FileMetaGroupsSearch, $parentId: String) {
    breadcrumb: fileMetaGroupGetById(companyId: $companyId, id: $parentId) {
      # ...FilesGroups
      id
      name
      breadcrumbs {
        # ...FilesGroups
        id
        name
      }
    }
    data: fileMetaGroupAll(companyId: $companyId, parentId: $parentId, search: $search) {
      groups {
        ...FilesGroups
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const unsplashExternalImages = gql(`
  query UnsplashExternalImages($payload: UnsplashExternalImagesPayload!) {
    data: unsplashExternalImages(payload: $payload) {
      ...UnsplashExternalImages
    }
  }
`)

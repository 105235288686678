import saveAs from 'file-saver'
import { useCallback, useMemo } from 'react'

import { IMenuOption } from 'components/uiKit/Menu'
import { ExternalImage } from 'gql/__generated__/graphql'
import { useUnsplashTrackDownloads } from 'gql/files/apollo'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

import { SelectedLabel } from '../../SelectedLabel'
import { UnsplashTableActionUiEnum } from './types'

interface IUseTableMenu {
  selected: ExternalImage[]
  setSelected: (selected: ExternalImage[]) => void
  disabled?: boolean
  handleClickAddFileMeta: (items: ExternalImage[]) => void
  disableUpload: boolean
}

const getTableActions = (selected: ExternalImage[], disableUpload: boolean, disabled?: boolean) => [
  getEnumOption('UnsplashTableActionUiEnum', UnsplashTableActionUiEnum.DOWNLOAD, {
    disabled,
  }),
  getEnumOption('UnsplashTableActionUiEnum', UnsplashTableActionUiEnum.ADD, {
    disabled: disabled || disableUpload,
  }),
  // TODO: think about refactoring this or simplify
  getEnumOption('UnsplashTableActionUiEnum', UnsplashTableActionUiEnum.RESET, {
    disabled,
    label: <SelectedLabel selected={selected} />,
  }),
]

export const useTableMenu = ({
  selected,
  setSelected,
  disabled,
  handleClickAddFileMeta,
  disableUpload,
}: IUseTableMenu) => {
  const [unsplashTrackDownload] = useUnsplashTrackDownloads()
  const tableActions = useMemo(
    () => getTableActions(selected, disableUpload, disabled),
    [disabled, selected, disableUpload],
  )

  const downloadFile = useCallback(() => {
    selected.forEach((file) => {
      if (file.urls?.raw) {
        const url = new URL(file.urls?.raw)
        url.searchParams.set('w', '700')
        saveAs(url.toString(), `${t('page.finder.tabs.unsplash')}_${file.name}`)
      }
    })
  }, [selected])

  const onClickTableMenu = useCallback(
    async ({ value }: IMenuOption<UnsplashTableActionUiEnum>) => {
      if (value === UnsplashTableActionUiEnum.DOWNLOAD) {
        downloadFile()
        await unsplashTrackDownload({ variables: { ids: selected.map(({ id }) => id) } })
        setSelected([])
      }
      if (value === UnsplashTableActionUiEnum.ADD) {
        await handleClickAddFileMeta(selected)
      }
      if (value === UnsplashTableActionUiEnum.RESET) {
        setSelected([])
      }
    },
    [downloadFile, handleClickAddFileMeta, selected, setSelected, unsplashTrackDownload],
  )
  return { tableActions, onClickTableMenu }
}

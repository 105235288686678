import cn from 'classnames'
import React, { useCallback } from 'react'

import ScaledBlockViewport from 'components/editor-v3/cource/renderEntry/ScaledBlockViewport'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import DropdownMenu from 'components/uiKit/DropdownMenu/DropdownMenu'
import Highlighter from 'components/uiKit/Highlighter'
import { KitSize } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import {
  EditorTemplateAllTab,
  EmployeePreferencesTab,
  TemplatesListSchemaFragment,
} from 'gql/__generated__/graphql'
import { TemplateActionsUiEnum } from 'routes/routes/App/routes/Company/routes/Templates/components/enum'
import { BlockMode, DeviceMode } from 'services/Store/Project/enums'
import { Block } from 'services/Store/Project/types'
import { getEnumLabel } from 'utils/enum'
import { testProps, testPropsEl } from 'utils/test/qaData'

import FavoriteIcon from '../FavoriteIcon'
import { IItemProps } from '../MasonryTemplate/MasonryTemplate'
import Numeration from '../Numeration'
import { TemplatePickerMode } from '../types'
import s from './CellRendererBlock.module.scss'

interface ICellRendererBlockProps extends Omit<IItemProps, 'onClick' | 'tab'> {
  template: TemplatesListSchemaFragment
  index: number
  onHeight?: (id: string, height: number) => void
  width?: number
  mode: TemplatePickerMode
  tab: EmployeePreferencesTab | EditorTemplateAllTab
  actions?: IMenuOption<TemplateActionsUiEnum>[]
  onClickActions?: (value: string, uuid: string) => void
  companyId: string
  projectId?: string
  showFavorite?: boolean
  allSelected?: string[]
  testData?: string
}

const DEFAULT_WIDTH = 280

const CellRendererBlock: React.FC<ICellRendererBlockProps> = ({
  template,
  search,
  index,
  onHeight,
  selected,
  mode,
  removeByIndex,
  width = DEFAULT_WIDTH,
  tab,
  actions,
  onClickActions,
  companyId,
  projectId,
  showFavorite,
  allSelected,
  testData,
}) => {
  const { uuid, name, isFavorite } = template

  const onHeightCb = useCallback(
    (blockHeight: number) => onHeight && onHeight(uuid, blockHeight + 70),
    [onHeight, uuid],
  )
  const isSelected = Boolean(allSelected?.find((id) => id === uuid))
  const rootCn = cn(s.root, {
    [s.withActions]: actions,
    [s.selected]: isSelected,
  })
  return (
    <div className={rootCn} {...testProps({ el: 'templateCard', name, testData })}>
      <div className={cn(s.card, { [s.active]: isSelected })}>
        <div className={s.bottomGroup}>
          <Tooltip overlay={name} fluid>
            <div className={s.header}>
              {mode === TemplatePickerMode.multi && (
                <Numeration removeByIndex={removeByIndex} selected={selected} uuid={uuid} />
              )}
              <div
                {...testPropsEl('blockCardTitle', { value: uuid }, { index })}
                className={s.blockTitle}
              >
                <Highlighter
                  search={search}
                  text={name || getEnumLabel('BlockMode', BlockMode.view)}
                />
              </div>
            </div>
          </Tooltip>
          {actions && (
            <div className={s.actions}>
              <DropdownMenu
                name={`${name}.actionsDropdownMenu`}
                onClick={({ value }) => onClickActions?.(value, uuid)}
                options={actions}
                placement='bottomRight'
              >
                <IconButton
                  icon='iconsOtherMore'
                  name={`${name}.actions`}
                  size={KitSize.S}
                  styleType='ghost'
                />
              </DropdownMenu>
            </div>
          )}
        </div>
        <div className={s.blockWrapper}>
          <div {...testPropsEl('blockCardContent', { value: uuid, name }, { index })}>
            <ScaledBlockViewport
              block={template as unknown as Block}
              deviceMode={DeviceMode.desktop}
              onHeight={onHeightCb}
              viewportSize={width - 55}
            />
          </div>
        </div>
        {showFavorite && (
          <div className={cn(s.favIcon, { [s.favIconSelected]: isFavorite })}>
            <FavoriteIcon
              companyId={companyId}
              projectId={projectId}
              tab={tab}
              template={template}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CellRendererBlock

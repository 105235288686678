import { GroupingItem, GroupingElementValue, GroupingGroup } from '@leenda/editor/lib/elements'
import { FileUsageImageSource } from '@leenda/editor/lib/files'
import { generateId } from '@leenda/editor/lib/utils/id'
import lodash from 'lodash'
import { useMemo } from 'react'

import { genDataField } from 'components/controls/Field'
import { IOnChangeData } from 'components/controls/Field/Field.types'
import RealtimeField from 'components/controls/RealtimeField'
import Collapse from 'components/uiKit/Collapse'
import { KitSize } from 'components/uiKit/KitTypes'
import { Typography } from 'components/uiKit/Typography'
import { DEFAULT_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { FULL_COLLAPSE_PADDING } from 'constants/styles'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import { GroupingValueFormType } from './GroupingElement.types'

const genField = genDataField<Block>()

const onAddNumberedGroup = (changeData: IOnChangeData<unknown>) => {
  const dataValue = lodash.clone(changeData.value) as GroupingGroup[]
  const value = changeData.value as GroupingGroup[]
  const newItem = {
    items: [],
    value: generateId(),
    target: {
      image: {
        source: FileUsageImageSource.file,
        id: '',
        accessibility: '',
      },
      overlay: '#FFFFFF00',
      label: `${t('elements.grouping.tags.group')} #${value.length + 1}`,
    },
  }
  dataValue.push(newItem)
  return dataValue
}

const onAddNumberedAnswer = (changeData: IOnChangeData<unknown>) => {
  const dataValue = lodash.clone(changeData.value) as GroupingItem[]
  const value = changeData.value as GroupingItem[]
  const newItem = {
    label: `${t('elements.grouping.form.answer')} #${value.length + 1}`,
    value: generateId(),
    image: {
      source: FileUsageImageSource.file,
      id: '',
      accessibility: '',
    },
    overlay: '#FFFFFF00',
  }
  dataValue.push(newItem)
  return dataValue
}

const hasGroupImage = genField({
  name: 'hasGroupImage',
  type: 'segmented',
  label: t('input.label.group'),
  defaultValue: false,
  layout: 'horizontal',
})

const hasAnswerImage = genField({
  name: 'hasAnswerImage',
  type: 'segmented',
  label: t('elements.grouping.form.answer'),
  defaultValue: false,
  layout: 'horizontal',
})

const groupLabel = genField({
  name: 'target.label',
  type: 'text',
  label: t('input.label.name'),
  params: {
    placeholder: t('input.label.group'),
  },
})

const groupImage = genField({
  name: 'target.image',
  type: 'file',
  params: (block, { name }) => {
    const { parent } = getParent<GroupingElementValue>(name, block, 3)
    return {
      fileType: 'image',
      hidden: !parent?.hasGroupImage,
      nullable: true,
      preview: true,
      showSource: true,
    }
  },
})

export const groupOverlay = genField({
  name: 'target.overlay',
  type: 'color',
  label: t('input.label.overlay'),
  layout: 'horizontal',
  useParams: (block, { name }) => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    const { parent } = getParent<GroupingElementValue>(name, block, 3)
    return {
      options,
      labeled: true,
      hidden: !parent?.hasGroupImage,
    }
  },
})

const itemLabel = genField({
  name: 'label',
  type: 'text',
  params: {
    size: KitSize.S,
    placeholder: t('elements.grouping.form.answer'),
  },
})

const itemImage = genField({
  name: 'image',
  type: 'file',
  params: (block, { name }) => {
    const { parent } = getParent<GroupingElementValue>(name, block, 3)
    return {
      fileType: 'image',
      hidden: !parent?.hasAnswerImage,
      nullable: true,
      preview: true,
      showSource: true,
    }
  },
})

export const itemOverlay = genField({
  name: 'overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: (block, { name }) => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    const { parent } = getParent<GroupingElementValue>(name, block, 3)
    return {
      options,
      labeled: true,
      hidden: !parent?.hasAnswerImage,
    }
  },
})

const items = genField({
  name: 'items',
  type: 'array',
  fields: [itemLabel, itemImage, itemOverlay],
  defaultValue: { label: t('elements.grouping.form.answer'), value: generateId() },
  params: {
    itemLabel: t('elements.grouping.form.answer'),
    styleType: 'collapse',
    min: 1,
    max: 5,
    onAdd: onAddNumberedAnswer,
  },
})

const groups = genField({
  name: 'items',
  type: 'array',
  fields: [groupLabel, groupImage, groupOverlay, items],
  defaultValue: {
    value: generateId(),
    target: { label: t('input.label.group'), image: null },
    items: [],
  } as any,
  params: {
    itemLabel: t('input.label.group'),
    ordering: true,
    styleType: 'hovered',
    min: 2,
    max: 4,
    onAdd: onAddNumberedGroup,
  },
})

const GroupingForm: GroupingValueFormType = ({ name }) => (
  <>
    <Collapse
      header={t('elements.grouping.form.settings')}
      iconPosition='right'
      name='settings'
      paddings={FULL_COLLAPSE_PADDING}
      initialOpen
    >
      <RealtimeField config={chainName(name, hasGroupImage)} />
      <RealtimeField config={chainName(name, hasAnswerImage)} />
    </Collapse>
    <Collapse
      header={t('elements.grouping.form.groups')}
      iconPosition='right'
      name='groups'
      paddings={FULL_COLLAPSE_PADDING}
      initialOpen
    >
      <Typography indents={DEFAULT_BOTTOM_INDENT} styleType='hint'>
        {t('elements.grouping.form.groupHint')}
      </Typography>
      <RealtimeField config={chainName(name, groups)} />
    </Collapse>
  </>
)

export default GroupingForm

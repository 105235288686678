import { TestRankingSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { border } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

const TestRankingItemStyled = styled.div<{
  $styles: ElementStyleCss<TestRankingSchemaType>
  $isDragging: boolean
}>`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: visible;

  padding: ${({ $styles }) => $styles.offset.padding};
  ${({ $styles }) => border($styles.border)}
  background-color: ${({ $styles }) => $styles?.item?.backgroundColor};
  box-shadow: ${({ $styles }) => $styles?.effects?.boxShadow};

  ${({ $isDragging, $styles }) =>
    $isDragging &&
    css`
      background-color: ${$styles.item_hover.backgroundColor};
    `}

  &:hover {
    background-color: ${({ $styles }) => $styles.item_hover.backgroundColor};
  }
`

export default TestRankingItemStyled

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KEYS = exports.THEME = void 0;
const R = __importStar(require("ramda"));
const utils_1 = require("./utils");
exports.THEME = {
    primaryColor: 'rgba(55, 215, 29, 1)',
    secondaryColor: 'rgba(55, 215, 29, 1)',
    hoverColor: 'rgba(240, 240, 240, 1)',
    focusedColor: 'rgba(29, 27, 27, 1)',
    inactiveColor: 'rgba(212, 212, 212, 1)',
    neutralColor: 'rgba(235, 235, 235, 1)',
    borderColor: 'rgba(230, 230, 230, 1)',
    primaryTextColor: 'rgba(27, 27, 27, 1)',
    secondaryTextColor: 'rgba(117, 117, 117, 1)',
    lightTextColor: '#FFFFFF',
    accentTextColor: 'rgba(55, 215, 29, 1)',
    primaryBackgroundColor: 'rgba(249, 249, 249, 1)',
    darkBackgroundColor: 'rgba(27, 27, 27, 1)',
    lightBackgroundColor: 'rgba(235, 235, 235, 1)',
    accentBackgroundColor: 'rgba(55, 215, 29, 1)',
    infoColor: '#DBD9FF',
    successColor: 'rgba(216, 255, 202, 1)',
    errorColor: 'rgba(255, 227, 229, 1)',
    warningColor: '#FFB88E',
    logoHeader: null,
    borderRadius: 4,
    borderStyle: 'solid',
    boxShadow: JSON.stringify({ preset: 'none', size: 0, color: (0, utils_1.setThemeVariable)('borderColor') }),
};
exports.KEYS = R.mapObjIndexed((_value, key) => key, exports.THEME);

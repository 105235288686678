import { gql } from 'gql/__generated__'

export const templatesGetAllQuery = gql(`
  query TemplatesAll($companyId: String!, $data: EditorTemplateAllInput!) {
    data: editorTemplatesAll(companyId: $companyId, data: $data) {
      templates {
        ...TemplatesListSchema
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`)

export const templatesGetGroupAll = gql(`
  query EditorTemplateCollections(
    $companyId: ID!
    $data: TemplateCollectionSearch!
    $mode: [String!]
  ) {
    data: templateCollections(companyId: $companyId, search: $data) {
      templateCollections {
        ...TemplateCollectionsSchemaCount
      }
    }
  }
`)

export const templatesGetTemplatesAndCollections = gql(`
  query EditorTemplatesAndCollections(
    $companyId: ID!
    $data: TemplatesAndCollectionsSearch!
    $mode: [String!]
  ) {
    data: templatesAndCollections(companyId: $companyId, search: $data) {
      templateCollections {
        ...TemplateCollectionsSchemaCount
      }
      templates {
        ...TemplatesListSchema
      }
    }
  }
`)

export const editorTemplatesAllTotal = gql(`
  query EditorTemplatesAllTotal($search: EditorTemplatesAllTotalInput!) {
    data: editorTemplatesAllTotal(search: $search) {
      favorites
      archived
    }
  }
`)

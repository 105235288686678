import { TestInfoStartFontSchemaType, TestInfoStartSchemaType } from '@leenda/editor/lib/brand'
import { FileImageUsage } from '@leenda/editor/lib/files'
import cn from 'classnames'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useImageWithCrop } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import s from './TestInfoItem.module.scss'
import './TestInfoItem.scss'

type TestInfoItemProps = {
  item?: string
  text?: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | null
  show: boolean
  testInfoItemVariable?: string
  image: Omit<FileImageUsage, 'type'> | null
  styles: ElementStyleCss<TestInfoStartSchemaType>
  font?: ElementFontCss<TestInfoStartFontSchemaType>
}

const TestInfoItem = ({
  item,
  text,
  icon,
  show,
  testInfoItemVariable,
  image: imageProps,
  styles,
  font,
}: TestInfoItemProps) => {
  const Icon = icon || null

  const image = useImageWithCrop(imageProps)
  const customImage = image?.path || imageProps?.url

  return (
    <li key={item}>
      <div
        className={cn(`test-info-item test-info-item--isVisible--${show}`)}
        {...testProps({ el: 'testInfoItem', name: item, val: testInfoItemVariable })}
      >
        {customImage && (
          <div className={s.item}>
            <img
              alt='test status image'
              className='test-info-item__img'
              src={image?.path || imageProps?.url}
              style={{ ...styles.circle, ...styles.effects }}
            />
          </div>
        )}
        {!customImage && Icon && (
          <div className='test-info-item__icon' style={{ ...styles.circle, ...styles.effects }}>
            <Icon />
          </div>
        )}

        <span className='test-info-item__text' style={font?.base}>
          {text} - {testInfoItemVariable}
        </span>
      </div>
    </li>
  )
}

export { TestInfoItem }

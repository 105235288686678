"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const id_1 = require("../../../utils/id");
exports.TYPE = 'chart';
exports.DEFAULT_VALUE = {
    type: 'line',
    description: true,
    items: [
        {
            value: (0, id_1.generateId)(),
            label: 'Item',
            val: 10,
            color: 'var(--brand-primaryColor)',
        },
        {
            value: (0, id_1.generateId)(),
            label: 'Item',
            val: 20,
            color: 'var(--brand-primaryColor)',
        },
        {
            value: (0, id_1.generateId)(),
            label: 'Item',
            val: 15,
            color: 'var(--brand-primaryColor)',
        },
    ],
};

import { Block } from '@leenda/editor'
import { useEffect, useMemo } from 'react'

import { getPath } from 'components/editor-v3/context/EditorContext/selectors/block'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getActiveNodeId } from 'services/Store/Project/selectors'

const useSetActiveItemElement = <V extends { value: string }[]>(
  items: V,
  block: Block,
  setActiveItem: (value: string) => void,
) => {
  const nodeId = useProjectContext(getActiveNodeId)
  const path = useMemo(() => (nodeId ? getPath(block!, nodeId) : null), [block, nodeId])
  const activeKey = path?.find((id) => items.some(({ value }) => id === value))

  useEffect(() => {
    activeKey && setActiveItem(activeKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])
}

export default useSetActiveItemElement

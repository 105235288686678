import { IconsAlignEnum } from '@leenda/editor/lib/brand'
import { ScalingCardEnum } from '@leenda/editor/lib/elements'

import { CardSidesEnum } from './enum'

export const OFFSET_ICON = 16

export const STYLES_SIDE_KEYS = {
  [CardSidesEnum.FRONT]: 'sideA',
  [CardSidesEnum.BACK]: 'sideB',
} as const

export const STYLES_ICON_SIDE_KEYS = {
  [CardSidesEnum.FRONT]: 'sideAIcon',
  [CardSidesEnum.BACK]: 'sideBIcon',
} as const

export const getCardsCongig = (isBack: boolean) => [
  { side: CardSidesEnum.FRONT, active: !isBack },
  { side: CardSidesEnum.BACK, active: isBack },
]

export const getObjectFitDivMap = (sideValues: ScalingCardEnum) =>
  ({
    [ScalingCardEnum.fill]: '100% 100%' as const,
    [ScalingCardEnum.fit]: 'contain' as const,
    [ScalingCardEnum.stretch]: 'cover' as const,
  })[sideValues]

export const getPositionIcon = (iconPosition: IconsAlignEnum) => {
  switch (iconPosition) {
    case IconsAlignEnum.hide:
      return { display: 'none' }
    case IconsAlignEnum.leftUp:
      return { left: OFFSET_ICON, top: OFFSET_ICON, right: 'auto', bottom: 'auto' }
    case IconsAlignEnum.rightUp:
      return { right: OFFSET_ICON, top: OFFSET_ICON, left: 'auto', bottom: 'auto' }
    case IconsAlignEnum.leftDown:
      return { left: OFFSET_ICON, bottom: OFFSET_ICON, right: 'auto', top: 'auto' }
    case IconsAlignEnum.rightDown:
      return { right: OFFSET_ICON, bottom: OFFSET_ICON, left: 'auto', top: 'auto' }
    default:
      return { left: OFFSET_ICON, top: OFFSET_ICON, right: 'auto', bottom: 'auto' }
  }
}

export const getBookAnimationConfig = (isBack: boolean) => [
  { side: CardSidesEnum.FRONT, active: !isBack, className: 'left' },
  { side: CardSidesEnum.FRONT, active: !isBack, className: 'right' },
  { side: CardSidesEnum.BACK, active: isBack, className: 'leftFull' },
  { side: CardSidesEnum.BACK, active: isBack, className: 'rightFull' },
]

import { gql } from 'gql/__generated__'

export const projectGroupsByIdQuery = gql(`
  query ProjectGroupsGetById($groupId: String!, $companyId: String!) {
    data: projectGroupsGetById(companyId: $companyId, groupId: $groupId) {
      ...ProjectGroupSchema
    }
  }
`)

export const projectGroupAll = gql(`
  query ProjectGroupsAll(
    $companyId: String!
    $parentId: String
    $search: ProjectGroupsSearch
    $deep: Boolean
  ) {
    breadcrumb: projectGroupsGetById(companyId: $companyId, groupId: $parentId) {
      ...ProjectGroupSchema
    }
    data: projectAndGroupAll(
      companyId: $companyId
      search: $search
      parentId: $parentId
      deep: $deep
    ) {
      projects {
        ...ProjectListSchema
      }
      groups {
        ...ProjectGroupListSchema
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const dashboardProjects = gql(`
  query DashboardProjects($companyId: String!, $search: DashboardProjectsSearch!) {
    data: dashboardProjects(companyId: $companyId, search: $search) {
      projects {
        ...DashboardProject
      }
      pagination {
        total
      }
    }
  }
`)


export const projectAndGroupTrash = gql(`
  query ProjectAndGroupTrash( $companyId: String!
    $parentId: String
    $search: ProjectGroupsSearch
    $deep: Boolean) {
    data: projectAndGroupTrash( companyId: $companyId
      search: $search
      parentId: $parentId
      deep: $deep) {
      projects {
        ...ProjectListSchema
      }
      groups {
        ...ProjectGroupListSchema
      }
      limit
      offset
      total
    }
  }
`)

export const projectAndGroupTrashCount = gql(`
  query ProjectAndGroupTrashCount($companyId: String!) {
    data: projectTrashCount(companyId: $companyId) {
      count
    }
  }
`)





import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.courseProgress.style, {
  chartEmpty: {
    META: { label: t('elements.courseProgress.tags.progress') },
    color: { label: t('input.label.progressBackground') },
  },
  chartFill: {
    META: { hidden: true },
    color: { label: t('input.label.progressCompleted') },
  },
  progress__var: {
    META: { hidden: true },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
    gap: { label: t('elements.courseProgress.schema.indents') },
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.courseProgress.schema.boxShadow') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.courseProgress.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.courseProgress.tags.text') },
  },
})

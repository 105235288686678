import { ImageSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, ImageElementValue } from '@leenda/editor/lib/elements'
import { FileUsageImageSource } from '@leenda/editor/lib/files'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './ImageElView'
import imageDemo from './assets/imageDemo.svg'

const DEMO_VALUE: ImageElementValue = {
  image: {
    source: FileUsageImageSource.url,
    id: 'demo',
    url: imageDemo,
  },
  overlay: '#FFFFFF00',
}

const ImageDemo: React.FC<IElementDemoProps<ImageSchemaType>> = ({ styles }) => {
  return (
    <DemoWrapper center>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.image.type}-demo`,
          type: CONFIG_ELEMENTS.image.type,
          value: DEMO_VALUE,
          style: {},
          font: {},
        }}
        font={{}}
        mode={DEMO_MODE}
        styles={{ ...styles, root: { ...styles.root, padding: 200 } }} // rude hack to make image bigger
      />
    </DemoWrapper>
  )
}

export default ImageDemo

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Accordion/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    collapse: {
        type: (0, genStyle_1.genStyle)({ value: 'splitted' }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
    hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    select: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
    divider: {
        displayDivider: (0, genStyle_1.genStyle)({ value: 'inner' }),
        borderTopColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderTopWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    icon: {
        position: (0, genStyle_1.genStyle)({ value: 'left' }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryTextColor }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
        backgroundOpen: (0, genStyle_1.genStyle)({ value: null }),
    },
    border: {
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    effects: {
        boxShadow: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.boxShadow }),
    },
    indents: {
        accordion: (0, genStyle_1.genStyle)({ value: [20, 20, 20, 16] }),
        content: (0, genStyle_1.genStyle)({ value: [0, 20, 20, 52] }),
        gap: (0, genStyle_1.genStyle)({ value: [8] }),
        icon: (0, genStyle_1.genStyle)({ value: [16, 16] }),
    },
};
const fontSchema = Object.assign({}, font_1.FONT_SCHEMA_EMPTY);
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };

import { gql } from 'gql/__generated__'

export const fileObjFragment = gql(`
  fragment FileSchema on File {
    id
    metaId
    origin
    path
    ext
    size
    width
    height
    type
    key
    fileMeta {
      name
    }
  }
`)

export const fileFragment = gql(`
  fragment FileMetaSchema on FileMeta {
    id
    origin
    files {
      ...FileSchema
    }
    status
    name
    type
    size
    isArchived
    isProcessed
    createdAt
    updatedAt
    createdBy {
      id
    }
  }
`)

export const filesGroupsFragment = gql(`
  fragment FilesGroups on FileMetaGroup {
    id
    name
    color
    parentId
    lvl
    isArchived
    companyId
    createdAt
    updatedAt
    createdFor
    size
    createdBy {
      id
    }
  }
`)

export const ExternalImageFragment = gql(`
  fragment ExternalImage on ExternalImage {
    id
    name
    width
    height
    urls {
      raw
    }
    user {
      name
      username
    }
  }
`)

export const UnsplashExternalImagesFragment = gql(`
  fragment UnsplashExternalImages on UnsplashExternalImages {
   total
    limit
    offset
    externalImages {
      ...ExternalImage
    }
    }
`)

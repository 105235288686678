import { gql } from 'gql/__generated__'

export const testFragment = gql(`
  fragment TestSchema on EditorSectionTest {
    time
    testLimit
    testRetryCount
    testThreshold
    isNextOnSubmit
    isValidationVisible
    isResultValuePercent
    questionOrder
    isShuffleQuestions
    isProgressShown
    randomCount
    isUnableToExit
  }
`)

export const sectionFragment = gql(`
  fragment SectionSchema on EditorSection {
    id
    name
    description
    type
    blocksOrder
    deadline
    blocks {
      uuid
      mode
      isHide
      name
      mode
    }
    test {
      ...TestSchema
    }
    lvl
    isChapter
    updatedAt
    publicLink {
      ...PublicLinkSchema
    }
    isDone
    isHide
    tags
  }
`)

export const sectionWithBlocksDeepFragment = gql(`
  fragment SectionWithBlocksSchema on EditorSection {
    ...SectionSchema
    blocks {
      ...BlockDeepSchema
    }
  }
`)

export const sectionOrderFragment = gql(`
  fragment SectionOrderSchema on EditorSectionOrderOptionalType {
    id
    lvl
  }
`)

export const sectionEditorFragment = gql(`
  fragment SectionEditorSchema on EditorSection {
    ...SectionSchema
    isDone
    isHide
    projectId
    deadline
    totalCost
    deletedBy
    formats {
      id
      cost
      unitType
      count
      formatId
    }
    tags
    isArchived
    createdAt
    publicLink {
      ...PublicLinkSchema
    }
  }
`)

import { NodeType } from '@leenda/editor'
import { AccordionFontSchemaType, AccordionSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, AccordionElementValue } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import { useState } from 'react'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { BlockMode } from 'services/Store/Project/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import { AccordionState } from './Accordion.types'
import Element from './AccordionElement'

const BLOCK_MOCK: Block = {
  uuid: 'mock-block',
  mode: BlockMode.view,
  name: 'mock-block',
  isHide: false,
  isDone: false,
  sectionId: 'section-mock',
  createdAt: '2021-09-01T00:00:00.000Z',
  updatedAt: '2021-09-01T00:00:00.000Z',
  tasksCount: 0,
  publicLink: { isOpen: false, shortLink: '' },
  files: [],
  elements: {
    'mock-element-1': {
      id: 'mock-element-1',
      type: 'richText',
      font: {},
      style: {},
      value: textToRtValue(t('elements.accordion.value.subtitle1')),
    },
    'mock-element-2': {
      id: 'mock-element-2',
      type: 'richText',
      style: {},
      font: {},
      value: textToRtValue(t('elements.accordion.value.subtitle2')),
    },
  },
  schema: {
    nodes: {
      'mock-node-1': {
        id: 'mock-node-1',
        type: NodeType.column,
        style: {},
        children: ['mock-node-1-children'],
      },
      'mock-node-1-children': {
        id: 'mock-node-1-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {},
        elementId: 'mock-element-1',
        help: '',
        children: [],
      },
      'mock-node-2': {
        id: 'mock-node-2',
        type: NodeType.column,
        style: {},
        children: ['mock-node-2-children'],
      },
      'mock-node-2-children': {
        id: 'mock-node-2-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {},
        elementId: 'mock-element-2',
        help: '',
        children: [],
      },
    },
    rootId: 'rootId',
  },
}

const DEMO_VALUE: AccordionElementValue = {
  items: [
    { value: 'mock-node-1', label: textToRtValue(t('elements.accordion.value.title1')) },
    { value: 'mock-node-2', label: textToRtValue(t('elements.accordion.value.title2')) },
  ],
  showFirstTab: false,
  multipleExpand: true,
}

const AccordionDemo: React.FC<IElementDemoProps<AccordionSchemaType, AccordionFontSchemaType>> = ({
  styles,
  fonts,
}) => {
  const [state, setState] = useState<AccordionState | undefined>()

  const handleSetState = (value: AccordionState) => setState((prev) => ({ ...prev, ...value }))

  return (
    <DemoWrapper>
      <Element
        block={BLOCK_MOCK}
        element={{
          id: `${CONFIG_ELEMENTS.accordion.type}-demo`,
          type: CONFIG_ELEMENTS.accordion.type,
          value: DEMO_VALUE,
          style: {},
          font: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        setState={handleSetState}
        state={state}
        styles={styles}
      />
    </DemoWrapper>
  )
}

export default AccordionDemo

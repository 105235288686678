import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

import { ReactComponent as IndentsIcon } from './indents.svg'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.table.style, {
  content: {
    META: { label: t('elements.table.tags.content') },
    backgroundColor: {},
  },
  header: {
    META: { hidden: true },
    backgroundColor: { label: t('input.label.headerColor') },
  },
  divider: {
    META: { hidden: true },
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  indent: {
    META: { label: t('elements.table.tags.indent') },
    padding: { info: <IndentsIcon />, label: t('elements.table.schema.padding') },
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.table.schema.boxShadow') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.table.font, FONT_SCHEMA_FIELDS)

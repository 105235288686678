import { gql } from '../../__generated__'

export const projectDeepQuery = gql(`
  query ProjectsDeepGetById(
    $projectId: String!
    $data: ManagePublicLink
    $excludeStash: Boolean = true
    $excludeHide: Boolean = false
  ) {
    data: projectsGetById(
      projectId: $projectId
      data: $data
      excludeStash: $excludeStash
      excludeHide: $excludeHide
    ) {
      ...ProjectSchemaDeep
    }
  }
`)

export const projectsGetByIdQuery = gql(`
  query ProjectsGetById($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      ...ProjectSchema
    }
  }
`)

export const meInProjectQuery = gql(`
  query MeInProject($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      id
      me {
        id
        employee {
          ...EmployeeSmallSchema
        }
        role
        permissionGroups
      }
    }
  }
`)

import { AccordionFontSchemaType, AccordionSchemaType } from '@leenda/editor/lib/brand'
import {
  AccordionElementValue,
  EditorElement,
  RichTextPanelItem,
} from '@leenda/editor/lib/elements'
import { RichTextValue, textToRtValue } from '@leenda/rich-text'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import * as R from 'ramda'
import { useCallback } from 'react'

import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import RichText from 'components/form/RichText'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import ContentStyled from './styled/ContentStyled'
import IconStyled from './styled/IconStyled'
import ItemStyled from './styled/ItemStyled'
import TitleStyled from './styled/TitleStyled'

const ANIMATION_VARIANTS: Variants = {
  open: {
    height: 'auto',
    opacity: 1,
    translateY: 0,
    position: 'relative',
    zIndex: 0,
    transition: { duration: 0.3 },
    willChange: 'auto',
  },
  closed: {
    height: 0,
    opacity: 0,
    translateY: -10,
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    zIndex: 0,
    transition: { duration: 0.3, opacity: { duration: 0.5 } },
    willChange: 'auto',
  },
}

interface IItemProps {
  styles: ElementStyleCss<AccordionSchemaType>
  item: RichTextPanelItem
  index: number
  waiting?: boolean
  block?: Block | null
  isOpen?: boolean
  element: EditorElement<AccordionElementValue, AccordionSchemaType, AccordionFontSchemaType>
  font: ElementFontCss<AccordionFontSchemaType>
  mode: IBlockMode
  activeRt?: boolean
  onChange?: (value: AccordionElementValue) => void
  onLabelSelect?: (index: string) => void
  onChoose?: (value: string) => void
  onToggle?: (value: string) => void
}

const AccordionItem: React.FC<IItemProps> = (props) => {
  const { styles, item, index, waiting, block, isOpen, element, font, mode, activeRt } = props
  const { onChange, onChoose, onToggle, onLabelSelect } = props
  const { value, label } = item
  const { icon } = styles
  const showIcon = !((!isOpen && icon.backgroundImage) || (isOpen && icon.backgroundOpen))
  const isEditor = mode.previewMode === PreviewMode.editor
  const isFill = isEditor && mode.editorMode === AppMode.fill

  const handleLabelSelect = useCallback(
    () => onLabelSelect?.(index.toString()),
    [onLabelSelect, index],
  )

  const handleLabelChange = useCallback(
    (v: RichTextValue) => onChange?.(R.assocPath(['items', index, 'label'], v, element.value)),
    [onChange, element.value, index],
  )

  const handleToggleIcon = useCallback(
    () => isEditor && onToggle?.(value),
    [onToggle, isEditor, value],
  )

  const handleToggleTitle = useCallback(
    () => !isEditor && onToggle?.(value),
    [onToggle, isEditor, value],
  )

  const handleChoose = useCallback(() => onChoose?.(value), [onChoose, value])

  return (
    <ItemStyled $styles={styles} onClick={handleChoose}>
      <TitleStyled $isOpen={isOpen} $styles={styles} onClick={handleToggleTitle}>
        <IconStyled $isOpen={isOpen} $styles={styles} onClick={handleToggleIcon}>
          {showIcon && <Icon name='iconsForElementsDown' size={KitSize.S} />}
        </IconStyled>
        <span onMouseDown={handleLabelSelect}>
          <RichText
            active={!!activeRt}
            disabled={!isFill}
            name={`accordion.${index}`}
            onChange={handleLabelChange}
            styles={font}
            value={label || textToRtValue(t('elements.accordion.form.component'))}
            waiting={waiting}
          />
        </span>
      </TitleStyled>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div animate='open' exit='closed' initial='closed' variants={ANIMATION_VARIANTS}>
            <ContentStyled $styles={styles}>
              <NodeContainer block={block!} id={value} level={0} mode={mode} />
            </ContentStyled>
          </motion.div>
        )}
      </AnimatePresence>
    </ItemStyled>
  )
}

export default AccordionItem

import { DeepPartial } from '@leenda/editor/lib/utils/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'

import type { CommentMock } from 'components/comments/types'
import { COMMENTS_FILTER_INITIAL_VALUES } from 'components/forms/CommentsFilter/constants'
import { TASKS_FILTER_INITIAL_VALUES } from 'components/forms/TasksEditorFilter/constants'
import type { TaskMock } from 'components/uiKit/Task'
import { SCORM_PATHS } from 'constants/paths'
import {
  EditorBlockPatchSubscriptionSubscription,
  EmployeePreferencesSchemaFragment,
} from 'gql/__generated__/graphql'
import { ScrollType } from 'services/Scroll/ScrollService'

import { DEFAULT_SETTINGS } from './constants'
import { DeviceMode, AppMode, PreviewMode } from './enums'
import { updateBlockState, updateNavigation } from './mutators/common'
import {
  courseContinue,
  courseStart,
  setCourseStatus,
  setSidebar,
  setSidebarChapter,
  setSidebarSearch,
} from './mutators/course'
import {
  setAppMode,
  setNavigation,
  setToolbar,
  setSettings,
  setCommentsFilter,
  setDeviceMode,
  setHighlight,
  setProjectNavigation,
  setTasksFilter,
  updateBlock,
  remoteBlockPatch,
  addUndoPatch,
  undoBlock,
  redoBlock,
  resetBlock,
  updateBlockVersion,
  waitBlock,
} from './mutators/editor'
import { updateElement, completeElementInBlock } from './mutators/element'
import { questionReset, questionSetValue, questionValidate, reset } from './mutators/question'
import { testEnd, testRestart, testStart } from './mutators/test'
import {
  Block,
  ElementState,
  IProjectContext,
  MapType,
  PreviewCourseStatus,
  Project,
  ProjectPaths,
  ScormSettings,
  Section,
  Patch,
  IEditorSettings,
  INavigationState,
  IToolbarState,
  IProjectCommentsFilterValues,
  IProjectTasksFilterValues,
  ProjectUrlType,
} from './types'

export interface ICourseNavigationPayload {
  sectionId?: string | null
  blockId?: string | null
  nodeId?: string | null
  scrollBlockId?: string | null
}

export interface IQuestionPayload {
  elementId: string
  value: ElementState
  isReady: boolean
  blockId: string
  currentTry?: number | null
}

export interface ICompleteElementPayload {
  elementId: string
  blockId: string
  incomplete: boolean
}

export interface IProjectNavigationPayload {
  sectionId?: string
  blockId?: string | null
  nodeId?: string | null
  shift?: boolean
  ctrl?: boolean
  scroll?: ScrollType | ScrollType[]
  blockIds?: string[]
}

export interface IUpdateBlockPayload {
  id: string
  name?: string
  value?: unknown
}

export interface IResetBlockPayload {
  id: string
  block: Block
}

export interface IWaitBlockPayload {
  id: string
  wait: boolean
}

export interface IUpdateBlockVersionPayload {
  blockId: string
  version: number
}

const initialState: IProjectContext = {
  path: SCORM_PATHS.mainV2,
  urlParams: {
    publicLinkId: '',
    companyId: '',
    projectId: '',
    sectionId: '',
    version: '0',
  },
  mode: PreviewMode.scorm,
  data: {
    project: {} as Project,
    sections: {},
    blocks: {},
    comments: [],
    tasks: [],
  },
  state: {
    mode: null,
    comments: {
      filter: COMMENTS_FILTER_INITIAL_VALUES,
      form: { blockId: null, elementId: null },
      opened: null,
      pinned: [],
    },
    tasks: { filter: TASKS_FILTER_INITIAL_VALUES, opened: null },
    sidebar: { open: false, chapter: null },
    deviceMode: DeviceMode.desktop,
    editor: {
      syncing: false,
      remotePatches: [],
      undoPatches: [],
      redoPatches: [],
      highlight: null,
      selectedBlocks: [],
      settings: DEFAULT_SETTINGS,
      navBar: {
        tab: null,
        showPin: true,
      },
      toolbar: {
        blockSettings: null,
        hidden: false,
        commentTab: null,
        elementTab: null,
        taskTab: null,
      },
    },
    course: { status: 'not attempted' },
    sections: {},
    blocks: {},
    elements: {},
    scrollReady: {},
  },
}

// TODO: Common question using Draft(mutations, and compose?)  or strong immutable (always return new state with R (types problem with R.assocPath))
export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // INITIALIZE
    init: (state, action: PayloadAction<DeepPartial<IProjectContext>>) =>
      R.mergeDeepRight(state, action.payload) as IProjectContext,

    reset: () => initialState,

    setPath: (state, action: PayloadAction<ProjectPaths>) => {
      state.path = action.payload
    },
    setDataProject: (state, action: PayloadAction<Project>) => {
      state.data.project = action.payload
    },
    setDataSections: (state, action: PayloadAction<MapType<Section>>) => {
      state.data.sections = action.payload
    },
    setDataBlocks: (state, action: PayloadAction<MapType<Block>>) => {
      state.data.blocks = action.payload
    },
    setDataComments: (state, action: PayloadAction<CommentMock[]>) => {
      state.data.comments = action.payload
    },
    setDataTasks: (state, action: PayloadAction<TaskMock[]>) => {
      state.data.tasks = action.payload
    },
    setScormSettings: (state, action: PayloadAction<ScormSettings | undefined>) => {
      state.data.scormSettings = action.payload
    },
    setUrlParams: (state, action: PayloadAction<ProjectUrlType>) => {
      state.urlParams = R.map((v) => v ?? null, action.payload) as ProjectUrlType
    },

    // COURSE
    courseStart: (state) => courseStart(state),

    courseContinue: (state) => courseContinue(state),

    courseEnd: (state) => setCourseStatus('finished')(state),

    setCourseNavigation: (state, action: PayloadAction<ICourseNavigationPayload>) =>
      updateNavigation(action.payload)(state),

    setCourseStatus: (state, action: PayloadAction<PreviewCourseStatus>) =>
      setCourseStatus(action.payload)(state),

    continueBlock: (state, action: PayloadAction<{ blockId: string }>) =>
      updateBlockState(action.payload.blockId, { continueCompleted: true, viewed: true })(state),

    setBlockViewed: (state, action: PayloadAction<string>) =>
      updateBlockState(action.payload, { viewed: true })(state),

    completeElement: (state, action: PayloadAction<ICompleteElementPayload>) =>
      completeElementInBlock(state, action.payload),

    testStart: (state) => testStart(state),

    testRestart: (state) => testRestart(state),

    testEnd: (state) => testEnd(state),

    setQuestion: (state, action: PayloadAction<IQuestionPayload>) =>
      questionSetValue(state, action.payload),

    questionValidate: (state, action: PayloadAction<{ blockId: string }>) =>
      questionValidate(state, action.payload.blockId),

    questionReset: (state, action: PayloadAction<string>) => questionReset(state, action.payload),

    answerReset: (state, action: PayloadAction<string>) => reset(state, action.payload),

    // PROJECT
    setProjectNavigation: (state, action: PayloadAction<IProjectNavigationPayload>) =>
      setProjectNavigation(action.payload)(state),

    setAppMode: (state, action: PayloadAction<{ mode: AppMode }>) =>
      setAppMode(action.payload.mode)(state),

    setDeviceMode: (state, action: PayloadAction<{ mode: DeviceMode }>) =>
      setDeviceMode(action.payload.mode)(state),

    setSidebar: (state, action: PayloadAction<{ open: boolean }>) =>
      setSidebar(action.payload.open)(state),

    setSidebarChapter: (state, action: PayloadAction<{ chapter: Section | null }>) =>
      setSidebarChapter(action.payload.chapter)(state),

    setSidebarSearch: (state, action: PayloadAction<{ search?: string }>) =>
      setSidebarSearch(action.payload.search)(state),

    setToolbar: (state, action: PayloadAction<Partial<IToolbarState>>) =>
      setToolbar(action.payload)(state),

    setNavbar: (state, action: PayloadAction<Partial<INavigationState & { init?: boolean }>>) =>
      setNavigation(action.payload)(state),

    setSettings: (state, action: PayloadAction<Partial<IEditorSettings>>) =>
      setSettings(action.payload)(state),

    setCommentsFilter: (state, action: PayloadAction<IProjectCommentsFilterValues>) =>
      setCommentsFilter(action.payload)(state),

    setTasksFilter: (state, action: PayloadAction<IProjectTasksFilterValues>) =>
      setTasksFilter(action.payload)(state),

    setHighlight: (state, action: PayloadAction<string | null>) =>
      setHighlight(action.payload)(state),

    updateBlock: (state, action: PayloadAction<IUpdateBlockPayload>) =>
      updateBlock(state, action.payload),

    waitBlock: (state, action: PayloadAction<IWaitBlockPayload>) => {
      waitBlock(state, action.payload)
    },

    updateBlockVersion: (state, action: PayloadAction<IUpdateBlockVersionPayload>) => {
      updateBlockVersion(state, action.payload)
    },

    resetBlock: (state, action: PayloadAction<IResetBlockPayload>) => {
      resetBlock(state, action.payload)
    },

    remoteBlockPatch: (
      state,
      action: PayloadAction<EditorBlockPatchSubscriptionSubscription['data']>,
    ) => {
      remoteBlockPatch(state, action.payload)
    },

    addUndoPatch: (state, action: PayloadAction<Patch>) => {
      addUndoPatch(state, action.payload)
    },

    undoBlock: (state) => {
      undoBlock(state)
    },

    redoBlock: (state) => {
      redoBlock(state)
    },

    setSyncing: (state, action: PayloadAction<boolean | 'error'>) => {
      state.state.editor.syncing = action.payload
    },

    // COMMON
    setOpenedTask: (
      state,
      action: PayloadAction<{
        sectionId?: string | null
        blockId?: string | null
        taskId?: string | null
      } | null>,
    ) => {
      if (action.payload?.sectionId) {
        state.urlParams.sectionId = action.payload.sectionId
      }
      state.state.tasks.opened = action.payload
    },
    setOpenedComment: (
      state,
      action: PayloadAction<{
        sectionId?: string | null
        blockId?: string | null
        nodeId?: string | null
        threadId?: string | null
      } | null>,
    ) => {
      state.state.comments.opened = action.payload
      state.state.comments.form = { blockId: null, elementId: null }

      const { sectionId, blockId } = action.payload || {}
      if (sectionId) {
        Object.assign(state, setProjectNavigation({ sectionId, blockId, nodeId: null })(state))
      }
    },
    setCommentForm: (
      state,
      {
        payload,
      }: PayloadAction<{
        open?: true
        blockId: string | null
        elementId: string | null
      } | null>,
    ) => {
      if (payload && (payload.open || !state.state.comments.form.blockId)) {
        state.state.comments.form = payload
      } else {
        state.state.comments.form = { blockId: null, elementId: null }
      }
    },
    togglePinnedComment: (state, { payload }: PayloadAction<{ id: string }>) => {
      const index = state.state.comments.pinned.indexOf(payload.id)
      if (index !== -1) {
        state.state.comments.pinned.splice(index, 1)
      } else {
        state.state.comments.pinned.unshift(payload.id)
      }
      state.state.editor.navBar.showPin = true
    },
    setScrollWait: (state, action: PayloadAction<{ id: string; ready: boolean }>) => {
      state.state.scrollReady[action.payload.id] = action.payload.ready
    },
    clearScrollWait: (state) => {
      state.state.scrollReady = {}
    },
    setElement: (state, action: PayloadAction<{ id: string; value: ElementState }>) =>
      updateElement(action.payload.id, action.payload.value)(state),
    scroll: (_state, _action: PayloadAction<ScrollType | ScrollType[]>) => {},
    setEmployeeToProjectPreferences: (
      state,
      action: PayloadAction<EmployeePreferencesSchemaFragment>,
    ) => {
      state.data.project.employeePreferences = R.mergeDeepRight(
        state.data.project.employeePreferences || {},
        action.payload,
      )
    },
  },
})

export const actions = projectSlice.actions

export type ProjectActions = {
  [key in keyof typeof actions]: (typeof actions)[key]
}

export type ProjectActionsTypes = {
  [key in keyof typeof actions]: (typeof actions)[key] extends { type: infer T } ? T : never
}

export default projectSlice.reducer

import { SliderSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const DotStyled = styled.div<{ $styles: ElementStyleCss<SliderSchemaType>; $selected: boolean }>`
  transition: 0.2s;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;

  &:first-child {
    margin: 0;
  }

  ${({ $styles: { progress, activeColor, inactiveColor }, $selected }) => css`
    height: ${progress.height};
    width: ${progress.width};
    margin-left: ${progress.marginLeft};
    background-color: ${$selected ? activeColor.backgroundColor : inactiveColor.backgroundColor};
  `}
  box-shadow: ${({ $styles }) => $styles.effects.boxShadow};

  &:hover {
    background-color: ${({ $styles: { hover }, $selected }) => !$selected && hover.backgroundColor};
  }
`

export default DotStyled

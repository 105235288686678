"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.createItem = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const id_1 = require("../../../utils/id");
const constants_1 = require("../../constants");
const rich_text_1 = require("@leenda/rich-text");
exports.TYPE = 'labeledGraphic';
const createItem = () => ({
    value: (0, id_1.generateId)(),
    description: [
        ...(0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.labeledGraphic.title'), rich_text_1.SlateElementType.heading4),
        ...(0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.labeledGraphic.description')),
    ],
    x: `${Math.floor(Math.random() * 100)}%`,
    y: `${Math.floor(Math.random() * 100)}%`,
    image: constants_1.imageValue,
    pointIcon: null,
    overlay: '#FFFFFF00',
});
exports.createItem = createItem;
exports.DEFAULT_VALUE = {
    image: constants_1.imageValue,
    items: [(0, exports.createItem)()],
    type: 'image',
    overlay: '#FFFFFF00',
};

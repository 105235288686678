import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { useRef } from 'react'
import { useContextSelector } from 'use-context-selector'

import { LayoutScroll } from 'components/LayoutPage'
import Badge from 'components/uiKit/Badge'
import { EmployeePreferencesTab } from 'gql/__generated__/graphql'
import { useTemplatesAllTotal } from 'gql/templates/apollo'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { testProps } from 'utils/test/qaData'

import { BASE_TAGS, allTags, getTabs } from '../helper'
import { TemplatePickerContext, getQueryVariables } from '../hooks'
import { UiTemplateTags, UiTemplateTagsBySection } from '../types'
import s from './Tags.module.scss'

interface ITagsProps {
  setTab: React.Dispatch<React.SetStateAction<EmployeePreferencesTab>>
}
const Tags: React.FC<ITagsProps> = ({ setTab }) => {
  const {
    collectionId,
    type,
    tag: tagProps,
    setTag,
    tab: tabProps,
    mode,
    companyId,
    sectionId = '',
    setCollection,
  } = useContextSelector(TemplatePickerContext, (value) => value)
  const scrollRef = useRef<OverlayScrollbarsComponent>(null)

  const tagsOptions = type ? BASE_TAGS[type] : allTags
  const tabs = getTabs(mode)
  const tag =
    type && UiTemplateTagsBySection[type].includes(tagProps) ? tagProps : UiTemplateTags.all

  const { mode: tagMode } = getQueryVariables(tag, type, tabProps)

  const { data } = useTemplatesAllTotal({ search: { companyId, sectionId, mode: tagMode } })

  const onChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const value = e.currentTarget.dataset.value
    const tab = tabs.find((item) => item.value === value)
    if (tab) {
      setCollection(null)
      setTab(tab.value)
    }
  }

  const onChangeTag = (e: React.MouseEvent<HTMLDivElement>) => {
    const value = e.currentTarget.textContent
    const tag = tagsOptions.find((item) => item.label === value)
    if (tag) {
      setTag(tag.value)
    }
  }

  const badge: { [key in EmployeePreferencesTab]?: number } = {
    [EmployeePreferencesTab.favorites]: data?.data.favorites || 0,
    [EmployeePreferencesTab.archived]: data?.data.archived || 0,
  }

  const showTags =
    collectionId &&
    type === SectionTypeEnum.landing &&
    tabProps === EmployeePreferencesTab.collections

  const showTabs = !collectionId

  return (
    <div className={s.root}>
      <LayoutScroll ref={scrollRef} sizeAutoCapable>
        {showTabs && (
          <div className={s.tabs}>
            {tabs.map(({ value, label }) => {
              const count = badge[value]
              return (
                <div
                  className={cn(s.menuItem, { [s.active]: tabProps === value })}
                  data-value={value}
                  key={value}
                  onClick={onChangeTab}
                  {...testProps({ el: 'menuItem', value, name: 'templatesTabs' })}
                >
                  {count !== undefined ? (
                    <Badge count={count} offset={[8, 20]} placement='right'>
                      <div className={s.tag} key={value}>
                        {label}
                      </div>
                    </Badge>
                  ) : (
                    <div className={s.tag} key={value}>
                      {label}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}

        <div>
          {showTags && (
            <>
              {tagsOptions.map(({ value, label }) => (
                <div
                  className={cn(s.menuItem, { [s.active]: tag === value })}
                  key={value}
                  onClick={onChangeTag}
                  {...testProps({ el: 'menuItem', value, name: 'templatesTags' })}
                >
                  <div className={s.tag} key={value}>
                    {label}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </LayoutScroll>
    </div>
  )
}

export default React.memo(Tags)

import { PermissionsKey } from '@leenda/crud'
import { useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { FileTypeEnum } from 'utils'

import { BreadcrumbItem } from 'components/uiKit/Breadcrumbs/Breadcrumbs'
import { KitSize } from 'components/uiKit/KitTypes'
import Modal, { MCWithParams, useOpenModal } from 'components/uiKit/Modal'
import Tabs, { TabPanel } from 'components/uiKit/Tabs'
import { EmployeePreferencesFinderTab, FileMetaFor } from 'gql/__generated__/graphql'
import { useCompanyGetById } from 'gql/companies/apollo'
import { usePermissions } from 'services/Permission/PermissionContext'
import { FinderItem } from 'store/models'
import { isFinderFile } from 'utils/useFinder'

import FinderPage from '../FinderPage'
import { Paths } from '../types'
import s from './FinderModalPage.module.scss'
import { Unsplash } from './Unsplash'
import { useGetTabsOptions } from './hooks'
import { FinderTabsEnum } from './types'

interface IFinderModalPageProps {
  paths?: Paths
  params: {
    companyId: string
    projectId?: string
    fileMetaGroupId?: string
  }
  defaultContentType?: FileTypeEnum
  tabs?: FinderTabsEnum[]
}

const SHOW_FINDER_TABS: (EmployeePreferencesFinderTab | FinderTabsEnum)[] = [
  EmployeePreferencesFinderTab.companies,
  EmployeePreferencesFinderTab.projects,
]

export const FinderModalPage: MCWithParams<IFinderModalPageProps, string> = ({
  onClose,
  params: { defaultContentType, params, paths, tabs },
}) => {
  const { data: companyData } = useCompanyGetById(params.companyId)
  const showExternalImages = companyData?.data?.showExternalImages
  const canAdd = usePermissions(PermissionsKey.project_resource_c)
  const { tabsOptions, activeTab, setActiveTab, activeFolder, setActiveFolder } = useGetTabsOptions(
    {
      projectId: params.projectId,
      contentType: defaultContentType,
      showExternalImages,
      tabs,
    },
  )

  const fileMetaGroupId = params.fileMetaGroupId || activeFolder || 'root'

  const onClickBreadcrumbs = useCallback(
    (breadcrumbItem: BreadcrumbItem) => setActiveFolder(breadcrumbItem.id || 'root'),
    [setActiveFolder],
  )
  const onClickItem = useCallback(
    (item: FinderItem) => {
      if (isFinderFile(item)) {
        onClose(item.id)
      } else {
        setActiveFolder(item.id)
      }
    },
    [onClose, setActiveFolder],
  )

  const handleResetFolder = useCallback(() => setActiveFolder('root'), [setActiveFolder])

  const handleSetActiveTab = useCallback(() => {
    const tab = params.projectId
      ? EmployeePreferencesFinderTab.projects
      : EmployeePreferencesFinderTab.companies
    const existTab = tabsOptions.find((t) => t.tabKey === tab)

    existTab ? setActiveTab(existTab.tabKey) : onClose()
  }, [params.projectId, setActiveTab, tabsOptions, onClose])

  return (
    <Modal minHeight='100%' name='filePick' styleType='fullScreen'>
      <div className={s.root}>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={activeTab}
          name='tabs'
          onChange={setActiveTab}
          size={KitSize.S}
          styleType='clear'
          tabContentStyle={{ overflow: 'visible' }}
          grow
        >
          {tabsOptions.map((tab) => (
            <TabPanel key={tab.tabKey} {...tab}>
              {SHOW_FINDER_TABS.includes(tab.tabKey) && (
                <FinderPage
                  backCb={handleResetFolder}
                  createdFor={
                    activeTab === EmployeePreferencesFinderTab.projects
                      ? FileMetaFor.projects
                      : FileMetaFor.companies
                  }
                  defaultContentType={defaultContentType}
                  hiddenAddFile={!canAdd}
                  onClickBreadcrumbs={onClickBreadcrumbs}
                  onClickItem={onClickItem}
                  params={{ ...params, fileMetaGroupId }}
                  paths={paths}
                  isModal
                />
              )}
              {tab.tabKey === FinderTabsEnum.unsplash && (
                <Unsplash
                  defaultContentType={defaultContentType}
                  handleSetActiveTab={handleSetActiveTab}
                  onClose={onClose}
                  params={params}
                  parentId={fileMetaGroupId}
                />
              )}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </Modal>
  )
}

interface IFilePickerParams {
  defaultContentType: FileTypeEnum
  tabs?: FinderTabsEnum[]
}

export const useFilePickerModal = ({ defaultContentType, tabs }: IFilePickerParams) => {
  const routeMatch = useRouteMatch<{
    projectId?: string
    companyId: string
    fileMetaGroupId?: string
  }>()
  const params = routeMatch.params

  return useOpenModal(FinderModalPage, {
    params,
    defaultContentType,
    tabs,
  })
}

import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import { FileTypeEnum } from 'utils'

import MediaPlayer from 'components/MediaPlayer'
import { getFileByUsage } from 'utils/files'

import s from './VideoPreview.module.scss'

const VideoPreview = ({ value }: any) => {
  const mediaFile: any = getFileByUsage(value, FileUsageTypeEnum.video)

  return (
    <div className={s.root}>
      {(mediaFile || value?.id || value?.url) && (
        <MediaPlayer type={FileTypeEnum.VIDEO} url={mediaFile?.path || value.url || null} />
      )}
    </div>
  )
}

export default VideoPreview

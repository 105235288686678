"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUsageImageSource = exports.isFileSource = exports.FileUsageTypeEnum = void 0;
var FileUsageTypeEnum;
(function (FileUsageTypeEnum) {
    FileUsageTypeEnum["common"] = "common";
    FileUsageTypeEnum["audio"] = "audio";
    FileUsageTypeEnum["video"] = "video";
    FileUsageTypeEnum["image"] = "image";
    FileUsageTypeEnum["embeddedArchive"] = "embeddedArchive";
    FileUsageTypeEnum["fontGroup"] = "fontGroup";
    FileUsageTypeEnum["font"] = "font";
    FileUsageTypeEnum["captions"] = "captions";
})(FileUsageTypeEnum || (exports.FileUsageTypeEnum = FileUsageTypeEnum = {}));
const isFileSource = (usage) => Boolean(usage.source === FileUsageImageSource.file && usage.id);
exports.isFileSource = isFileSource;
var FileUsageImageSource;
(function (FileUsageImageSource) {
    FileUsageImageSource["url"] = "url";
    FileUsageImageSource["file"] = "file";
})(FileUsageImageSource || (exports.FileUsageImageSource = FileUsageImageSource = {}));

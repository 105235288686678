import { GroupingFontSchemaType, GroupingSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, GroupingElementValue } from '@leenda/editor/lib/elements'
import { FileUsageImageSource } from '@leenda/editor/lib/files'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './GroupingElement'

const imageValue = {
  source: FileUsageImageSource.file,
  id: '',
  accessibility: '',
}

const DEMO_VALUE: GroupingElementValue = {
  items: [
    {
      value: 'grouping_group_1',
      target: {
        label: t('elements.grouping.value.firstLabel'),
        image: imageValue,
        overlay: '#FFFFFF00',
      },
      items: [
        {
          value: 'grouping_group1_item_1',
          label: t('elements.grouping.value.firstItem', {
            elem: t('elements.grouping.value.firstElem'),
          }),
          image: imageValue,
          overlay: '#FFFFFF00',
        },
        {
          value: 'grouping_group1_item_2',
          label: t('elements.grouping.value.firstItem', {
            elem: t('elements.grouping.value.secondElem'),
          }),
          image: imageValue,
          overlay: '#FFFFFF00',
        },
        {
          value: 'grouping_group1_item_3',
          label: t('elements.grouping.value.firstItem', {
            elem: t('elements.grouping.value.thirdElem'),
          }),
          image: imageValue,
          overlay: '#FFFFFF00',
        },
      ],
    },
    {
      value: 'grouping_group_2',
      target: {
        label: t('elements.grouping.value.secondLabel'),
        image: imageValue,
        overlay: '#FFFFFF00',
      },
      items: [
        {
          value: 'grouping_group2_item_1',
          label: t('elements.grouping.value.secondItem', {
            elem: t('elements.grouping.value.firstElem'),
          }),
          image: imageValue,
          overlay: '#FFFFFF00',
        },
        {
          value: 'grouping_group2_item_2',
          label: t('elements.grouping.value.secondItem', {
            elem: t('elements.grouping.value.secondElem'),
          }),
          image: imageValue,
          overlay: '#FFFFFF00',
        },
        {
          value: 'grouping_group2_item_3',
          label: t('elements.grouping.value.secondItem', {
            elem: t('elements.grouping.value.thirdElem'),
          }),
          image: imageValue,
          overlay: '#FFFFFF00',
        },
      ],
    },
  ],
}

const GroupingDemo: React.FC<IElementDemoProps<GroupingSchemaType, GroupingFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.grouping.type}-demo`,
        type: CONFIG_ELEMENTS.grouping.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default GroupingDemo

import { BoxShadowType } from '@leenda/editor/lib/brand'
import { StyleValue } from '@leenda/editor/lib/elements'

export const PRESET_MAP = {
  none: 'none',
  soft: '0px 0px 20px',
  regular: '6px 6px 15px',
  retro: '2px 2px 0px',
}

export const validateBoxShadowCss = (value: StyleValue | BoxShadowType) => {
  try {
    const { preset, size, color }: BoxShadowType =
      typeof value === 'string' ? JSON.parse(value) : value
    if (preset === 'none') return 'none'
    return `${PRESET_MAP[preset]} ${size}px ${color}`
  } catch (e) {
    return 'none'
  }
}

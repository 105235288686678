"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThemeValue = exports.isThemeVariable = exports.setThemeVariable = exports.BrandThemeRegex = exports.setFontValue = exports.getFontValue = exports.getStyleSchemaFontFamily = void 0;
const R = __importStar(require("ramda"));
const NotNull = (value) => {
    return !!value;
};
const getStyleSchemaFontFamily = (styleSchema) => {
    return Object.values(styleSchema || {})
        .flatMap((deviceData) => Object.values(deviceData || {}).flatMap((data) => data === null || data === void 0 ? void 0 : data.fontFamily))
        .filter(NotNull);
};
exports.getStyleSchemaFontFamily = getStyleSchemaFontFamily;
const getFontValue = (value) => {
    try {
        return JSON.parse(value);
    }
    catch (error) {
        return { name: 'sans-serif' };
    }
};
exports.getFontValue = getFontValue;
const setFontValue = (value) => {
    if (value.id)
        return JSON.stringify(R.pick(['id', 'isSerif'], value));
    return JSON.stringify(R.pick(['name'], value));
};
exports.setFontValue = setFontValue;
exports.BrandThemeRegex = /^var\(--brand-([a-z-0-9,\s]+)\)$/i;
const setThemeVariable = (value) => {
    return `var(--brand-${value})`;
};
exports.setThemeVariable = setThemeVariable;
const isThemeVariable = (value) => {
    if (typeof value === 'string') {
        const match = value.match(exports.BrandThemeRegex);
        const themeKey = match === null || match === void 0 ? void 0 : match[1];
        if (themeKey) {
            return true;
        }
    }
    return false;
};
exports.isThemeVariable = isThemeVariable;
const getThemeValue = (value, theme) => {
    var _a;
    if ((0, exports.isThemeVariable)(value)) {
        const match = String(value).match(exports.BrandThemeRegex);
        const themeKey = match === null || match === void 0 ? void 0 : match[1];
        if (themeKey) {
            return ((_a = theme === null || theme === void 0 ? void 0 : theme[themeKey]) !== null && _a !== void 0 ? _a : '');
        }
    }
    return value;
};
exports.getThemeValue = getThemeValue;

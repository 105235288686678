import { gql } from 'gql/__generated__'

export const sectionsAllQuery = gql(`
  query SectionsAll($projectId: String!) {
    data: editorSectionsAll(projectId: $projectId) {
      sections {
        ...SectionEditorSchema
      }
    }
  }
`)

export const sectionsGetById = gql(`
  query SectionsGetById($id: String!, $projectId: String!) {
    data: editorSectionsGetById(id: $id, projectId: $projectId) {
      ...SectionEditorSchema
    }
  }
`)

export const editorSectionsTrash = gql(`
  query EditorSectionsTrash($projectId: String!) {
    data: editorSectionsTrash(projectId: $projectId) {
      sections {
        ...SectionEditorSchema
      }
    }
  }
`)

export const sectionsTrashCount = gql(`
  query SectionsTrashCount($projectId: String!) {
    data: sectionsTrashCount(projectId: $projectId) {
      count
    }
  }
`)

export const editorSectionsRestoreByIds = gql(`
  mutation EditorSectionsRestoreByIds($ids: [String!]!, $sectionId: String) {
    data: editorSectionsRestoreByIds(ids: $ids,  sectionId: $sectionId) {
      sectionIdsRestored
      sectionsOrder {
        lvl
        id
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

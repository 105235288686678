import {
  CompanyIdentification,
  EmployeeStatusEnum,
  getCommentRole,
  getTaskRole,
  PermissionIdentification,
  PermissionService,
  PermissionsKey,
  ProjectIdentification,
} from '@leenda/crud'
import * as R from 'ramda'
import { createContext, useContext, useMemo } from 'react'

import { CommentMock } from 'components/comments/types'
import { KitAccessState } from 'components/uiKit/KitTypes'
import { TaskMock } from 'components/uiKit/Task'
import { CompanyRole, EmployeeListSchemaFragment } from 'gql/__generated__/graphql'
import { useParticipantsAll } from 'gql/participants/apollo'
import { selectCurrentEmployee, selectSortedEmployeesAll } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { notEmpty } from 'utils/notEmpty'

import { FinderFileItem } from '../../store/models'
import { ICheckPermissionsParam, IUiPermission, PermissionLeaf } from './types'

export interface IPermissionContextValue {
  permissions: PermissionService<PermissionIdentification>
  loading: boolean
  data: PermissionIdentification
}

interface IUseEmployeesByPermissionInProject {
  projectId: string
  includeCommentator?: boolean
  excludeSelf?: boolean
  pk: PermissionsKey | PermissionsKey[]
  sort?: (<U>(a: U, b: U) => R.Ordering)[]
}

export const PermissionContext = createContext({
  data: {},
  loading: true,
} as IPermissionContextValue)

const usePermissionContext = () => useContext(PermissionContext)

const isComplexLeaf = (
  item: PermissionLeaf,
): item is { key: ICheckPermissionsParam; mode?: 'or' | 'and' } =>
  !Array.isArray(item) && typeof item === 'object'

const checker = (service: PermissionService<PermissionIdentification>, perm: PermissionLeaf) =>
  isComplexLeaf(perm) ? service.has(perm.key, perm.mode) : service.has(perm)

const isOptions = <I,>(
  item: I | (I & IUiPermission)[] | ICheckPermissionsParam,
): item is (I & IUiPermission)[] => Array.isArray(item) && typeof item[0] === 'object'

const isMany = <
  I extends Record<string, PermissionLeaf> | (I & IUiPermission)[] | ICheckPermissionsParam,
>(
  item: Record<string, PermissionLeaf> | (I & IUiPermission)[] | ICheckPermissionsParam,
): item is Record<string, PermissionLeaf> => !Array.isArray(item) && typeof item === 'object'

const applyPermissions = <I extends KitAccessState>(
  service: PermissionService<PermissionIdentification>,
  { permission: p, ...rest }: I & IUiPermission,
) =>
  ({
    ...rest,
    ...(p?.disabled && { disabled: !checker(service, p.disabled) || rest.disabled }),
    ...(p?.hidden && { hidden: !checker(service, p.hidden) || rest.hidden }),
    ...(p?.readonly && { readonly: !checker(service, p.readonly) || rest.readOnly }),
  }) as I & KitAccessState

/**
 * @example
 *  Usage with `options`
 * ```typescript
 * const OPTIONS: IMenuOptions<'edit' | 'delete'> = [
 *  {
 *    value: 'edit',
 *    permission: {
 *      disabled: { key: [PermissionsKey.project_resource_c, PermissionsKey.company_resource_c], mode: 'or'}
 *    }
 *  },
 *  {
 *    value: 'delete',
 *    permission: {
 *      hidden: PermissionsKey.project_resource_c
 *    }
 *  }
 * ];
 * const Component: React.FC = () => {
 *  const options = usePermissions(OPTIONS);
 *  // options: [
 *  //   { value: 'edit', disabled: true },
 *  //   { value: 'delete', hidden: true }
 *  // ]
 *  // return ...
 * ```
 * }
 */
export function usePermissions<I>(options: (I & IUiPermission)[]): (I & KitAccessState)[]
/**
 * @example
 *  Usage with `many`
 * ```typescript
 * const MANY = {
 *  canDelete: PermissionsKey.project_resource_d,
 *  canEdit: PermissionsKey.project_resource_u,
 *  canRead: { key: [PermissionsKey.project_resource_c, PermissionsKey.company_resource_c], mode: 'or'},
 * }
 * const Component: React.FC = () => {
 *  const { canDelete, canEdit, canRead } = usePermissions(MANY);
 *  // canDelete: true, canEdit: true, canRead: false
 *  // return ...
 * }
 * ```
 */
export function usePermissions<I extends Record<string, PermissionLeaf>>(
  many: I,
): Record<keyof I, boolean>
/**
 * @example
 *  Usage with `key` and `mode`
 * ```typescript
 * const Component: React.FC = () => {
 *  const canDelete = usePermissions(PermissionsKey.project_resource_d, 'or');
 *  // canDelete: true
 *  // return ...
 * }
 * ```
 */
export function usePermissions(key: ICheckPermissionsParam, mode?: 'or' | 'and'): boolean
/**
 * @example
 * // Usage without arguments
 * ```typescript
 * const service = usePermissions();
 * // service: PermissionService<PermissionIdentification>
 * // service.has(PermissionsKey.project_resource_d)
 * ```
 */
export function usePermissions(): PermissionService<PermissionIdentification>
export function usePermissions<I extends Record<string, PermissionLeaf>>(
  data?: I | (I & IUiPermission)[] | ICheckPermissionsParam,
  mode?: 'or' | 'and',
) {
  const { data: permissions } = usePermissionContext()

  return useMemo(() => {
    const service = new PermissionService(permissions || {})
    if (data && isMany(data)) {
      return R.mapObjIndexed((v) => checker(service, v), data)
    }
    if (data && isOptions(data)) {
      return data.map((item) => applyPermissions(service, item))
    }
    return data ? service.has(data, mode) : service
  }, [permissions, data, mode])
}

export const useCommentPermissions = (
  comment: Pick<CommentMock, 'mentioned' | 'createdBy' | 'project'>,
) => {
  const { data, loading } = usePermissionContext()
  if (loading) {
    return new PermissionService(data)
  }
  if (!data.employee || !comment.mentioned || !comment.createdBy) {
    throw new Error('Employee is not defined')
  }
  const mentionedIds = comment.mentioned.map(({ id }) => id)
  const commentRole = getCommentRole(
    { mentionedIds, createdBy: comment.createdBy.id },
    data.employee.id,
  )
  return new PermissionService({
    ...data,
    participant: comment.project?.me || undefined,
    commentRole,
  })
}

export const useFileMetaPermissions = (file: Pick<FinderFileItem, 'createdBy'>) => {
  const { data, loading } = usePermissionContext()
  if (loading || !file) {
    return new PermissionService(data)
  }
  if (!data.employee || !file.createdBy) {
    throw new Error('Employee is not defined')
  }
  return new PermissionService(data)
}

export const useTaskPermissions = (task?: Pick<TaskMock, 'assigned' | 'createdBy'> | null) => {
  const { data, loading } = usePermissionContext()
  if (loading || !task) {
    return new PermissionService(data)
  }
  if (!data.employee || !task.assigned || !task.createdBy) {
    throw new Error('Employee is not defined')
  }
  const assignedIds = task.assigned.map((item) => item.id) // TODO: CRUD after tasks
  const taskRole = getTaskRole(
    { assignedIds, createdBy: task.createdBy?.id || '' },
    data.employee.id,
  )
  return new PermissionService({ ...data, taskRole })
}

export const useEmployeesByPermissionInProject = ({
  projectId,
  includeCommentator = false,
  excludeSelf,
  pk,
  sort,
}: IUseEmployeesByPermissionInProject) => {
  const { data, loading } = useParticipantsAll(projectId, {})
  const companyEmployees = useAppSelector(selectSortedEmployeesAll)
  const currentEmployee = useAppSelector(selectCurrentEmployee)
  return useMemo(() => {
    const participantsAll = data?.data.participants.filter(notEmpty) || []
    const project = participantsAll
      .map((p) => {
        const permissions = new PermissionService<ProjectIdentification>({
          companyId: p?.employee.companyId,
          projectId,
          user: p?.employee.kUser,
          employee: p?.employee,
          participant: p,
        })
        if (!includeCommentator && p?.employee.role === CompanyRole.commentator) {
          return
        }
        if (permissions.has(pk)) {
          return p.employee
        }
        return
      })
      .filter(notEmpty)
    const company = companyEmployees.filter((ce) => {
      const permissions = new PermissionService<CompanyIdentification>({
        companyId: ce.companyId,
        user: ce.kUser,
        employee: ce,
      })
      if (permissions.has(pk)) {
        return !project.some((pe) => pe.id === ce.id)
      }
      return false
    })
    const all = [...project, ...company].filter((item) => {
      if (excludeSelf) {
        return item.id !== currentEmployee?.id
      }
      return true
    })
    return {
      company: sort ? R.sortWith(sort, company) : company,
      project: sort ? R.sortWith(sort, project) : project,
      all: sort ? R.sortWith(sort, all) : all,
      loading,
    }
  }, [
    data?.data.participants,
    companyEmployees,
    sort,
    loading,
    projectId,
    includeCommentator,
    pk,
    excludeSelf,
    currentEmployee?.id,
  ])
}

export const SORT_BY_STATUS = [R.ascend(R.propOr(EmployeeStatusEnum.ARCHIVED, 'status'))]

export const getEmployeesByPermissionInCompanyFiltered = ({
  pk,
  includeCommentator = true,
  employees,
}: {
  pk: PermissionsKey | PermissionsKey[]
  includeCommentator?: boolean
  employees: EmployeeListSchemaFragment[]
}) => {
  return employees.filter((employee) => {
    const employeePermissions = new PermissionService<CompanyIdentification>({
      companyId: employee.companyId,
      user: employee.kUser,
      employee,
    })
    if (!includeCommentator && employee.role === CompanyRole.commentator) {
      return false
    }
    if (employeePermissions.has(pk)) {
      return employee
    }
    return false
  })
}

export { usePermissionContext }

import React from 'react'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { DownloaderValueFormType } from './DownloaderElement.types'

const genField = genDataField<Block>()

const file = genField({
  name: 'file',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'document',
    nullable: true,
    preview: true,
    showSource: true,
  },
})

const label = genField({
  name: 'label',
  label: t('input.label.name'),
  type: 'text',
  layout: 'horizontal',
  params: { placeholder: t('input.placeholder.name') },
  rules: [{ max: 150 }],
})

const DownloaderForm: DownloaderValueFormType = ({ name }) => (
  <Padding sides>
    <RealtimeField config={chainName(name, file)} />
    <RealtimeField config={chainName(name, label)} />
  </Padding>
)

export default DownloaderForm

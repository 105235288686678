import { NavButtonFontSchemaType, NavButtonSchemaType } from '@leenda/editor/lib/brand'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { IBlockMode } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import s from './NavigationButtonsDemo.module.scss'
import BaseNavigationButton from './components/BaseNavigationButton'
import ButtonIcon from './components/ButtonIcon'
import { getIconUrl } from './helpers'
import img from './illustration-eye-block.svg'

interface INavigationButtonsDemoProps {
  styles: ElementStyleCss<NavButtonSchemaType>
  fonts?: ElementFontCss<NavButtonFontSchemaType>
  mode: IBlockMode
}

const NavigationButtonsDemo = ({ styles, fonts, mode }: INavigationButtonsDemoProps) => {
  const pt = useProjectT()

  const nextIcon = getIconUrl(styles?.nextButton__var)
  const prevIcon = getIconUrl(styles?.prevButton__var)

  return styles?.show.show ? (
    <div className={s.visible}>
      <div className={s.demo}>
        <div>{t('elements.navigationButtons.demo.active')}</div>
        <div className={s.buttonWrapper} style={{ gap: styles.padding__var.gap }}>
          <div className={s.item}>
            <BaseNavigationButton
              fonts={fonts}
              icon={<ButtonIcon defaultName='courseArrowBack' url={prevIcon} />}
              iconPosition='left'
              label={pt('navigationButton.return')}
              mode={mode}
              styles={{
                root: styles.root,
                button: styles?.prevButton__var,
                hover: styles.hover,
                effects: styles.effects,
              }}
            />
          </div>
          <div className={s.item}>
            <BaseNavigationButton
              fonts={fonts}
              icon={<ButtonIcon defaultName='courseArrowForward' url={nextIcon} />}
              iconPosition='right'
              label={t('elements.button.demo.value.continue')}
              mode={mode}
              styles={{
                root: styles.root,
                button: styles.nextButton__var,
                hover: styles.hover,
                effects: styles.effects,
              }}
            />
          </div>
        </div>
      </div>
      <div className={s.demo}>
        <div>{t('elements.navigationButtons.demo.disabled')}</div>
        <div className={s.buttonWrapper} style={{ gap: styles.padding__var.gap }}>
          <div className={s.item}>
            <BaseNavigationButton
              fonts={fonts}
              icon={<ButtonIcon defaultName='courseArrowBack' url={prevIcon} />}
              iconPosition='left'
              label={pt('navigationButton.return')}
              mode={mode}
              styles={{
                root: styles.root,
                button: { ...styles.prevButton__var, ...styles.prevButtonDisabled__var },
                hover: styles.hover,
                effects: styles.effects,
              }}
            />
          </div>
          <div className={s.item}>
            <BaseNavigationButton
              fonts={fonts}
              icon={<ButtonIcon defaultName='courseArrowForward' url={nextIcon} />}
              iconPosition='right'
              label={t('elements.button.demo.value.continue')}
              mode={mode}
              styles={{
                root: styles.root,
                button: { ...styles.nextButton__var, ...styles.nextButtonDisabled__var },
                hover: styles.hover,
                effects: styles.effects,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={s.empty}>
      <img src={img} />
      <div className={s.text}>{t('elements.navigationButtons.demo.hide')}</div>
    </div>
  )
}

export default NavigationButtonsDemo

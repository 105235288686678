"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/CourseStructure/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    chapterIcons: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    sectionIcons: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    testIcons: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    item_hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    item: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }),
    },
    progress: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    offsetFromIcon: {
        marginRight: (0, genStyle_1.genStyle)({ value: 8 }),
    },
    offsetFromSection: {
        marginTop: (0, genStyle_1.genStyle)({ value: 12 }),
    },
    offsetFromProgress: {
        marginRight: (0, genStyle_1.genStyle)({ value: 16 }),
    },
    offsetFromCollapse: {
        marginRight: (0, genStyle_1.genStyle)({ value: 8 }),
    },
    collapseIcon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
        backgroundOpen: (0, genStyle_1.genStyle)({ value: null }),
    },
};
const fontSchema = {
    chapter: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 16 }), color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }) }),
    section: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 14 }) }),
    description: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 14 }) }),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };

import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import Icon from 'components/uiKit/Icon'
import { Locale } from 'gql/__generated__/graphql'
import { getCurrentLocale } from 'services/Translation/i18n'
import { testProps } from 'utils/test/qaData'

import s from './Loader.module.scss'
import SvgLoader from './SvgLoader'

interface ILoader {
  name: string
  styleType?: 'default' | 'absolute' | 'fixed' | 'svg' | 'inline'
  color?: 'primary' | 'background'
  resources?: boolean
}

const COLOR_MAP = {
  primary: 'var(--color-primary-default)',
  background: 'var(--color-background-main)',
}

const locale = getCurrentLocale()

const RESOURCES =
  locale === Locale.EN
    ? [
        'L&D productivity',
        'Real-time collaboration',
        'Enterprise-wide',
        'eLearning 3.0',
        'Teamwork',
        'Organizational Efficiency',
        'Efficient workflows',
        'L&D platform',
        'Knowledge sharing',
        'End-to-end branding',
        'Impactful teamwork',
        'No-code editor',
        'Role models',
        'Template builder',
        'Corporate administration',
        'Collaboration canvas',
        'Comments & tasks',
      ]
    : [
        'Рост продуктивности',
        'Совместная работа в реальном времени',
        'Корпоративное обучение',
        'Онлайн образование 3.0',
        'Командная работа',
        'Командная продуктивность',
        'Эффективные рабочие процессы',
        'Платформа для обучения и развития',
        'Обмен знаниями',
        'Корпоративные стили',
        'Эффективная командная работа',
        'No-code редактор',
        'Ролевая модель',
        'Конструктор шаблонов',
        'Корпоративное управление',
        'Единое рабочее пространство',
        'Комментарии и задачи',
      ]

const useFakeResourceBuilderProgress = () => {
  const [currentResource, setCurrentResource] = useState(RESOURCES[0])

  useEffect(() => {
    let interval: NodeJS.Timeout
    const doJob = () => {
      clearInterval(interval)
      setCurrentResource(RESOURCES[Math.floor(Math.random() * RESOURCES.length)])
      interval = setInterval(doJob, Math.floor(Math.random() * 500) + 300)
    }

    doJob()

    return () => clearInterval(interval)
  }, [RESOURCES])

  return currentResource
}

const Loader = React.forwardRef<HTMLDivElement, ILoader>(
  ({ name, resources, styleType = 'default', color = 'primary' }, ref) => {
    if (styleType === 'svg') {
      return <SvgLoader color={COLOR_MAP[color]} />
    }
    const cnRoot = cn(s.root, s[styleType])
    return (
      <div {...testProps({ el: 'loader', name })} className={cnRoot} ref={ref}>
        <div className={s.spinner}>
          <Icon name='spinner' style={{ color: COLOR_MAP[color] }} />
        </div>
        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
        {resources && <div className={s.resources}>{useFakeResourceBuilderProgress()}</div>}
      </div>
    )
  },
)

Loader.displayName = 'Loader'

export default Loader

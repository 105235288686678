import { PositionTabsElementType, ViewTabsElementType } from '@leenda/editor/lib/brand'
import { useUpdateEffect } from 'ahooks'
import lodash from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'

import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import { DeviceMode, PreviewMode } from 'services/Store/Project/enums'

import { useElementCompleted } from '../../hooks/useElementCompleted'
import useSetActiveItemElement from '../../hooks/useSetActiveItemElement'
import { TabsElementType } from './TabsElement.types'
import { ReactComponent as IconBottomDefaultSVG } from './assets/nav_icon_bottom.svg'
import { ReactComponent as IconLeftDefaultSVG } from './assets/nav_icon_left.svg'
import { ReactComponent as IconRightDefaultSVG } from './assets/nav_icon_right.svg'
import { ReactComponent as IconTopDefaultSVG } from './assets/nav_icon_top.svg'
import { useTabScroll } from './hooks'
import NavButtonContainerStyled from './styled/NavButtonContainerStyled'
import NavButtonStyled from './styled/NavButtonStyled'
import TabElementStyled from './styled/TabElementStyled'
import TabItemMarkerStyled from './styled/TabItemMarkerStyled'
import TabItemStyled from './styled/TabItemStyled'
import TabItemTextStyled from './styled/TabItemTextStyled'
import TabItemsListStyled from './styled/TabItemsListStyled'
import TabItemsScrollStyled, { OVERFLOW_OFFSET } from './styled/TabItemsScrollStyled'
import TabItemsStyled from './styled/TabItemsStyled'
import TabPanelStyled from './styled/TabPanelStyled'
import { DirectionTabsEnum } from './types'

const TabsElement: TabsElementType = ({ block, font, element, styles, mode, state, setState }) => {
  const isDesktop = mode.deviceMode === DeviceMode.desktop
  const items = element.value.items
  const showButtons = styles.icons.show
  const iconLeft = styles.iconLeft.backgroundImage
  const iconRight = styles.iconRight.backgroundImage
  const notTopDirectionTabs =
    (styles.tabs.position as PositionTabsElementType) !== PositionTabsElementType.top
  const ImgDefaultPrev = notTopDirectionTabs ? IconTopDefaultSVG : IconLeftDefaultSVG
  const ImgDefaultNext = notTopDirectionTabs ? IconBottomDefaultSVG : IconRightDefaultSVG
  const scrollTabsRef = useRef<HTMLDivElement | null>(null)
  const activeTabRef = useRef<HTMLDivElement | null>(null)
  const listTabRef = useRef<HTMLUListElement | null>(null)
  const [viewedTabs, setViewedTabs] = useState(
    items.reduce<Record<string, boolean>>(
      (acc, item, i) => ({ ...acc, [item.value]: i === 0 }),
      {},
    ),
  )
  const allViewed = useMemo(() => lodash.every(viewedTabs), [viewedTabs])
  const activeTabId = useMemo(
    () => items.find(({ value }) => value === state?.active)?.value || items[0]?.value,
    [state?.active, items],
  )
  const { hasNextScroll, hasPrevScroll } = useTabScroll({
    scrollTabsRef,
    activeTabId,
    activeTabRef,
    listTabRef,
    deps: [items.length, mode.deviceMode],
  })

  const onChangeTab = useCallback(
    (value: string) => {
      setState?.({ active: value, pauseAnimation: false })
      !allViewed && setViewedTabs((prev) => ({ ...prev, [value]: true }))
    },
    [allViewed, setState],
  )

  const handleClick = useCallback((value: string) => onChangeTab(value), [onChangeTab])

  const onChangeNewTab = useCallback(
    (direction: DirectionTabsEnum) => {
      const index = items.findIndex(({ value }) => value === activeTabId)
      if (direction === DirectionTabsEnum.next && index < items.length - 1) {
        handleClick(items[index + 1].value)
      }
      if (direction === DirectionTabsEnum.prev && index > 0) {
        handleClick(items[index - 1].value)
      }
    },
    [items, activeTabId, handleClick],
  )

  useElementCompleted(block?.uuid || '', element.id, allViewed)
  useSetActiveItemElement(items, block!, onChangeTab)

  useUpdateEffect(() => {
    setState?.({ active: state?.active || null, pauseAnimation: true })
  }, [styles.tabs.position, styles.view.type])

  if (mode.previewMode === PreviewMode.pdf) {
    return items.map((item) => (
      <>
        <TabElementStyled $styles={styles}>
          <TabItemsListStyled $styles={styles}>
            <TabItemsScrollStyled $styles={styles}>
              <TabItemsStyled $styles={styles}>
                <TabItemStyled $font={font} $styles={styles} $active>
                  <TabItemMarkerStyled $font={font} $styles={styles} $active />
                  <TabItemTextStyled $font={font} $styles={styles}>
                    {item.label}
                  </TabItemTextStyled>
                </TabItemStyled>
              </TabItemsStyled>
            </TabItemsScrollStyled>
          </TabItemsListStyled>
        </TabElementStyled>
        <TabPanelStyled $styles={styles}>
          <NodeContainer block={block!} id={activeTabId} level={0} mode={mode} />
        </TabPanelStyled>
      </>
    ))
  }

  return (
    <TabElementStyled $styles={styles}>
      {/*need for bottom line, that overflow in scroll hide content  which not static */}
      <TabItemsListStyled $styles={styles}>
        {hasPrevScroll && showButtons && (
          <NavButtonContainerStyled
            $iconSide={DirectionTabsEnum.prev}
            $isDesktop={isDesktop}
            $styles={styles}
            onClick={() => onChangeNewTab(DirectionTabsEnum.prev)}
          >
            <NavButtonStyled $iconSide={DirectionTabsEnum.prev} $styles={styles}>
              {!iconLeft && <ImgDefaultPrev />}
            </NavButtonStyled>
          </NavButtonContainerStyled>
        )}
        <TabItemsScrollStyled
          $hasNextScroll={hasNextScroll}
          $hasPrevScroll={hasPrevScroll}
          $styles={styles}
          ref={scrollTabsRef}
        >
          <TabItemsStyled $styles={styles} ref={listTabRef}>
            {items.map((item) => (
              <TabItemStyled
                $active={activeTabId === item.value}
                $font={font}
                $styles={styles}
                key={item.value}
                onClick={() => handleClick(item.value)}
              >
                {activeTabId === item.value && (
                  <TabItemMarkerStyled
                    $active={activeTabId === item.value}
                    $font={font}
                    $styles={styles}
                    initial={false}
                    layoutId={`bubble-group${element.id}`}
                    ref={activeTabRef}
                    transition={{ duration: state?.pauseAnimation ? 0 : 0.4 }}
                  />
                )}
                <TabItemTextStyled
                  $active={activeTabId === item.value}
                  $font={font}
                  $styles={styles}
                >
                  {item.label}
                </TabItemTextStyled>
              </TabItemStyled>
            ))}
            {styles.view.type === ViewTabsElementType.splitted &&
              (styles.tabs.position as PositionTabsElementType) === PositionTabsElementType.top && (
                <div style={{ minWidth: OVERFLOW_OFFSET - 8 }} />
              )}
          </TabItemsStyled>
        </TabItemsScrollStyled>
        {hasNextScroll && showButtons && (
          <NavButtonContainerStyled
            $iconSide={DirectionTabsEnum.next}
            $isDesktop={isDesktop}
            $styles={styles}
            onClick={() => onChangeNewTab(DirectionTabsEnum.next)}
          >
            <NavButtonStyled $iconSide={DirectionTabsEnum.next} $styles={styles}>
              {!iconRight && <ImgDefaultNext />}
            </NavButtonStyled>
          </NavButtonContainerStyled>
        )}
      </TabItemsListStyled>
      <TabPanelStyled $styles={styles}>
        <NodeContainer block={block!} id={activeTabId} level={0} mode={mode} />
      </TabPanelStyled>
    </TabElementStyled>
  )
}

export default TabsElement

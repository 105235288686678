import { GroupingSchemaType, SCHEMA } from '@leenda/editor/lib/brand'

import { simplifyStyleValue } from 'services/Branding/constants/fields'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'
import { getParent } from 'utils/form'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.grouping.style, {
  group: {
    META: { label: t('elements.grouping.tags.group') },
    backgroundColor: {},
    borderRadius: { params: { min: 1, max: 50 } },
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  answer: {
    META: { label: t('elements.grouping.tags.answer') },
    backgroundColor: {},
    borderRadius: { params: { min: 1, max: 50 } },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  icon: {
    META: { label: t('elements.grouping.tags.icon') },
    backgroundImage: {
      label: t('elements.grouping.schema.answerIcon'),
      params: { preview: false },
    },
    color: {
      label: t('style.iconColor'),
    },
    backgroundColor: {},
  },
  icon_hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: (data, { name }) => {
        const { parent } = getParent<GroupingSchemaType>(name, data, 2)
        const borderStyle = simplifyStyleValue(parent?.answer?.borderStyle)
        return {
          hidden: borderStyle === 'none',
          transparent: true,
          resettable: true,
        }
      },
    },
  },
  progressResult__var: {
    META: { label: t('elements.grouping.tags.progress') },
    borderRadius: { params: { min: 1, max: 50 } },
    backgroundColor: { label: t('input.label.progressCompleted') },
  },
  progress: {
    META: { hidden: true },
    backgroundColor: { label: t('input.label.progressBackground') },
    height: { params: { placeholder: '4px', min: 1 } },
  },
  group_effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.grouping.schema.group_boxShadow') },
  },
  answer_effects: {
    META: { hidden: true },
    boxShadow: { label: t('elements.grouping.schema.answer_boxShadow') },
  },
  indents: {
    META: { label: t('elements.grouping.tags.indents') },
    padding: {
      label: t('elements.grouping.schema.cardIndents'),
      params: { min: 0 },
    },
    gap: {
      label: t('elements.grouping.schema.answerGap'),
      params: { width: 64 },
    },
    marginTop: {
      label: t('elements.grouping.schema.groupsGap'),
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.grouping.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.grouping.tags.text') },
  },
  h4: {
    ...FONT_SCHEMA_FIELDS.h4,
    META: { label: t('elements.grouping.tags.result') },
  },
})

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Link/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    icon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    preview: {
        flexDirection: (0, genStyle_1.genStyle)({ value: 'row' }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
    },
    image: {
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
        borderStyle: (0, genStyle_1.genStyle)({ value: 'none' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    effects: {
        boxShadow: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.boxShadow }),
    },
};
const fontSchema = {
    link: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), font_1.FONT_SCHEMA_EMPTY.link),
    h3: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.h3),
    base: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };

import { FileImageUsage, FileUsage, FileUsageImageSource } from '@leenda/editor/lib/files'
import cn from 'classnames'
import lodash from 'lodash'
import numeral from 'numeral'

import { IAsyncOnChange } from 'components/controls/Field/Field.types'
import ImageEditModal from 'components/modals/ImageEditModal'
import { KitSize } from 'components/uiKit/KitTypes'
import { getFileMetaByIdFromCache } from 'gql/files/apollo'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getEditorMode } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'
import { getImageByUsage } from 'utils/files'

import Button, { IconButton } from '../Button'
import { Tooltip } from '../Dropdown'
import Icon from '../Icon'
import Input from '../Input'
import { useOpenModal } from '../Modal'
import Segmented from '../Segmented'
import s from './FileLoader.module.scss'
import AudioPreview from './components/AudioPreview'
import VideoPreview from './components/VideoPreview/VideoPreview'
import { useFileHandlers } from './useFileHandlers'

type FieldFileValue = FileUsage | null
export type FileTypes = 'image' | 'audio' | 'video' | 'document' | 'captions'
export interface IFieldFileCommon {
  fileType: FileTypes
  placeholder?: string
  nullable?: boolean
  preview?: boolean
  showSource?: boolean
}

const isFileUsage = (value: FieldFileValue): value is FileUsage => !!value?.id

const isImageUsage = (value: FieldFileValue, fileType: FileTypes): value is FileImageUsage => {
  return (!!value?.id && value?.type === 'image') || fileType === 'image'
}

export const isFileType = (type: FileTypes, currentType: FileTypes) => type === currentType

const SOURCE_OPTIONS = [
  { label: t('input.option.file'), value: FileUsageImageSource.file },
  { label: t('input.option.link'), value: FileUsageImageSource.url },
]

type IFieldFile<V> = {
  value: V
  fileType: FileTypes
  onBlur: ((e?: unknown) => void) | undefined
  onFocus: ((e?: unknown) => void) | undefined
  name: string
  preview?: boolean
  nullable?: boolean
  placeholder?: string
  onChange: IAsyncOnChange<V>
  disabled?: boolean
  showSource?: boolean
}

const FileLoader = <V extends FieldFileValue = FieldFileValue>({
  value,
  fileType,
  onBlur,
  onFocus,
  onChange,
  name,
  showSource,
  ...rest
}: IFieldFile<V>) => {
  const { nullable, placeholder, preview, disabled } = rest
  const { source = FileUsageImageSource.file, url, id } = value || {}
  const { handlePickFile, handleResetFile, handleChangeSource, handleChangeUrl } = useFileHandlers({
    onChange,
    name,
    value,
    onFocus,
    onBlur,
    fileType,
  })
  const file = getFileMetaByIdFromCache(isFileUsage(value) ? id : undefined)
  const size = numeral(file?.size).format('0.0 b')
  const { name: fileName } = file || {}
  const fileUsage = getImageByUsage(file as FileImageUsage)

  const video = isFileType(fileType, 'video')
  const image = isFileType(fileType, 'image')
  const audio = isFileType(fileType, 'audio')
  const document = isFileType(fileType, 'document')
  const fileSource = source === FileUsageImageSource.file
  const emptyFile = !id && !url
  const emptySourceFile = !id && fileSource
  const emptyDescription = fileSource
    ? t('uiKit.fileLoader.placeholder')
    : t('uiKit.fileLoader.urlHint')

  const editFileImage = useOpenModal(ImageEditModal, { image: value as FileImageUsage })
  const editorMode = useProjectContext(getEditorMode)

  const openCropModal = async () => {
    const result = await editFileImage.open({})
    if (result) {
      const resultValue = lodash.omitBy(result, lodash.isUndefined)
      if (source === FileUsageImageSource.url) {
        const newValue = {
          url,
          accessibility: resultValue?.accessibility,
          source: FileUsageImageSource.url,
        }
        onChange({ name, value: newValue })
      } else if (isImageUsage(value, fileType)) {
        const { cropParams } = lodash.cloneDeep(lodash.omit(resultValue, ['accessibility']))
        const newValue = {
          id,
          params: cropParams,
          accessibility: resultValue?.accessibility,
          source: FileUsageImageSource.file,
        }
        onChange({ name, value: newValue })
      }
    }
  }

  return (
    <div className={s.root}>
      {showSource && (
        <Segmented
          name={`${name}-source`}
          onChange={handleChangeSource}
          options={SOURCE_OPTIONS}
          size={KitSize.S}
          value={source}
        />
      )}
      <div className={s.wrapper}>
        {preview && !document && fileType && (
          <div
            className={cn(s.preview, { [s.full]: image || video, [s.empty]: emptySourceFile })}
            onClick={emptySourceFile ? () => handlePickFile() : undefined}
          >
            {image && !emptyFile && (
              <img src={fileUsage ? fileUsage?.path : value && (value as FileUsage)?.url} />
            )}
            {video && (value?.id || value?.url) && <VideoPreview value={value} />}
            {audio && <AudioPreview value={value} />}
            {emptyFile && !audio && (
              <Icon
                className={cn(s.icon, { [s.active]: emptyFile })}
                name={fileSource ? 'add' : 'link2'}
              />
            )}
          </div>
        )}
        <div className={s.info}>
          {fileSource ? (
            <Tooltip overlay={fileName || undefined}>
              <div className={s.title}>{fileName}</div>
            </Tooltip>
          ) : (
            <Input
              disabled={disabled}
              name={name}
              onChange={handleChangeUrl}
              placeholder={placeholder || t('input.placeholder.pasteLink')}
              size={KitSize.S}
              value={url}
              autoFocus
            />
          )}
          <div
            className={cn(s.description, { [s.bold]: !file && fileSource, [s.link]: !fileSource })}
          >
            {file ? size : emptyDescription}
          </div>
          {fileSource && (
            <div className={s.footer}>
              <div className={s.buttons}>
                <Button
                  disabled={disabled}
                  name={`${name}.replace`}
                  onClick={handlePickFile}
                  size={KitSize.S}
                  styleType='secondary'
                >
                  {file ? t('uiKit.button.replace') : t('uiKit.button.upload')}
                </Button>
                {file && image && nullable && (
                  <Button
                    disabled={disabled}
                    name={`${name}.crop`}
                    onClick={openCropModal}
                    size={KitSize.S}
                    styleType='secondary'
                  >
                    {t('modal.editorCrop.title')}
                  </Button>
                )}
              </div>
              {file && nullable && (
                <IconButton
                  disabled={disabled}
                  icon='trashDeleteBin'
                  name={`${name}.delete`}
                  onClick={handleResetFile}
                  size={KitSize.S}
                  styleType='ghost'
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        {!emptyFile && image && editorMode && (
          <div className={s.fields}>
            <div className={s.label}>{t('input.label.accessibility')}</div>
            <div>
              <span className={s.editButton} onClick={openCropModal}>
                {t('uiKit.fileLoader.altText')}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default FileLoader

import { BoxShadowType } from '@leenda/editor/lib/brand'
import cn from 'classnames'

import { Typography } from 'components/uiKit/Typography'
import { t } from 'services/Translation'
import { testPropsEl } from 'utils/test/qaData'

import s from './Presets.module.scss'
import { ReactComponent as IconNone } from './none.svg'

export const PRESETS = [
  {
    key: 'none' as const,
    icon: <IconNone />,
    label: t('input.label.shadow.none'),
    boxShadow: 'none',
  },
  {
    key: 'soft' as const,
    label: t('input.label.shadow.soft'),
    boxShadow: '0px 0px 10px 0px #1B1B1B1A',
  },
  {
    key: 'regular' as const,
    label: t('input.label.shadow.regular'),
    boxShadow: '6px 6px 6px 0px #1B1B1B1A',
  },
  {
    key: 'retro' as const,
    label: t('input.label.shadow.retro'),
    boxShadow: '2px 2px 0px 0px #1B1B1B1A',
  },
]

interface IPresetsProps {
  name: string
  value: BoxShadowType['preset']
  onChange: (preset: BoxShadowType['preset']) => void
  disabled?: boolean
}

const Presets: React.FC<IPresetsProps> = ({ name, onChange, value }) => {
  const handleClick = (key: BoxShadowType['preset']) => () => onChange(key)

  return (
    <div className={s.root}>
      {PRESETS.map(({ key, label, boxShadow, icon }) => (
        <div
          {...testPropsEl('shadowRadio', { name, value: value === key })}
          className={cn(s.item, { [s.selected]: value === key })}
          key={key}
          onClick={handleClick(key)}
        >
          <div className={s.preview} style={{ boxShadow }}>
            {icon}
          </div>
          <Typography styleType='hint'>{label}</Typography>
        </div>
      ))}
    </div>
  )
}

export default Presets

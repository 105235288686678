import { useCompanyMe } from 'gql/companies/apollo'
import { useGetUserInfo } from 'gql/kusers/apollo'

export const useMyInfo = (companyId: string) => {
  const { data: currentUserData, loading: loadingCurrentUser } = useGetUserInfo()

  const { data: companyProfileData, loading: loadingInfo } = useCompanyMe(companyId)

  const currentUser = currentUserData?.data
  const companyProfile = companyProfileData?.data

  return {
    profile: companyProfile,
    user: currentUser,
    loading: loadingInfo || loadingCurrentUser,
  }
}

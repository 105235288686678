import { gql } from 'gql/__generated__'

export const getMyUserInfoQuery = gql(`
  query CompanyGetMyInfo($companyId: ID!) {
    data: getMyAndCompanyInfo(companyId: $companyId) {
      ...CompanyInfoSchema
    }
  }
`)

export const companyGetByIdQuery = gql(`
  query CompanyGet($companyId: ID!) {
    data: companiesGetById(companyId: $companyId) {
      ...CompanySchema
    }
  }
`)

export const companyGetByIdAdminQuery = gql(`
  query CompanyGetAdmin($companyId: ID!) {
    data: companiesGetById(companyId: $companyId) {
      ...CompanySchema
      ...CompanyAdminSchema
      isArchived
    }
  }
`)

export const companyGetAllQuery = gql(`
  query CompaniesAll($payload: CompaniesAllInput!) {
    data: companiesAll(payload: $payload) {
      companies {
        ...CompanyListSchema
        ...CompanyWithoutAccessSchema
      }
      total
      limit
      offset
    }
  }
`)

export const companyGetAllAdmin = gql(`
  query CompaniesAllAdmin($payload: CompaniesAllInput!) {
    data: companiesAll(payload: $payload) {
      companies {
        ...CompanyAdminFullSchema
        ...CompanyWithoutAccessSchema
      }
      total
      limit
      offset
    }
  }
`)

export const companyGetAllShort = gql(`
  query CompaniesAllShort($payload: CompaniesAllInput!) {
    data: companiesAll(payload: $payload) {
      companies {
        ...CompanySmallSchema
        ...CompanyWithoutAccessSchema
      }
      total
      limit
      offset
    }
  }
`)

export const companySlug = gql(`
  query GetCompanySlug ($companyId: ID!) {
    data: getSlug(companyId: $companyId)
  }
`)

import { SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CollapseIconPosition, ProgressIconPosition } from '@leenda/editor/lib/elements'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const getGridTemplateStyle = (styles: ElementStyleCss<SidebarSchemaType>, showIcon?: boolean) => {
  let gridTemplateColumnsValue = ''
  const topRow: string[] = []
  const bottomRow: string[] = []
  const progressValue = 'minmax(min-content, max-content) '
  const iconValue = 'minmax(min-content, max-content) '
  const sectionNameValue = 'minmax(min-content, auto) '

  if (styles.collapse.collapsePosition === CollapseIconPosition.leftOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (styles.verticalProgress.progressPosition === ProgressIconPosition.left) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (styles.collapse.collapsePosition === CollapseIconPosition.left) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  if (showIcon) {
    topRow.push('icon')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  topRow.push('section')
  bottomRow.push('description')
  gridTemplateColumnsValue += sectionNameValue

  if (styles.collapse.collapsePosition === CollapseIconPosition.right) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (styles.verticalProgress.progressPosition === ProgressIconPosition.right) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (styles.collapse.collapsePosition === CollapseIconPosition.rightOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  return css`
    grid-template-areas:
      '${topRow.join(' ')}'
      '${bottomRow.join(' ')}';
    grid-template-columns: ${gridTemplateColumnsValue};
  `
}

const getCoursor = (open: boolean, isChapter: boolean, disabled: boolean) => css`
  ${open && isChapter && 'n-resize'};
  ${!open && isChapter && 's-resize'};
  ${!isChapter && 'pointer'};
  ${disabled && 'not-allowed'};
`

const StructureNodeCurseStyled = styled.div<{
  $styles: ElementStyleCss<SidebarSchemaType>
  $active: boolean
  $disabled: boolean
  $open: boolean
  $isChapter: boolean
  $showIcon: boolean
}>`
  display: grid;
  align-items: center;
  margin-right: 32px;
  padding: 0 16px 16px;
  padding: 10px 8px 0;
  border-radius: 6px;
  overflow: hidden;
  padding-bottom: ${({ $styles }) => $styles?.margins.offsetYFromSection}px;
  cursor: ${({ $open, $isChapter, $disabled }) => getCoursor($open, $isChapter, $disabled)};

  &:hover {
    background-color: ${({ $styles }) => $styles.item_hover.backgroundColor};
  }
  background-color: ${({ $styles, $active }) => $active && $styles.item.backgroundColor};
  ${({ $styles, $showIcon }) => getGridTemplateStyle($styles, $showIcon)};
`

export default StructureNodeCurseStyled

import { useQuery, useMutation, useSubscription } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import { CompaniesAllQueryVariables } from 'gql/__generated__/graphql'

import { companiesUpdateCache, companyLogoUpdate } from './cache'
import {
  companiesDelete,
  companyCreate,
  companyForceDelete,
  companyUpdate,
  createSsoConfig,
  deleteSsoConfig,
  updateSsoConfig,
  updateCompanyOwner,
} from './gql/mutations'
import {
  companyGetAllAdmin,
  companyGetAllQuery,
  companyGetAllShort,
  companyGetByIdAdminQuery,
  companyGetByIdQuery,
  getMyUserInfoQuery,
} from './gql/queries'
import { companySubscribe } from './gql/subscriptions'

export const useCompaniesGetAll = (variables: CompaniesAllQueryVariables, isCache?: boolean) =>
  useQuery(companyGetAllQuery, {
    fetchPolicy: !isCache ? 'cache-only' : 'cache-and-network',
    variables,
    onError: (err) =>
      console.error('"useCompaniesGetAll" fn is crashed on operation: "useQuery"', err),
  })

export const useCompaniesGetAllShort = () =>
  useQuery(companyGetAllShort, {
    fetchPolicy: 'cache-and-network',
    variables: {
      payload: { pagination: { limit: 10, offset: 0 }, isArchived: false },
    },
  })

export const useCompaniesGetAllAdmin = (variables: CompaniesAllQueryVariables) =>
  useQuery(companyGetAllAdmin, {
    variables,
    onError: (err) =>
      console.error('"useCompanyGetAllAdmin" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyGetById = (id: string) =>
  useQuery(companyGetByIdQuery, {
    variables: {
      companyId: id,
    },
    skip: !id,
    onError: (err) =>
      console.error('"useCompanyGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyGetByIdAdmin = (id: string) =>
  useQuery(companyGetByIdAdminQuery, {
    variables: {
      companyId: id,
    },
    onError: (err) =>
      console.error('"useCompanyGetByIdAdmin" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyMe = (companyId: string, skip?: boolean) => {
  return useQuery(getMyUserInfoQuery, {
    variables: {
      companyId,
    },
    skip,
    onError: (err) =>
      console.error('"useCompanyGetMyInfo" fn is crashed on operation: "useQuery"', err),
  })
}

export const useCompanyCreate = () =>
  useMutation(companyCreate, {
    refetchQueries: [
      String(getOperationName(companyGetAllQuery)),
      String(getOperationName(companyGetAllShort)),
      String(getOperationName(companyGetAllAdmin)),
    ],
    onError: (err) =>
      console.error('"useCompanyCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useCompanyUpdate = () =>
  useMutation(companyUpdate, {
    onError: (err) =>
      console.error('"useCompanyUpdate" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(companyGetByIdQuery))],
  })

export const useCompanyForceDelete = () =>
  useMutation(companyForceDelete, {
    onError: (err) =>
      console.error('"useCompanyForceDelete" fn is crashed on operation: "useMutation"', err),
  })

export const useCompaniesDelete = () =>
  useMutation(companiesDelete, {
    refetchQueries: [String(getOperationName(companyGetAllAdmin))],
    onError: (err) =>
      console.error('"useCompaniesDelete" fn is crashed on operation: "useMutation"', err),
  })

export const useCreateSsoConfig = () => useMutation(createSsoConfig)
export const useDeleteSsoConfig = () =>
  useMutation(deleteSsoConfig, { refetchQueries: [String(getOperationName(companyGetByIdQuery))] })

export const useSsoUpdate = () => useMutation(updateSsoConfig)

export const useUpdateCompanyOwner = () => useMutation(updateCompanyOwner)

export const useSubscriptionCompanies = (userId: string) => {
  return useSubscription(companySubscribe, {
    onData: (options) => {
      const data = options.data.data?.data
      if (data && data.company) {
        switch (data.type) {
          case 'update':
            companyLogoUpdate(data.company)
            companiesUpdateCache(data.company, userId)
            break
        }
      }
    },
    variables: { userId },
  })
}

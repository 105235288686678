import cn from 'classnames'
import React, { useMemo } from 'react'

import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'
import { testPropsEl } from 'utils/test/qaData'

import s from './ColorCircle.module.scss'

interface IProps {
  color: string
  icon?: KeyIconsType
  size?: KitSize
  active?: boolean
  name?: string
  empty?: boolean
  transparent?: boolean
  onClick?: () => void
  onBlur?: () => void
  onFocus?: () => void
}

const ColorCircle: React.FC<IProps> = ({
  color,
  icon,
  size = KitSize.S,
  active,
  name,
  empty,
  transparent,
  onClick,
  onBlur,
  onFocus,
}) => {
  const iconName = useMemo(() => {
    if (icon) {
      return icon
    }
    if (transparent) {
      return 'iconsOtherNullBg'
    }
    return undefined
  }, [icon, transparent])

  if (empty) {
    return (
      <Tooltip overlay={t('uiKit.tooltip.addColor')} placement='topRight'>
        <IconButton icon='otherAdd' name='colorPicker' size={size} styleType='secondary' />
      </Tooltip>
    )
  }

  if (iconName) {
    return (
      <div
        {...testPropsEl('colorPicker', { name, value: color.slice(1, 7) }, { color })}
        className={cn(s.icon, s[size])}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        style={icon && { color }}
      >
        <Icon name={iconName} size={KitSize.S} />
      </div>
    )
  }

  return (
    <div
      {...testPropsEl('colorPicker', { name, value: color.slice(1, 7) }, { color })}
      className={cn(s.root, s[size], { [s.active]: active })}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
    >
      <div className={s.alphaBackground}>
        <div className={cn(s.color, s[size])} style={{ backgroundColor: `${color}` }} />
      </div>
    </div>
  )
}

export default ColorCircle

import { gql } from 'gql/__generated__'

export const participantsAll = gql(`
  query ParticipantsAll($projectId: String!, $data: ParticipantInput!) {
    data: participantsAll(projectId: $projectId, data: $data) {
      participants {
        ...ParticipantSchema
      }
      pagination {
        total
        offset
        limit
      }
    }
  }
`)

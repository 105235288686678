import {
  AudioFontSchemaType,
  AudioSchemaType,
  VideoFontSchemaType,
  VideoSchemaType,
} from '@leenda/editor/lib/brand'
import { TimePoint } from '@leenda/editor/lib/elements'
import { FileUsageImageSource } from '@leenda/editor/lib/files'
import { useInViewport } from 'ahooks'
import cn from 'classnames'
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ReactPlayer from 'react-player'
import { useContextSelector } from 'use-context-selector'
import { FileTypeEnum } from 'utils'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { MediaContext } from 'services/Media/MediaProvider'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getDeviceMode } from 'services/Store/Project/selectors'
import { getCurrentLocale } from 'services/Translation/i18n'
import { testProps } from 'utils/test/qaData'
import { useReducerWithMiddleware } from 'utils/useReducerWithMiddleware'

import Controls from './Controls'
import s from './MediaPlayer.module.scss'
import { DEFAULT_STYLES } from './constants'
import { encodeSilentBuffer } from './helper'
import { ReactComponent as Play } from './play.svg'
import { play, pause, setDuration, setProgress, togglePlay, update, seekTo } from './store/actions'
import { fullscreenMiddleware, reactPlayerMiddleware } from './store/middlewares'
import reducer from './store/reducer'
import { IState } from './store/types'
import PlayStyled from './styled/PlayStyled'
import { IProgressChange } from './types'

const IS_IOS = /iPhone/.test(navigator.userAgent)
const percentPlayed = (played: number, duration: number) => (played / duration) * 100

export interface IMediaPlayerProps {
  url: string | null
  type: FileTypeEnum.VIDEO | FileTypeEnum.AUDIO
  autoPlay?: boolean
  timePoints?: TimePoint[]
  controls?: boolean
  cover?: { path?: string; overlay?: string; objectFit?: string }
  volume?: number
  duration?: number
  start?: number
  loop?: boolean
  styles?: ElementStyleCss<VideoSchemaType> | ElementStyleCss<AudioSchemaType>
  fonts?: ElementFontCss<AudioFontSchemaType | VideoFontSchemaType>
  isEditor?: boolean
  playbackRate?: number
  onDuration?: (duration: number) => void
  onPlay?: () => void
  onReady?: () => void
  onProgress?: (progress: number) => void
  full?: boolean
  accessibility?: string
  captions?: string
  enableCCByDefault?: boolean
  sourceType?: FileUsageImageSource
}

let blob: string = ''

const MediaPlayer: React.VFC<IMediaPlayerProps> = ({
  url = '',
  type,
  styles = DEFAULT_STYLES,
  cover,
  timePoints,
  isEditor,
  onDuration,
  onPlay,
  onReady,
  fonts,
  start = 0,
  duration = 0,
  autoPlay = false,
  loop = false,
  controls = true,
  playbackRate = 1,
  volume = 1,
  onProgress,
  full = false,
  accessibility,
  captions,
  enableCCByDefault,
  sourceType,
}) => {
  const player = useRef<ReactPlayer>(null)
  const ref = useRef<HTMLDivElement>(null)
  const ccPayerRef = useRef<ReactPlayer>(null)
  const [inViewport, ratio] = useInViewport(ref, { threshold: [0, 1] })
  const [iosFullScreen, setIosFullScreen] = useState(false)
  const [showCC, setShowCC] = useState(enableCCByDefault || false)
  const activeMedia = useContextSelector(MediaContext, (context) => context?.activeMedia)
  const locale = getCurrentLocale()
  const setActiveMediaPlayer = useContextSelector(
    MediaContext,
    (context) => context?.setActiveMediaPlayer,
  )
  const clearActiveMediaPlayer = useContextSelector(
    MediaContext,
    (context) => context?.clearActiveMediaPlayer,
  )

  const device = useProjectContext(getDeviceMode)

  const [state, dispatch] = useReducerWithMiddleware(
    reducer,
    { started: false, playing: false, loop, playbackRate, start, duration, volume } as IState,
    [fullscreenMiddleware(ref), reactPlayerMiddleware(player)],
  )
  const mainStyles = useMemo(
    () => ({ ...styles.border, ...styles.player, ...fonts?.player, ...styles.effects }),
    [fonts?.player, styles.border, styles.player, styles.effects],
  )

  const showCover = Boolean(cover?.path && !state.playing && !state.played)
  const showMask = !state.playing
  const innerBorderRadius = parseInt(String(styles?.border?.borderRadius || '6px')) - 2

  const track = useMemo(
    () => ({
      kind: 'subtitles',
      src: captions || '',
      srcLang: locale.toLowerCase(),
      default: true,
      label: locale,
    }),
    [captions, locale],
  )

  const ST: CSSProperties = {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: 3,
    inset: type === FileTypeEnum.VIDEO ? 0 : -50,
  }

  const ccData = useMemo(() => {
    return {
      file: {
        attributes: {
          crossOrigin: 'true',
        },
        tracks: [track],
        forceVideo: true,
      },
    }
  }, [track])

  const setIosFullScreenMemo = useCallback(() => setIosFullScreen((prev) => !prev), [iosFullScreen])
  const handleFullScreenIos = IS_IOS ? setIosFullScreenMemo : undefined

  const handlePlayPause = () => dispatch(togglePlay())

  const handlePlay = () => {
    if (activeMedia?.instance !== ref.current) {
      setActiveMediaPlayer && setActiveMediaPlayer(ref.current, () => dispatch(pause()))
    }
    dispatch(play())
    onPlay && onPlay()
  }

  const handlePause = () => dispatch(pause())
  const handleDuration = (duration: number) => dispatch(setDuration(duration))
  const handleProgress = (progress: IProgressChange) => {
    ccPayerRef.current?.seekTo(progress.playedSeconds, 'seconds')
    const percent = percentPlayed(progress.playedSeconds, progress.loadedSeconds)

    if (onProgress) {
      onProgress(percent)
    }
    return dispatch(setProgress(progress))
  }

  const handleOnReady = () => {
    // TODO: tmp fix for ios continue video after fullscreen
    IS_IOS && state.playedSeconds && dispatch(seekTo(state.playedSeconds))
    onReady?.()
  }

  const handleError = useCallback(() => {
    onReady?.()
    dispatch(pause())
  }, [dispatch, onReady])

  useEffect(() => {
    if (inViewport && ratio == 1 && autoPlay && !state.started) {
      dispatch(play())
    }
    if (!inViewport) {
      dispatch(pause())
    }
  }, [inViewport, ratio, autoPlay, state.started])

  useEffect(() => {
    if (isEditor) {
      dispatch(update({ volume, playbackRate, start, duration, loop }))
    }
  }, [isEditor, volume, start, duration, loop, playbackRate])

  useEffect(() => {
    return () => {
      clearActiveMediaPlayer && clearActiveMediaPlayer(ref.current)
    }
  }, [clearActiveMediaPlayer])

  useEffect(() => {
    const update = async () => {
      if (!captions || sourceType === FileUsageImageSource.file) {
        return
      }
      blob = await encodeSilentBuffer(duration)
    }
    update()
  }, [duration, url, captions])

  useEffect(() => {
    if (!url) {
      setShowCC(false)
    }
  }, [url])

  const defaultPlayerConfig = {
    youtube: {
      // TODO: tmp fix for ios stop player after fullscreen if video is unstarted
      onUnstarted: () => IS_IOS && dispatch(pause()),
    },
  }

  const playerFileConfig = {
    ...defaultPlayerConfig,
    ...(sourceType === FileUsageImageSource.file ? ccData : {}),
  }

  const ReactPlayerComponent = (
    <div
      className={cn(s.root, { [s.fullscreen]: state.fullscreen, [s.full]: full })}
      ref={ref}
      style={mainStyles}
    >
      <div className={s.fixSwipe}>
        <div
          className={cn(s[type], s[device], {
            [s.fullscreen]: state.fullscreen,
            [s.full]: full,
            [s.showCC]: showCC,
          })}
          onClick={handlePlayPause}
          role='region'
          style={{
            borderRadius: full ? (isNaN(innerBorderRadius) ? 2 : innerBorderRadius) : undefined,
          }}
        >
          <ReactPlayer
            config={playerFileConfig}
            height='100%'
            key={`${captions} ${url}`}
            onDuration={onDuration || handleDuration}
            onError={handleError}
            onPause={handlePause}
            onPlay={handlePlay}
            onProgress={handleProgress}
            onReady={handleOnReady}
            playbackRate={state.playbackRate}
            playing={state.playing}
            // https://www.notion.so/1179-8434fc9b1d534581819ef9861a34facb?pvs=23
            progressInterval={100}
            ref={player}
            style={{ position: 'relative' }}
            url={url || ''}
            volume={state.volume}
            width='100%'
            playsinline
          />
          {sourceType === 'url' && captions && (
            <div className={s.ccPlayer}>
              <ReactPlayer
                config={ccData}
                height='100%'
                key={`${captions} ${url}`}
                playbackRate={state.playbackRate}
                playing={state.playing}
                // https://www.notion.so/1179-8434fc9b1d534581819ef9861a34facb?pvs=23
                progressInterval={100}
                ref={ccPayerRef}
                style={ST}
                url={blob}
                width='100%'
                playsinline
              />
            </div>
          )}
          {showCover && (
            <img
              alt={accessibility}
              aria-label={accessibility}
              className={s.cover}
              src={cover?.path || ''}
              style={{ objectFit: cover?.objectFit as CSSProperties['objectFit'] }}
            />
          )}
          {showMask && type === FileTypeEnum.VIDEO && (
            <div className={s.mask} style={{ backgroundColor: cover?.overlay }}>
              <PlayStyled
                {...testProps({ el: 'playMedia' })}
                $full={full}
                $styles={styles as ElementStyleCss<VideoSchemaType>}
              >
                <Play />
              </PlayStyled>
            </div>
          )}
        </div>
        {controls && (
          <div className={cn(s.controls, { [s.inline]: !full && type === FileTypeEnum.VIDEO })}>
            <Controls
              audioPreview={!full && type === FileTypeEnum.AUDIO}
              dispatch={dispatch}
              fonts={fonts}
              full={full}
              handleFullScreenIos={handleFullScreenIos}
              hasCC={Boolean(captions)}
              isEditor={isEditor}
              setShowCC={setShowCC}
              showCC={showCC}
              state={state}
              styles={styles}
              timePoints={timePoints}
              type={type}
            />
          </div>
        )}
      </div>
    </div>
  )

  return iosFullScreen && IS_IOS
    ? createPortal(<div className={s.wrapIos}>{ReactPlayerComponent}</div>, document.body)
    : ReactPlayerComponent
}
export default MediaPlayer

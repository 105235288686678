import { AccordionSchemaType } from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import { transformArrayToString } from './styled'

const ContentStyled = styled.div<{ $styles: ElementStyleCss<AccordionSchemaType> }>`
  background-color: ${({ $styles }) => $styles.collapse.backgroundColor};
  padding: ${({ $styles }) => transformArrayToString($styles.indents.content)};
`

export default ContentStyled

import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './DownloaderBrandFormSchema'
import Demo from './DownloaderDemo'
import Element from './DownloaderElement'
import Form from './DownloaderForm'
import downloaderIcon from './assets/downloader.svg'

export default genElementConfig(CONFIG_ELEMENTS.downloader, {
  label: t('elements.downloader.label'),
  group: ElementGroup.interactive,
  icon: downloaderIcon,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
})

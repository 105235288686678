import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './LinkBrandFormSchema'
import Demo from './LinkDemo'
import Element from './LinkElement'
import Form from './LinkElementForm'
import linkIcon from './assets/link.svg'

export default genElementConfig(CONFIG_ELEMENTS.link, {
  label: t('elements.link.label'),
  icon: linkIcon,
  group: ElementGroup.interactive,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
})

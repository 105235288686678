import {
  PositionTabsElementType,
  SizeTabsElementType,
  TabsFontSchemaType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { font } from 'services/Branding/common.styled'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

import { SPLITTER_WIDTH, Z_INDEX_TEXT_AND_SPLITER } from './TabElementStyled'

const solidTop = (styles: ElementStyleCss<TabsSchemaType>) => css`
  border-radius: 0;
  border-top-left-radius: ${styles.view.borderRadius};
  border-top-right-radius: ${styles.view.borderRadius};
`

const solidLeft = (styles: ElementStyleCss<TabsSchemaType>) => css`
  border-radius: 0;
  border-top-left-radius: ${styles.view.borderRadius};
  border-bottom-left-radius: ${styles.view.borderRadius};
`

const solidRight = (styles: ElementStyleCss<TabsSchemaType>) => css`
  border-radius: 0;
  border-top-right-radius: ${styles.view.borderRadius};
  border-bottom-right-radius: ${styles.view.borderRadius};
`

const solid = (styles: ElementStyleCss<TabsSchemaType>) => {
  const position = styles.tabs.position as PositionTabsElementType
  return css`
    ${position === PositionTabsElementType.top && solidTop(styles)}
    ${position === PositionTabsElementType.left && solidLeft(styles)}
    ${position === PositionTabsElementType.right && solidRight(styles)}
  `
}

const solidDividerTop = (styles: ElementStyleCss<TabsSchemaType>) => css`
  &:before {
    top: calc(${SPLITTER_WIDTH} / 2);
    width: ${styles.splitter.borderWidth};
    height: calc(100% - ${SPLITTER_WIDTH});
    left: calc(-1 * (${styles.border.borderWidth} + (${styles.splitter.borderWidth} / 2)));
    border-right: ${`${styles.splitter.borderWidth} ${styles.splitter.borderStyle} ${styles.splitter.borderColor}`};
  }
`

const solidDividerLeft = (styles: ElementStyleCss<TabsSchemaType>) => css`
  &:before {
    left: calc(${SPLITTER_WIDTH} / 2);
    height: ${styles.splitter.borderWidth};
    width: calc(100% - ${SPLITTER_WIDTH});
    top: calc(-1 * (${styles.border.borderWidth} + (${styles.splitter.borderWidth} / 2)));
    border-top: ${`${styles.splitter.borderWidth} ${styles.splitter.borderStyle} ${styles.splitter.borderColor}`};
  }
`

const solidDividerRight = (styles: ElementStyleCss<TabsSchemaType>) => css`
  &:before {
    right: calc(${SPLITTER_WIDTH} / 2);
    height: ${styles.splitter.borderWidth};
    width: calc(100% - ${SPLITTER_WIDTH});
    top: calc(-1 * (${styles.border.borderWidth} + (${styles.splitter.borderWidth} / 2)));
    border-top: ${`${styles.splitter.borderWidth} ${styles.splitter.borderStyle} ${styles.splitter.borderColor}`};
  }
`

const getDividerSolid = (styles: ElementStyleCss<TabsSchemaType>) => {
  const position = styles.tabs.position as PositionTabsElementType
  return css`
    ${position === PositionTabsElementType.top && solidDividerTop(styles)}
    ${position === PositionTabsElementType.left && solidDividerLeft(styles)}
  ${position === PositionTabsElementType.right && solidDividerRight(styles)}
  `
}

const notActiveTabs = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  padding: ${$styles.indents.padding};
  &:hover {
    background-color: ${$styles.hover.backgroundColor};
    padding: ${$styles.indents.padding};
    margin: 0;
  }
  &:before {
    z-index: ${Z_INDEX_TEXT_AND_SPLITER};
    content: '';
    position: absolute;
  }
  ${$styles.view.type === ViewTabsElementType.solid && getDividerSolid($styles)}
`

const splitted = (styles: ElementStyleCss<TabsSchemaType>) => {
  const { borderWidth, borderStyle, borderColor } = styles.borderTab
  return css`
    border: ${borderWidth} ${borderStyle} ${borderColor};
    border-radius: ${styles.borderTab.borderRadius};
    padding: ${styles.indents.padding};
    margin: 0;
    background-color: ${styles.tabs.backgroundColor};
    box-shadow: ${styles.effects.boxShadow};
    & + li:before {
      content: none;
    }
  `
}

const activeTab = css`
  &:before,
  & + li:before {
    content: none;
  }
`

const tabsFixed = ($styles: ElementStyleCss<TabsSchemaType>) => css`
  max-width: ${$styles.tabs.width};
  width: ${$styles.tabs.width};
`

const TabItemStyled = styled(motion.li)<{
  $styles: ElementStyleCss<TabsSchemaType>
  $font: ElementFontCss<TabsFontSchemaType>
  $active: boolean
}>`
  transition: 0.2s;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-right: 0;
  border-left: 0;
  flex-grow: 0;
  background: ${({ $styles }) => $styles.tabs.backgroundColor};
  cursor: pointer;
  padding: ${({ $styles }) => $styles.indents.padding};
  border: ${({ $styles }) =>
    `${$styles.border.borderWidth} ${$styles.border.borderStyle} transparent`};
  ${({ $font }) => font($font.tabs)}
  ${({ $styles }) => $styles.tabs.tabSize === SizeTabsElementType.fixed && tabsFixed($styles)};
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.solid && solid($styles)};
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.splitted && splitted($styles)};
  ${({ $styles, $active }) => !$active && notActiveTabs($styles)};
  ${({ $active }) => $active && activeTab};
  &[data-active='true'] + :not([data-active='true'])::before,
  &:nth-of-type(1):not([data-active='true'])::before {
    content: none;
  }
`

export default TabItemStyled

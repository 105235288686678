import React, { useRef } from 'react'

import { ConfirmModal } from 'components/modals/ConfirmModal/ConfirmModal'
import Card from 'components/uiKit/Card/Card'
import Highlighter from 'components/uiKit/Highlighter/Highlighter'
import { KitSize } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import { useOpenModal } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { Typography } from 'components/uiKit/Typography'
import { stopPropagation } from 'constants/commonConstans'
import {
  TemplateCollectionCreatedFor,
  TemplateCollectionsSchemaCountFragment,
} from 'gql/__generated__/graphql'
import { useGetBrand } from 'gql/brands/apollo'
import { useTemplateGroupDelete, useTemplateGroupDuplicate } from 'gql/templates/apollo'
import { t } from 'services/Translation'
import { extendTestData, testProps } from 'utils/test/qaData'

import EditCollectionModal from '../modals/EditCollectionModal'
import { CollectionActionUiEnum } from '../types'
import s from './TemplateCard.module.scss'
import TemplateCardContainer from './TemplateCardContainer'
import { ReactComponent as employeeSvg } from './folders-folder-heart-favorite.svg'
import { ReactComponent as companySvg } from './folders-folder-open-style-2.svg'
import { ReactComponent as projectSvg } from './folders-folder-open-style-2.svg'
import { ReactComponent as generalSvg } from './icons-other-folder-checkmark.svg'

const ICONS_MAP = {
  [TemplateCollectionCreatedFor.general]: generalSvg,
  [TemplateCollectionCreatedFor.employee]: employeeSvg,
  [TemplateCollectionCreatedFor.company]: companySvg,
  [TemplateCollectionCreatedFor.project]: projectSvg,
}

interface ITemplateCardProps {
  item: TemplateCollectionsSchemaCountFragment
  color?: string
  companyId: string
  actions?: IMenuOption<CollectionActionUiEnum>[]
  onClick?: ({
    id,
    name,
    createdFor,
    templatesCount,
  }: {
    id: string
    name: string
    createdFor: TemplateCollectionCreatedFor
    templatesCount: number
  }) => void
  search?: string
  showBrand?: boolean
}

const TemplateCard = ({
  item: { id, name, createdFor, templatesCount, brandId },
  color,
  companyId,
  actions,
  onClick,
  search,
  showBrand,
}: ITemplateCardProps) => {
  const [duplicateGroupTemplate] = useTemplateGroupDuplicate()
  const [deleteGroupTemplate] = useTemplateGroupDelete()
  const selected = useRef('')
  const updateTemplateGroupModal = useOpenModal(EditCollectionModal, {
    companyId,
    createdFor,
  })
  const confirmDeleteGroup = useOpenModal(ConfirmModal, {})
  const { data: loadedBrandData } = useGetBrand(brandId || '', !brandId)

  const IconComponent = ICONS_MAP[createdFor]

  const onClickActions = async ({ value }: IMenuOption<CollectionActionUiEnum>) => {
    selected.current = id
    if (value === CollectionActionUiEnum.DUPLICATE) {
      const { data } = await duplicateGroupTemplate({ variables: { companyId, id } })
      if (data) {
        notify({
          type: NotificationType.success,
          message: t('notify.objectsDuplicate', { count: 1 }),
        })
      }
    } else if (value === CollectionActionUiEnum.EDIT) {
      updateTemplateGroupModal.open({ selected: selected.current })
    } else if (value === CollectionActionUiEnum.DELETE) {
      const confirm = await confirmDeleteGroup.open({})
      if (confirm) {
        const { data } = await deleteGroupTemplate({ variables: { id } })
        if (data) {
          notify({ type: NotificationType.success, message: t('notify.delete', { count: 1 }) })
        }
      }
    }
  }

  return (
    <TemplateCardContainer
      actions={actions}
      collectionId={id}
      companyId={companyId}
      templatesCount={templatesCount}
    >
      <Card
        actions={actions}
        name='templateCollection'
        onClick={stopPropagation}
        onClickActions={onClickActions}
        size={KitSize.XS}
        testData={extendTestData({ label: name })}
        fluid
        hiddenActions
      >
        <div className={s.info} onClick={() => onClick?.({ id, name, createdFor, templatesCount })}>
          <div className={s.iconWrapper}>
            <div className={s.icon} style={{ color }}>
              <IconComponent />
            </div>
          </div>
        </div>
      </Card>
      {name && (
        <>
          <div
            className={s.name}
            {...testProps({ el: 'templateCard', name, id })}
            onClick={() => onClick?.({ id, name, templatesCount, createdFor })}
          >
            <div className={s.textWrap}>
              <Typography name={name} rows={1} styleType='link' tooltip={name}>
                <Highlighter search={search} text={name} />
              </Typography>
            </div>
            <span className={s.counter}>{templatesCount}</span>
          </div>
          {showBrand && (
            <div className={s.brand}>{loadedBrandData?.data?.name || t('common.platformName')}</div>
          )}
        </>
      )}
    </TemplateCardContainer>
  )
}

export default React.memo(TemplateCard)

import {
  PositionTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const solidBorderTopPosition = (
  borderRadius: ElementStyleCss<TabsSchemaType>['view']['borderRadius'],
) => css`
  border-top: none;
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`

const solidBorderLeftPosition = (
  borderRadius: ElementStyleCss<TabsSchemaType>['view']['borderRadius'],
) => css`
  border-left: none;
  border-bottom-right-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
`

const solidBorderRightPosition = (
  borderRadius: ElementStyleCss<TabsSchemaType>['view']['borderRadius'],
) => css`
  border-right: none;
  border-bottom-left-radius: ${borderRadius};
  border-top-left-radius: ${borderRadius};
`

const solid = ($styles: ElementStyleCss<TabsSchemaType>) => {
  const position = $styles.tabs.position as PositionTabsElementType
  const borderRadius = $styles.view.borderRadius
  return css`
    ${position === PositionTabsElementType.top && solidBorderTopPosition(borderRadius)}
    ${position === PositionTabsElementType.left && solidBorderLeftPosition(borderRadius)}
    ${position === PositionTabsElementType.right && solidBorderRightPosition(borderRadius)}
  `
}

const splitted = (styles: ElementStyleCss<TabsSchemaType>) => css`
  border: ${styles.content.borderWidth} ${styles.content.borderStyle} ${styles.content.borderColor};
  border-radius: ${styles.content.borderRadius};
  box-shadow: ${styles.content_effects.boxShadow};
`

const TabPanelStyled = styled.div<{ $styles: ElementStyleCss<TabsSchemaType> }>`
  display: flex;
  flex-grow: 1;
  margin-left: 0;
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
  background-color: ${({ $styles }) => $styles.content.backgroundColor};
  border: ${({ $styles }) =>
    `${$styles.border.borderWidth} ${$styles.border.borderStyle} ${$styles.border.borderColor}`};
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.solid && solid($styles)}
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.splitted && splitted($styles)};
  padding: ${({ $styles }) => $styles.indents.margin};
`

export default TabPanelStyled

import { SplitterFontSchemaType, SplitterSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, SplitterElementValue } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import { useState } from 'react'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps, DEPRECATED_Position } from '../../elements.types'
import { DEFAULT_SPLITTER_POSITION } from './SplitterConstants'
import Element from './SplitterElement'
import { SplitterElementState } from './SplitterElement.types'

const DEMO_VALUE: SplitterElementValue = {
  leftBlock: {
    image: null,
    showImage: false,
    text: textToRtValue(''),
    overlay: '#FFFFFF00',
  },
  rightBlock: {
    image: null,
    showImage: false,
    text: textToRtValue(''),
    overlay: '#FFFFFF00',
  },
  position: DEFAULT_SPLITTER_POSITION,
}

const DEMO_POSITION: DEPRECATED_Position = {
  height: 674,
}

const SplitterDemo: React.FC<IElementDemoProps<SplitterSchemaType, SplitterFontSchemaType>> = ({
  styles,
  fonts,
}) => {
  const [state, setState] = useState<SplitterElementState | undefined>()

  const handleSetState = (value: SplitterElementState) =>
    setState((prev) => ({ ...prev, ...value }))
  return (
    <DemoWrapper position={DEMO_POSITION}>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.splitter.type}-demo`,
          type: CONFIG_ELEMENTS.splitter.type,
          value: DEMO_VALUE,
          style: {},
          font: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        setState={handleSetState}
        state={state}
        styles={styles}
      />
    </DemoWrapper>
  )
}
export default SplitterDemo

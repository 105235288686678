import { useInViewport } from 'ahooks'
import cn from 'classnames'
import classNames from 'classnames'
import { get } from 'lodash'
import React, { useEffect, ReactNode, useContext, useRef } from 'react'

import Empty from 'components/uiKit/Empty'
import { EMPTY_ARRAY } from 'constants/commonConstans'
import { t } from 'services/Translation'
import { extendTestData, testProps } from 'utils/test/qaData'

import Checkbox from '../Checkbox'
import Loader from '../Loader'
import ColumnTitle from './ColumnTitle'
import HeaderCheckbox from './HeaderCheckbox'
import s from './Table.module.scss'
import { TableContext } from './TableContext'
import { TablePagination, useActualTotal } from './index'
import { ColumnType, ITablesExtendsData, TableProps } from './types'

interface ITableCellProps {
  name?: string
  sticky?: boolean
  renderCell: () => ReactNode
  value?: string
  align?: ColumnType<unknown>['align']
  width?: string | number
  index: number
}

const TableCell = ({
  sticky = false,
  renderCell,
  value,
  align,
  width,
  index,
  name,
}: ITableCellProps) => {
  const alignStyle = align ? s[align] : s.left

  return (
    <td
      className={cn({ [s.sticky]: sticky }, alignStyle)}
      style={{ minWidth: width, maxWidth: width, overflow: 'hidden', textOverflow: 'ellipsis' }}
      {...testProps({ el: 'tableCell', name, label: value, index: index })}
    >
      {renderCell()}
    </td>
  )
}

const defaultRender: ColumnType<unknown>['render'] = (value) => {
  return value ? String(value) : ''
}

const TableCore = <T extends ITablesExtendsData>({
  checked,
  columns,
  dataSource,
  footer,
  total = dataSource.length,
  onClickRow,
  rowClassName,
  className,
  pagination = true,
  loading,
  onChangeSelection,
  name,
  selectedItems,
  getCheckboxProps,
  disableRow,
  empty,
}: TableProps<T>) => {
  const { onLoadMore, hasNextPage } = useContext(TableContext)

  const ref = useRef<HTMLTableRowElement | null>(null)

  const actualTotal = useActualTotal(total, !loading)

  const [inViewport] = useInViewport(ref)

  useEffect(() => {
    if (inViewport && onLoadMore && hasNextPage) {
      onLoadMore()
    }
  }, [inViewport, onLoadMore, hasNextPage])

  useEffect(() => {
    onChangeSelection && onChangeSelection(EMPTY_ARRAY)
  }, [dataSource])

  useEffect(() => {
    return () => {
      onChangeSelection && onChangeSelection([])
    }
  }, [])

  const onClickRowHandler = (record: T, event: React.MouseEvent) =>
    onClickRow && onClickRow(event, record, 'view')

  const handleCheck = (index: number) => (value: boolean) => {
    if (onChangeSelection && selectedItems) {
      onChangeSelection(
        value
          ? [...selectedItems, dataSource[index]]
          : selectedItems.filter(({ id }) => id !== dataSource[index].id),
      )
    }
  }

  const showTable = dataSource.length > 0 && !loading
  const showEmpty = !dataSource.length && !loading

  return (
    <div
      className={cn(s.root, className, {
        [s.emptyTable]: Boolean(!dataSource.length),
      })}
    >
      <div className={cn(s.tableLayout)}>
        <div className={s.tableWrapper}>
          <table>
            <thead>
              <tr className={s.header}>
                {checked && (
                  <th className={cn(s.checkboxHeader, s.stickyCheckbox)}>
                    <HeaderCheckbox
                      dataSource={dataSource}
                      getCheckboxProps={getCheckboxProps}
                      onChangeSelection={onChangeSelection}
                      selected={selectedItems}
                    />
                  </th>
                )}
                {columns.map((config, colIndex) => (
                  <th
                    className={cn({
                      [s.sticky]: colIndex === 0,
                      [s.noBordersHeader]: config.title === undefined,
                    })}
                    key={config.title?.toString() || colIndex}
                    style={{
                      maxWidth: config.width,
                      minWidth: config.width,
                      textAlign: config.align,
                      overflow: 'hidden',
                    }}
                  >
                    <ColumnTitle config={config} name={name} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={s.body}>
              {showTable &&
                dataSource.map((row, index) => (
                  <tr
                    className={classNames(rowClassName?.(row, index), {
                      [s.disabledRow]: disableRow && disableRow(row, index),
                    })}
                    key={index}
                    onClick={(e) => onClickRowHandler(row, e)}
                  >
                    {checked && selectedItems && (
                      <td className={s.stickyCheckbox}>
                        <Checkbox
                          disabled={getCheckboxProps?.(row).disabled}
                          onChange={handleCheck(index)}
                          value={selectedItems.some(({ id }) => id === row.id)}
                          name='tableCheckbox'
                          // name={`${name}-row-${index}`}
                          testData={extendTestData({
                            index,
                            label:
                              getCheckboxProps?.(row)?.name ||
                              (typeof row.name === 'string' ? row.name : row.name?.props.text),
                          })}
                        />
                      </td>
                    )}
                    {columns.map(({ dataIndex, render: renderCell, align, width }, colIndex) => (
                      <TableCell
                        align={align}
                        index={index}
                        key={typeof dataIndex === 'string' ? dataIndex : dataIndex?.join('.')}
                        name={typeof dataIndex === 'string' ? dataIndex : dataIndex?.join('.')}
                        renderCell={() =>
                          renderCell?.(dataIndex ? get(row, dataIndex) : undefined, row, index) ||
                          defaultRender(dataIndex ? get(row, dataIndex) : undefined, row, index)
                        }
                        sticky={colIndex === 0}
                        value={dataIndex ? String(get(row, dataIndex)) : ''}
                        width={width}
                      />
                    ))}
                  </tr>
                ))}
              {onLoadMore && hasNextPage && (
                <tr className={s.loaderRow} ref={ref}>
                  <td className={s.loader}>
                    <Loader name='loadMoreLoader' />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {!!footer && showTable && <div className={s.tableFooter}>{footer}</div>}
        {pagination && (
          <div className={s.wrapperPagination}>
            <TablePagination total={actualTotal} />
          </div>
        )}
      </div>
      {showEmpty && (
        <div className={s.emptyWrapper}>
          <Empty text={t('common.nothingFound')} {...empty} grow />
        </div>
      )}
      {loading && <Loader name={`${name}.tableLoader`} />}
    </div>
  )
}

export default TableCore

import { FileUsageImageSource, FileUsageTypeEnum } from '@leenda/editor/lib/files'

import { IControlProps } from 'components/controls/Field/Field.types'
import FileLoader from 'components/uiKit/FileLoader'
import { IFieldFileCommon } from 'components/uiKit/FileLoader/FileLoader'

export interface IFieldFileExtended extends IFieldFileCommon {
  value: string
}

const FieldFileId: React.FC<IControlProps<IFieldFileCommon>> = ({
  value,
  onChange,
  name,
  fileType,
  placeholder,
  defaultValue,
  onFocus,
  onBlur,
  showSource,
  ...rest
}) => {
  const onChangeFile = ({ name, value }: { name: string; value?: { id?: string } | null }) => {
    onChange?.({ name, value: value?.id })
  }
  const id = (value || defaultValue) as string

  return (
    <FileLoader
      fileType={fileType}
      name={name}
      onBlur={onBlur}
      // @ts-ignore
      onChange={onChangeFile}
      onFocus={onFocus}
      showSource={showSource}
      source={FileUsageImageSource.file}
      value={id ? { id, type: FileUsageTypeEnum.image } : null}
      {...rest}
    />
  )
}

export default FieldFileId

import { AccordionSchemaType } from '@leenda/editor/lib/brand'
import { AccordionType } from '@leenda/editor/lib/elements'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const solid = (styles: ElementStyleCss<AccordionSchemaType>) => css`
  overflow: hidden;
  background-color: ${styles.collapse.backgroundColor};
  gap: ${styles.indents.gap};
  border-style: ${styles.border.borderStyle};
  border-color: ${styles.border.borderColor};
  border-width: ${styles.border.borderWidth};
  border-radius: ${styles.border.borderRadius};
  box-shadow: ${styles.effects.boxShadow};
`

const splitted = (styles: ElementStyleCss<AccordionSchemaType>) => css`
  gap: ${styles.indents.gap};
`

const RootStyled = styled.div<{ $styles: ElementStyleCss<AccordionSchemaType> }>`
  display: flex;
  flex-direction: column;
  ${({ $styles }) => $styles.collapse.type === AccordionType.SPLITTED && splitted($styles)}
  ${({ $styles }) => $styles.collapse.type === AccordionType.SOLID && solid($styles)}
`

export default RootStyled

import {
  ChartFontSchemaType,
  ChartSchemaType,
  KEYS,
  setThemeVariable,
} from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, ChartElementValue } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './ChartElement'

const primaryColor = setThemeVariable(KEYS.primaryColor)

const DEMO_LINE_VALUE: ChartElementValue = {
  type: 'line',
  description: true,
  x: 'X',
  y: 'Y',
  items: [
    { label: 'item 1', value: generateId(), val: 10 },
    { label: 'item 2', value: generateId(), val: 20 },
    { label: 'item 3', value: generateId(), val: 15 },
  ],
}

const DEMO_BAR_VALUE: ChartElementValue = {
  type: 'bar',
  description: true,
  x: 'X',
  y: 'Y',
  items: [
    { label: 'item 1', value: generateId(), val: 10, color: primaryColor },
    { label: 'item 2', value: generateId(), val: 20, color: primaryColor },
    { label: 'item 3', value: generateId(), val: 15, color: primaryColor },
  ],
}

const DEMO_PIE_VALUE: ChartElementValue = {
  type: 'pie',
  description: true,
  x: 'X',
  y: 'Y',
  items: [
    { label: 'item 1', value: generateId(), val: 10, color: primaryColor },
    { label: 'item 2', value: generateId(), val: 20, color: primaryColor },
    { label: 'item 3', value: generateId(), val: 15, color: primaryColor },
  ],
}

const LineChartDemo: React.FC<IElementDemoProps<ChartSchemaType, ChartFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.chart.type}-demo`,
        type: CONFIG_ELEMENTS.chart.type,
        value: DEMO_LINE_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

const BarChartDemo: React.FC<IElementDemoProps<ChartSchemaType, ChartFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.chart.type}-demo`,
        type: CONFIG_ELEMENTS.chart.type,
        value: DEMO_BAR_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)
const PieChartDemo: React.FC<IElementDemoProps<ChartSchemaType, ChartFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.chart.type}-demo`,
        type: CONFIG_ELEMENTS.chart.type,
        value: DEMO_PIE_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)
export { LineChartDemo, BarChartDemo, PieChartDemo }

import InputNumber from 'components/uiKit/InputNumber'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import { IImageEditValue } from '../ImageEditModal'
import s from './SizeFields.module.scss'

interface ISizeFieldsProps {
  localValue: IImageEditValue
  setLocalValue: (val: React.SetStateAction<IImageEditValue>) => void
  ih?: number
  iw?: number
  imgElement: HTMLImageElement | null
}

const SizeFields = ({ localValue, setLocalValue, ih, iw, imgElement }: ISizeFieldsProps) => {
  const updateCropParams = (dimension: 'width' | 'height', newValue: string | number) => {
    const value = Number(newValue) || 0

    setLocalValue((val: IImageEditValue) => {
      const { cropParams } = val
      const { aspect, crop } = cropParams || {}
      const { width = iw, height = ih } = crop || {}

      const calculateDimensions = () => {
        if (aspect && imgElement) {
          return dimension === 'width'
            ? { width: value, height: Math.round(value / aspect) }
            : { width: Math.round(value * aspect), height: value }
        }

        return {
          width: (dimension === 'width' ? value : width) as number,
          height: (dimension === 'height' ? value : height) as number,
        }
      }
      const newDimensions = calculateDimensions()
      return {
        ...val,
        cropParams: {
          ...cropParams,
          crop: {
            ...crop,
            ...newDimensions,
            unit: 'px',
            x: crop?.x || 0,
            y: crop?.y || 0,
          },
        },
      }
    })
  }

  const handleWidthChange = (newWidth: string | number) => updateCropParams('width', newWidth)

  const handleHeightChange = (newHeight: string | number) => updateCropParams('height', newHeight)

  return (
    <>
      <div className={s.group}>{t('common.settings')}</div>
      <div className={s.fieldLayout}>
        <div className={s.label}>{t('input.label.size')}</div>
        <div className={s.field}>
          <InputNumber
            max={iw}
            min={0}
            name='width'
            onChange={handleWidthChange}
            prefix={<span className={s.prefix}>{t('modal.editorCrop.placeholderW')}:&nbsp; </span>}
            size={KitSize.S}
            value={localValue.cropParams?.crop?.width ?? iw}
            width={78}
          />
          <InputNumber
            max={ih}
            min={0}
            onChange={handleHeightChange}
            prefix={<span className={s.prefix}>{t('modal.editorCrop.placeholderH')}:&nbsp; </span>}
            size={KitSize.S}
            value={localValue.cropParams?.crop?.height ?? ih}
            width={78}
          />
        </div>
      </div>
    </>
  )
}

export default SizeFields

import {
  PositionTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import NavButtonContainerStyled from './NavButtonContainerStyled'
import { Z_INDEX_TABS_LIST } from './TabElementStyled'
import { OVERFLOW_OFFSET } from './TabItemsScrollStyled'

const bottomLineByTopTabs = (border: ElementStyleCss<TabsSchemaType>['border']) => css`
  &:before {
    right: 0;
    left: 0;
    bottom: 0;
    content: '';
    border-bottom: ${border.borderWidth} ${border.borderStyle} ${border.borderColor};
  }
`

const bottomLineByLeftTabs = (border: ElementStyleCss<TabsSchemaType>['border']) => css`
  align-items: flex-start;
  &:before {
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-left: ${border.borderWidth} ${border.borderStyle} ${border.borderColor};
  }
`

const bottomLineByRightTabs = (border: ElementStyleCss<TabsSchemaType>['border']) => css`
  align-items: flex-start;
  &:before {
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
    border-right: ${border.borderWidth} ${border.borderStyle} ${border.borderColor};
  }
`

const getBottomLineByPosition = ($styles: ElementStyleCss<TabsSchemaType>) => {
  const position = $styles.tabs.position as PositionTabsElementType
  return css`
    ${position === PositionTabsElementType.top && bottomLineByTopTabs($styles.border)};
    ${position === PositionTabsElementType.left && bottomLineByLeftTabs($styles.border)};
    ${position === PositionTabsElementType.right && bottomLineByRightTabs($styles.border)};
  `
}

const splitted = css`
  max-width: calc(100% + ${OVERFLOW_OFFSET * 2}px);
  margin: -${OVERFLOW_OFFSET}px;
  pointer-events: none;
`

const TabItemsListStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
}>`
  position: relative;
  display: flex;
  max-width: 100%;
  align-items: center;
  margin-left: 0;
  &:before {
    z-index: ${Z_INDEX_TABS_LIST};
    position: absolute;
  }
  &:hover {
    ${NavButtonContainerStyled} {
      display: flex;
    }
  }
  ${({ $styles }) =>
    $styles.view.type === ViewTabsElementType.solid && getBottomLineByPosition($styles)}

  ${({ $styles }) => $styles.view.type === ViewTabsElementType.splitted && splitted}
  ${({ $styles }) =>
    ($styles.tabs.position as PositionTabsElementType) !== PositionTabsElementType.top &&
    css`
      max-width: 80%;
    `};
`

export default TabItemsListStyled
